import React, { Fragment, useState } from 'react'
import { tenThanhService } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Select, message } from 'antd';
import InputImage from '../../components/InputImage';
import { ANH_MAC_DINH } from '../../helpers/constants';

export const ThemMoiTenThanh = () => {
    // Function to get the current date with time set to 00:00:00
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loadingLinhMuc, setLoadingLinhMuc] = useState(false);

    // cho image
    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState(ANH_MAC_DINH);
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };
    const onClose = () => {
        navigate('/ten-thanh');
    };
    const onSubmit = async (values: any) => {
        setLoadingLinhMuc(true);
        try {
            const response = await tenThanhService.createOrUpdate(null, values.tenThanh, values.ngayKinh, values.gioiTinh, urlImgAnhDaiDien ? urlImgAnhDaiDien : ANH_MAC_DINH);
            if (response.code === 0) {
                message.success('Tạo mới Tên Thánh thành công', onClose)
            } else {
                setLoadingLinhMuc(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoadingLinhMuc(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Tên Thánh / Tạo Mới</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={form} name="detail" onFinish={onSubmit} className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/ten-thanh">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loadingLinhMuc}> <>
                                            {loadingLinhMuc && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tên Thánh</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tenThanh"
                                                rules={[{ required: true, message: 'Vui lòng nhập Tên Thánh !' }]}>
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Ngày Kính (Nhập Tháng/Ngày)</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="ngayKinh"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập Ngày Kính !'
                                                    },
                                                    {
                                                        pattern: /^(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1])$/,
                                                        message: 'Định dạng ngày tháng không hợp lệ. Vui lòng nhập theo định dạng MM-DD!'
                                                    }
                                                ]}
                                            >
                                                <Input className='form-control' placeholder='MM-DD' autoComplete="off" />
                                            </Form.Item>

                                        </div>
                                    </div>
                                </div>


                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Giới tính</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="gioiTinh">
                                                <Select placeholder="Chọn Giới tính" size="large">
                                                    <Select.Option value={''}>{''}</Select.Option>
                                                    <Select.Option value={'NAM'}>Nam</Select.Option>
                                                    <Select.Option value={'NU'}>Nữ</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>


                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="anhDaiDien">
                                                <InputImage onChange={handleImageChange} initialValue={ANH_MAC_DINH} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/ten-thanh">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loadingLinhMuc}> <>
                                            {loadingLinhMuc && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
