import React, { Fragment, useEffect, useState } from 'react'
import { congDoanService, nhanSuService, vungService } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { DatePicker, Form, Input, Select, message } from 'antd';
import InputImage from '../../components/InputImage';
import { ANH_MAC_DINH, STORARE_NAME_LIST_NHAN_SU } from '../../helpers/constants';

export const ThemMoiCongDoan = () => {
    // Function to get the current date with time set to 00:00:00
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loadingLinhMuc, setLoadingLinhMuc] = useState(true);
    const [lstLinhMuc, setLstLinhMuc] = useState<any[]>([]);
    const [lstVung, setLstVung] = useState<any[]>([]);
    const [lstLoaiCongDoan, setLstLoaiCongDoan] = useState<any[]>([]);

    useEffect(() => {
        const fetchLinhMucData = async () => {
            try {
                const congDoanResponse = await congDoanService.getAllLoaiCongDoan();
                if (congDoanResponse.status === 200) {
                    setLstLoaiCongDoan(congDoanResponse.data);
                }
                const vungResponse = await vungService.getAll();
                if (vungResponse.status === 200) {
                    setLstVung(vungResponse.data);
                }
                const storedUserList = localStorage.getItem(STORARE_NAME_LIST_NHAN_SU);
                if (storedUserList) {
                    // case đã tồn tại
                    setLstLinhMuc(JSON.parse(storedUserList));
                } else {
                    // chư tồn tại thì get ra
                    const linhMucResponse = await nhanSuService.getAll();
                    if (linhMucResponse.code === 0) {
                        setLstLinhMuc(linhMucResponse.data);
                    }
                    localStorage.setItem(STORARE_NAME_LIST_NHAN_SU, JSON.stringify(linhMucResponse.data));
                }
            } catch (error: any) {
                message.error(error.message, onClose);
            } finally {
                setLoadingLinhMuc(false); // Set loading to false regardless of success or failure
            }
        };
        fetchLinhMucData();
    }, []);

    // cho image
    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState(ANH_MAC_DINH);
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };

    const onClose = () => {
        navigate('/cong-doan');
    };

    const onSubmit = async (values: any) => {
        setLoadingLinhMuc(true);
        try {
            const response = await congDoanService.createOrUpdate(null, values.tenCongDoan, values.tenBonMang, values.idPhanLoai,
                values.idChaBeTren, urlImgAnhDaiDien, values.ngayThanhLap ? values.ngayThanhLap.format("DD.MM.YYYY") : null,
                values.ngayBonMang ? (values.ngayBonMang + ".2023") : null, values.idVung, values.diaChi);
            if (response.code === 0) {
                message.success('Tạo mới Cộng Đoàn thành công', onClose)
            } else {
                setLoadingLinhMuc(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoadingLinhMuc(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Cộng Đoàn / Tạo Mới</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form
                            form={form}
                            name="detail"
                            onFinish={onSubmit}
                            className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/cong-doan">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loadingLinhMuc}> <>
                                            {loadingLinhMuc && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tên cộng đoàn</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tenCongDoan"
                                                rules={[{ required: true, message: 'Vui lòng nhập tên cộng đoàn !' }]}>
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tên bổn mạng</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="tenBonMang">
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Phân loại</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="idPhanLoai">
                                                <Select placeholder="Chọn loại" size="large">
                                                    <option value=""></option>
                                                    {lstLoaiCongDoan?.map((item: any, index) => (
                                                        <Select.Option key={index} value={item.id}>{item.tenLoaiCongDoan}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Cha bề trên</label>
                                        <div className="col-sm-8">
                                            {loadingLinhMuc ? (
                                                // Hiển thị bộ Loading khi loadingLoadList là true
                                                <tr className='text-center'>
                                                    <td colSpan={6}>
                                                        <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                                    </td>
                                                </tr>
                                            ) : (
                                                // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                                <Form.Item name="idChaBeTren">
                                                    <Select placeholder="Chọn Cha bề trên" size="large" showSearch
                                                        optionFilterProp="label">
                                                        <option value=""></option>
                                                        {lstLinhMuc?.map((item: any, index) => (
                                                            <Select.Option key={index} value={item.id} label={item.hoTen}>{item.hoTen}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="anhDaiDien">
                                                <InputImage onChange={handleImageChange} initialValue={ANH_MAC_DINH} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Ngày thành lập</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="ngayThanhLap">
                                                <DatePicker
                                                    size="large"
                                                    format="DD.MM.YYYY"
                                                    showTime={false}
                                                    inputReadOnly={true}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Ngày bổn mạng</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="ngayBonMang"
                                                rules={[
                                                    {
                                                        pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])$/,
                                                        message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM!',
                                                    }
                                                ]}>
                                                <Input className='form-control' placeholder='DD.MM' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Vùng</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="idVung">
                                                <Select placeholder="Chọn Vùng" size="large">
                                                    <option value=""></option>
                                                    {lstVung?.map((item: any, index) => (
                                                        <Select.Option key={index} value={item.id}>{item.tenVung}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Địa chỉ</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="diaChi">
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/cong-doan">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loadingLinhMuc}> <>
                                            {loadingLinhMuc && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
