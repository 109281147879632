import { Fragment } from 'react';
import { LeftMenu } from './LeftMenu';

export const Admin = ({ children }: { children: React.ReactNode }) => {
    return (
        <Fragment>
            <LeftMenu />
            {/* Content Wrapper */}
            <div id="content-wrapper" className="d-flex flex-column">
                {/* Main Content */}
                <div id="content">
                    {children}
                </div>
                {/* Footer */}
                <footer className="sticky-footer">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>Phần mềm được quản lý bởi Truyền Thông Dòng Chúa Cứu Thế</span>
                        </div>
                    </div>
                </footer>
            </div>
            {/* End of Content Wrapper */}
        </Fragment>
    );
};
