import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDataHome } from '../../store/home/actions';
import { AppState } from '../../store';
import { DataThongKeChucThanh, DataThongKeCongDoan, DataThongKeQuocGia } from '../../store/home/types';
import { isMobile } from 'react-device-detect';

export const TrangChu = () => {

  const loadingDataThongKe = useSelector<AppState>((state) => state.homeReducer.loading);

  const dispatch = useDispatch();
  useEffect(() => {
    const dataHome = getDataHome();
    dataHome(dispatch);
  }, [dispatch]);

  const lstChuThanh = useSelector<AppState>(
    (state) => state.homeReducer.lstChuThanh
  ) as DataThongKeChucThanh[];

  const lstCongDoan = useSelector<AppState>(
    (state) => state.homeReducer.lstCongDoan
  ) as DataThongKeCongDoan[];

  const lstQuocGia = useSelector<AppState>(
    (state) => state.homeReducer.lstQuocGia
  ) as DataThongKeQuocGia[];

  return (
    <Fragment>
      <div id="content" className='bg-custom'>
        <div className="row split-screen text-right">
          <div className="col-md-12">
            <img style={{ width: '21%', marginTop: '15px' }} src="/images/web_alfonso.png" alt="Logo" />
          </div>
        </div>
        <div className='text-left pl-2 text-lg'>
          <strong style={{ color: '#951a1d', fontSize: '22px' }}>TỈNH DÒNG CHÚA CỨU THẾ VIỆT NAM</strong>
        </div>
        <div className="divider" />
        <div className="row split-screen bg-custom container-fluid ">
          {isMobile ?
            (
              <Fragment>
                <div className="row">
                  <div className="col-12">
                    <div className="card shadow mb-4" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                      {/* Card Header - Dropdown */}
                      <div className="card-header text-center" style={{ backgroundColor: '#f1dcaa' }}>
                        <h6 className="m-0 font-weight-bold text-black">THỐNG KÊ CỘNG ĐOÀN</h6>
                      </div>
                      {/* Card Body */}
                      <div className="card-body" style={{ padding: 'inherit', overflowY: 'auto', maxHeight: '450px' }}>
                        <table className=" table table-bordered dataTable text-center" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                          <tbody>
                            {loadingDataThongKe ? (
                              // Hiển thị bộ Loading khi loadingLoadList là true
                              <tr className='text-center'>
                                <td colSpan={2}>
                                  <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                </td>
                              </tr>
                            ) : (
                              // Hiển thị bảng dữ liệu khi loadingLoadList là false
                              <Fragment>
                                {lstCongDoan.map((item, index) => (
                                  <tr key={index}>
                                    <td className='text-left'>{item.tenCongDoan}</td>
                                    <td>{item.soNhanSu}</td>
                                  </tr>
                                ))}
                              </Fragment>
                            )}
                            <tr>
                              <td className='text-center'><strong>Tổng số</strong></td>
                              <td>
                                <strong>
                                  {lstCongDoan.reduce((total, item) => total + item.soNhanSu, 0)}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card shadow mb-4" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                      {/* Card Header - Dropdown */}
                      <div className="card-header text-center" style={{ backgroundColor: '#f1dcaa' }}>
                        <h6 className="m-0 font-weight-bold text-black">THỐNG KÊ</h6>
                      </div>
                      {/* Card Body */}
                      <div className="card-body" style={{ padding: 'inherit' }}>
                        <table className=" table table-bordered dataTable text-center" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                          <tbody>
                            {loadingDataThongKe ? (
                              // Hiển thị bộ Loading khi loadingLoadList là true
                              <tr className='text-center'>
                                <td colSpan={2}>
                                  <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                </td>
                              </tr>
                            ) : (
                              // Hiển thị bảng dữ liệu khi loadingLoadList là false
                              <Fragment>
                                {lstChuThanh.map((item, index) => (
                                  <tr key={index}>
                                    <td className='text-left'>{item.tenChucThanh}</td>
                                    <td>{item.soNhanSu}</td>
                                  </tr>
                                ))}
                              </Fragment>
                            )}
                            <tr>
                              <td className='text-center'><strong>Tổng số</strong></td>
                              <td>
                                <strong>
                                  {lstChuThanh.reduce((total, item) => total + item.soNhanSu, 0)}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card shadow mb-4" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                      {/* Card Header - Dropdown */}
                      <div className="card-header text-center" style={{ backgroundColor: '#f1dcaa' }}>
                        <h6 className="m-0 font-weight-bold text-black">THỐNG KÊ QUỐC GIA LÀM VIỆC</h6>
                      </div>
                      {/* Card Body */}
                      <div className="card-body" style={{ padding: 'inherit', overflowY: 'auto', maxHeight: '450px' }}>
                        <table className=" table table-bordered dataTable text-center" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                          <tbody>
                            {loadingDataThongKe ? (
                              // Hiển thị bộ Loading khi loadingLoadList là true
                              <tr className='text-center'>
                                <td colSpan={2}>
                                  <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                </td>
                              </tr>
                            ) : (
                              // Hiển thị bảng dữ liệu khi loadingLoadList là false
                              <Fragment>
                                {lstQuocGia.map((item, index) => (
                                  <tr key={index}>
                                    <td className='text-left'>{item.tenQuocGia}</td>
                                    <td>{item.soNhanSu}</td>
                                  </tr>
                                ))}
                              </Fragment>
                            )}
                            <tr>
                              <td className='text-center'><strong>Tổng số</strong></td>
                              <td>
                                <strong>
                                  {lstQuocGia.reduce((total, item) => total + item.soNhanSu, 0)}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

              </Fragment>
            )
            :
            (
              <Fragment>
                <div className="col-md-12">
                  {/* Content for bottom half */}
                  <div className="row">
                    <div className="col-3">
                      <div className="card shadow mb-4" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                        {/* Card Header - Dropdown */}
                        <div className="card-header text-center" style={{ backgroundColor: '#f1dcaa' }}>
                          <h6 className="m-0 font-weight-bold text-black">THỐNG KÊ CỘNG ĐOÀN</h6>
                        </div>
                        {/* Card Body */}
                        <div className="card-body" style={{ padding: 'inherit', overflowY: 'auto', maxHeight: '450px' }}>
                          <table className=" table table-bordered dataTable text-center" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                            <tbody>
                              {loadingDataThongKe ? (
                                // Hiển thị bộ Loading khi loadingLoadList là true
                                <tr className='text-center'>
                                  <td colSpan={2}>
                                    <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                  </td>
                                </tr>
                              ) : (
                                // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                <Fragment>
                                  {lstCongDoan.map((item, index) => (
                                    <tr key={index}>
                                      <td className='text-left'>{item.tenCongDoan}</td>
                                      <td>{item.soNhanSu}</td>
                                    </tr>
                                  ))}
                                </Fragment>
                              )}
                              <tr>
                                <td className='text-center'><strong>Tổng số</strong></td>
                                <td>
                                  <strong>
                                    {lstCongDoan.reduce((total, item) => total + item.soNhanSu, 0)}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="card shadow mb-4" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                        {/* Card Header - Dropdown */}
                        <div className="card-header text-center" style={{ backgroundColor: '#f1dcaa' }}>
                          <h6 className="m-0 font-weight-bold text-black">THỐNG KÊ</h6>
                        </div>
                        {/* Card Body */}
                        <div className="card-body" style={{ padding: 'inherit' }}>
                          <table className=" table table-bordered dataTable text-center" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                            <tbody>
                              {loadingDataThongKe ? (
                                // Hiển thị bộ Loading khi loadingLoadList là true
                                <tr className='text-center'>
                                  <td colSpan={2}>
                                    <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                  </td>
                                </tr>
                              ) : (
                                // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                <Fragment>
                                  {lstChuThanh.map((item, index) => (
                                    <tr key={index}>
                                      <td className='text-left'>{item.tenChucThanh}</td>
                                      <td>{item.soNhanSu}</td>
                                    </tr>
                                  ))}
                                </Fragment>
                              )}
                              <tr>
                                <td className='text-center'><strong>Tổng số</strong></td>
                                <td>
                                  <strong>
                                    {lstChuThanh.reduce((total, item) => total + item.soNhanSu, 0)}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="card shadow mb-4" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                        {/* Card Header - Dropdown */}
                        <div className="card-header text-center" style={{ backgroundColor: '#f1dcaa' }}>
                          <h6 className="m-0 font-weight-bold text-black">THỐNG KÊ QUỐC GIA LÀM VIỆC</h6>
                        </div>
                        {/* Card Body */}
                        <div className="card-body" style={{ padding: 'inherit', overflowY: 'auto', maxHeight: '450px' }}>
                          <table className=" table table-bordered dataTable text-center" style={{ backgroundColor: '#f1dcaa', color: '#000' }}>
                            <tbody>
                              {loadingDataThongKe ? (
                                // Hiển thị bộ Loading khi loadingLoadList là true
                                <tr className='text-center'>
                                  <td colSpan={2}>
                                    <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                  </td>
                                </tr>
                              ) : (
                                // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                <Fragment>
                                  {lstQuocGia.map((item, index) => (
                                    <tr key={index}>
                                      <td className='text-left'>{item.tenQuocGia}</td>
                                      <td>{item.soNhanSu}</td>
                                    </tr>
                                  ))}
                                </Fragment>
                              )}
                              <tr>
                                <td className='text-center'><strong>Tổng số</strong></td>
                                <td>
                                  <strong>
                                    {lstQuocGia.reduce((total, item) => total + item.soNhanSu, 0)}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="col-3">
                    </div>
                  </div>
                </div>
              </Fragment>
            )
          }


        </div>

      </div>
    </Fragment>
  )
}
