import React, { Fragment, useState } from 'react'
import { thuChaGiamTinhService } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { DatePicker, Form, Input, message } from 'antd';
import dayjs from 'dayjs';
import EditorCustom from '../../components/EditorCustom';
import InputImage from '../../components/InputImage';
import InputFile from '../../components/InputFile';
import { ANH_MAC_DINH_THU_CHA_GIAM_TINH } from '../../helpers/constants';

export const ThemMoiThuChaGiamTinh = () => {
    // Function to get the current date with time set to 00:00:00
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    // cho trình soạn thảo văn bản
    const [chiTietThongTin, setChiTietThongTin] = useState('');
    const handleChiTietChange = (value: string) => {
        setChiTietThongTin(value);
    };

    // cho trình soạn thảo văn bản
    const [gioiThieu, setGioiThieu] = useState('');
    const handleGioiThieuChange = (value: string) => {
        setGioiThieu(value);
    };

    const onClose = () => {
        navigate('/thu-cha-giam-tinh');
    };

    // cho image
    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState(ANH_MAC_DINH_THU_CHA_GIAM_TINH);
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };

    const [urlFileUpload, setUrlFileUpload] = useState('');
    const handleFileChange = (value: string) => {
        setUrlFileUpload(value);
    };

    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const ngayDangString = values.thangNam.format('MM-YYYY');
            const response = await thuChaGiamTinhService.themMoiOrChinhSuaTCGT(null, values.so, ngayDangString, values.tieuDe, gioiThieu, chiTietThongTin, urlImgAnhDaiDien ? urlImgAnhDaiDien : ANH_MAC_DINH_THU_CHA_GIAM_TINH, urlFileUpload)
            if (response.code === 0) {
                message.success('Tạo mới Thư Cha Giám Tỉnh thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 3 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 2 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>THÔNG TIN / Thư Cha Giám Tỉnh / Tạo Mới</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form
                            form={form}
                            name="detail"
                            onFinish={onSubmit}
                            initialValues={{
                                thangNam: dayjs().startOf('day'), // Lấy ngày hiện tại
                            }}
                            className="c-formNew"
                        >
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/thu-cha-giam-tinh">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Số</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="so"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng chọn số',
                                                    }
                                                ]}>
                                                <Input type="number" className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Tháng Năm</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="thangNam" // Tên của trường trong form
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng chọn Tháng Năm',
                                                    }
                                                ]}>
                                                <DatePicker
                                                    size="large"
                                                    picker="month"
                                                    format="MM-YYYY"
                                                    inputReadOnly={true}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tiêu đề</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tieuDe"
                                                rules={[{ required: true, message: 'Vui lòng nhập tiêu đề !' }]}>
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                        <div className="col-sm-9">
                                            <Form.Item name="hinhAnhDaiDien">
                                                <InputImage onChange={handleImageChange} initialValue={ANH_MAC_DINH_THU_CHA_GIAM_TINH} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">File nội dung</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="fileNoiDung"
                                                rules={[{ required: true, message: 'Vui lòng upload lên file nội dung !' }]}>
                                                <InputFile onChange={handleFileChange} initialValue={''} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="col-sm-3 col-form-label">Nội dung Giới thiệu</label>
                                <div className="col-sm-12">
                                    <Form.Item name="gioiThieu"
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung giới thiệu!' }]}>
                                        <EditorCustom onChange={handleGioiThieuChange} initialValue={''} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row'>
                                <label className="col-sm-3 col-form-label">Nội dung Chi tiết</label>
                                <div className="col-sm-12">
                                    <Form.Item name="chiTietThongTin"
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung chi tiết !' }]}>
                                        <EditorCustom onChange={handleChiTietChange} initialValue={''} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/thu-cha-giam-tinh">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
