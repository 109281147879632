import React, { FC } from 'react';
import pica from 'pica';
import { RcFile, UploadFile } from 'antd/es/upload/interface';

interface Props {
    fileList: UploadFile[];
    setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
    addImageToUploadList: (url: string) => void;
    imageService: { uploadImage: (file: File) => Promise<any> };
}

const resizeImage = async (file: RcFile | File): Promise<File> => {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(file);

    return new Promise((resolve, reject) => {
        img.onload = async () => {
            const canvas = document.createElement('canvas');
            const MAX_WIDTH = 512;
            if (img.width > MAX_WIDTH) {
                const scaleFactor = MAX_WIDTH / img.width;
                canvas.width = MAX_WIDTH;
                canvas.height = img.height * scaleFactor;

                const picaInstance = pica();
                try {
                    await picaInstance.resize(img, canvas);
                    canvas.toBlob((blob) => {
                        if (blob) {
                            const resizedFile = new File([blob], file.name, { type: file.type });
                            resolve(resizedFile);
                        } else {
                            reject(new Error('Canvas toBlob failed'));
                        }
                    }, file.type);
                } catch (err) {
                    reject(err);
                }
            } else {
                resolve(file);
            }
        };

        img.onerror = () => {
            reject(new Error('Image load error'));
        };
    });
};

export default resizeImage;