import { Navigate } from 'react-router-dom';

import { AccountState } from '../store/account/types';
import { AppState } from '../store';
import { useSelector } from 'react-redux';
import { Admin } from '../pages/admin/Admin';

export const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const account: AccountState = useSelector((state: AppState) => state.account);
  return account.token ? <Admin> {children} </Admin> : <Navigate to="/login" />
};