import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/account/actions';
import { AppState } from '../../store';
import { AuthenticatedUser } from '../../store/account/types';
import { Link, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

export const LeftMenu = () => {
    const [isToggled, setIsToggled] = useState(false);
    const [isComponentShow, setIsComponentShow] = useState(true);
    const [isComponentShow1, setIsComponentShow1] = useState(true);
    const [isComponentShow2, setIsComponentShow2] = useState(true);
    const [isComponentShow3, setIsComponentShow3] = useState(true);
    const [isComponentShow4, setIsComponentShow4] = useState(true);

    const dispatch = useDispatch();
    const location = useLocation();
    const isLinkActive = (path: string) => {
        return location.pathname.includes(path);
    };

    const handleClick = async (toggleFunction: React.Dispatch<React.SetStateAction<boolean>>, value: boolean) => {
        if (isMobile) {
            setIsComponentShow(false)
            setIsComponentShow1(false);
            setIsComponentShow2(false);
            setIsComponentShow3(false);
            setIsComponentShow4(false);
        }
        toggleFunction(value);
    };

    const handleClickSubMenu = async (toggleFunction: React.Dispatch<React.SetStateAction<boolean>>, value: boolean) => {
        if (isMobile) {
            setIsComponentShow(false)
            setIsComponentShow1(false);
            setIsComponentShow2(false);
            setIsComponentShow3(false);
            setIsComponentShow4(false);
        }
    };

    useEffect(() => {
        if (isToggled) {
            setIsComponentShow(false)
            setIsComponentShow1(false);
            setIsComponentShow2(false);
            setIsComponentShow3(false);
            setIsComponentShow4(false);
        } else {
            setIsComponentShow(!isMobile)
            setIsComponentShow1(!isMobile);
            setIsComponentShow2(!isMobile);
            setIsComponentShow3(!isMobile);
            setIsComponentShow4(!isMobile);
        }
    }, [isToggled]);

    const user = useSelector<AppState>(
        (state) => state.account.user
    ) as AuthenticatedUser;

    return (
        <ul className={'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion' + (isToggled ? ' toggled' : '')} id='accordionSidebar'>
            {/* Sidebar - Brand */}
            <div className='sidebar-brand-text mx-3 text-center mb-2'>
                <img style={{ width: '50%', marginTop: '20px' }} src="/images/web_logo_w.png" alt="Logo" />
            </div>
            {/* Divider */}
            <hr className='sidebar-divider my-0' />
            {/* Nav Item - Dashboard */}
            <li className='nav-item text-center'>
                <span style={{ fontWeight: 'unset', color: '#fa7821' }}>{user?.thuocCongDoan}</span><br />
                <img className='rounded-circle mt-2 mr-1' style={{ width: '21%' }} src={user?.avatar} alt='' /><br />
                <span style={{ fontWeight: 'unset', color: '#fff' }}>{user?.hoTen}</span><br />
            </li>

            {user && user.userLevel === 'Administrator' ?
                (
                    <Fragment>
                        <hr className='sidebar-divider' />
                        <li className='nav-item active'>
                            <Link className='nav-link' to='/#'>
                                <span>TRANG CHỦ</span>
                            </Link>
                        </li>
                        <hr className='sidebar-divider' />

                        <li className='nav-item'>
                            <a className={'nav-link' + (isComponentShow ? '' : ' collapsed')}
                                href='#!'
                                data-toggle='collapse'
                                data-target='#collapseTwo'
                                aria-expanded={isComponentShow ? 'true' : 'false'}
                                aria-controls='collapseTwo'
                                onClick={() => handleClick(setIsComponentShow, !isComponentShow)}>
                                <span>THÔNG TIN</span>
                            </a>
                            <div
                                id='collapseTwo'
                                className={'collapse' + (isComponentShow ? ' show' : '')}
                                aria-labelledby='headingTwo'
                                data-parent='#accordionSidebar'>
                                <div className='collapse-inner rounded' style={{ backgroundColor: '#900307' }}>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow, !isComponentShow)} className={`collapse-item ${isLinkActive('/tin-tuc') ? 'link-active' : ''}`} to='/tin-tuc'>
                                        Tin Tức
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow, !isComponentShow)} className={`collapse-item ${isLinkActive('/loai-tin-tuc') ? 'link-active' : ''}`} to='/loai-tin-tuc'>
                                        Phân Loại Tin Tức
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow, !isComponentShow)} className={`collapse-item ${isLinkActive('/thu-cha-giam-tinh') ? 'link-active' : ''}`} to='/thu-cha-giam-tinh'>
                                        Thư Cha Giám Tỉnh
                                    </Link>
                                </div>
                            </div>
                        </li>
                        <hr className='sidebar-divider' />
                        {/* Nav Item - Pages Collapse Menu */}
                        <li className='nav-item'>
                            <a className={'nav-link' + (isComponentShow1 ? '' : ' collapsed')}
                                href='#!'
                                data-toggle='collapse'
                                data-target='#collapseTwo'
                                aria-expanded={isComponentShow1 ? 'true' : 'false'}
                                aria-controls='collapseTwo'
                                onClick={() => handleClick(setIsComponentShow1, !isComponentShow1)}>
                                <span>NHÂN SỰ</span>
                            </a>
                            <div
                                id='collapseTwo'
                                className={'collapse' + (isComponentShow1 ? ' show' : '')}
                                aria-labelledby='headingTwo'
                                data-parent='#accordionSidebar'>
                                <div className='collapse-inner rounded' style={{ backgroundColor: '#900307' }}>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow1, !isComponentShow1)} className={`collapse-item ${isLinkActive('/nhan-su') ? 'link-active' : ''}`} to='/nhan-su'>
                                        Nhân Sự
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow1, !isComponentShow1)} className={`collapse-item ${isLinkActive('/cong-doan') ? 'link-active' : ''}`} to='/cong-doan'>
                                        Cộng Đoàn
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow1, !isComponentShow1)} className={`collapse-item ${isLinkActive('/ten-thanh') ? 'link-active' : ''}`} to='/ten-thanh'>
                                        Tên Thánh
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow1, !isComponentShow1)} className={`collapse-item ${isLinkActive('/chuc-thanh') ? 'link-active' : ''}`} to='/chuc-thanh'>
                                        Chức Thánh
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow1, !isComponentShow1)} className={`collapse-item ${isLinkActive('/muc-vu') ? 'link-active' : ''}`} to='/muc-vu'>
                                        Mục Vụ
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow1, !isComponentShow1)} className={`collapse-item ${isLinkActive('/vung') ? 'link-active' : ''}`} to='/vung'>
                                        Vùng
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow1, !isComponentShow1)} className={`collapse-item ${isLinkActive('/quoc-gia') ? 'link-active' : ''}`} to='/quoc-gia'>
                                        Quốc Gia
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow1, !isComponentShow1)} className={`collapse-item ${isLinkActive('/tinh-trang') ? 'link-active' : ''}`} to='/tinh-trang'>
                                        Tình Trạng
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow1, !isComponentShow1)} className={`collapse-item ${isLinkActive('/chat') ? 'link-active' : ''}`} to='/chat/danh-sach-nhom'>
                                        Nhóm chat
                                    </Link>
                                </div>
                            </div>
                        </li>

                        <hr className='sidebar-divider' />
                        {/* Nav Item - Pages Collapse Menu */}
                        <li className='nav-item'>
                            <a className={'nav-link' + (isComponentShow3 ? '' : ' collapsed')}
                                href='#!'
                                data-toggle='collapse'
                                data-target='#collapseTwo'
                                aria-expanded={isComponentShow3 ? 'true' : 'false'}
                                aria-controls='collapseTwo'
                                onClick={() => handleClick(setIsComponentShow3, !isComponentShow3)}>
                                <span>LỊCH DÒNG</span>
                            </a>
                            <div
                                id='collapseTwo'
                                className={'collapse' + (isComponentShow3 ? ' show' : '')}
                                aria-labelledby='headingTwo'
                                data-parent='#accordionSidebar'>
                                <div className='collapse-inner rounded' style={{ backgroundColor: '#900307' }}>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow3, !isComponentShow3)} className={`collapse-item ${isLinkActive('/lich-dong') ? 'link-active' : ''}`} to='/lich-dong'>
                                        Lịch Dòng
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow3, !isComponentShow3)} className={`collapse-item ${isLinkActive('/su-kien') ? 'link-active' : ''}`} to='/su-kien'>
                                        Sự Kiện
                                    </Link>
                                </div>
                            </div>
                        </li>

                        <hr className='sidebar-divider' />
                        {/* Nav Item - Pages Collapse Menu */}
                        <li className='nav-item'>
                            <a className={'nav-link' + (isComponentShow4 ? '' : ' collapsed')}
                                href='#!'
                                data-toggle='collapse'
                                data-target='#collapseTwo'
                                aria-expanded={isComponentShow4 ? 'true' : 'false'}
                                aria-controls='collapseTwo'
                                onClick={() => handleClick(setIsComponentShow4, !isComponentShow4)}>
                                <span>TÀI LIỆU</span>
                            </a>
                            <div
                                id='collapseTwo'
                                className={'collapse' + (isComponentShow4 ? ' show' : '')}
                                aria-labelledby='headingTwo'
                                data-parent='#accordionSidebar'>
                                <div className='collapse-inner rounded' style={{ backgroundColor: '#900307' }}>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow4, !isComponentShow4)} className={`collapse-item ${isLinkActive('/tai-lieu') ? 'link-active' : ''}`} to='/tai-lieu'>
                                        Tài Liệu
                                    </Link>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow4, !isComponentShow4)} className={`collapse-item ${isLinkActive('/phan-loai-tai-lieu') ? 'link-active' : ''}`} to='/phan-loai-tai-lieu'>
                                        Phân Loại
                                    </Link>
                                </div>
                            </div>
                        </li>
                    </Fragment>
                )
                :
                (
                    <Fragment>
                        <hr className='sidebar-divider' />

                        <li className='nav-item'>
                            <a className={'nav-link' + (isComponentShow ? '' : ' collapsed')}
                                href='#!'
                                data-toggle='collapse'
                                data-target='#collapseTwo'
                                aria-expanded={isComponentShow ? 'true' : 'false'}
                                aria-controls='collapseTwo'
                                onClick={() => handleClick(setIsComponentShow, !isComponentShow)}>
                                <span>THÔNG TIN</span>
                            </a>
                            <div
                                id='collapseTwo'
                                className={'collapse' + (isComponentShow ? ' show' : '')}
                                aria-labelledby='headingTwo'
                                data-parent='#accordionSidebar'>
                                <div className='collapse-inner rounded' style={{ backgroundColor: '#900307' }}>
                                    <Link onClick={() => handleClickSubMenu(setIsComponentShow, !isComponentShow)} className={`collapse-item ${isLinkActive('/tin-tuc') ? 'link-active' : ''}`} to='/tin-tuc'>
                                        Tin Tức
                                    </Link>
                                </div>
                            </div>
                        </li>
                    </Fragment>
                )}
            <hr className='sidebar-divider' />
            {/* Nav Item - Pages Collapse Menu */}
            <li className='nav-item'>
                <a className={'nav-link' + (isComponentShow2 ? '' : ' collapsed')}
                    href='#!'
                    data-toggle='collapse'
                    data-target='#collapseTwo1'
                    aria-expanded={isComponentShow2 ? 'true' : 'false'}
                    aria-controls='collapseTwo1'
                    onClick={() => handleClick(setIsComponentShow2, !isComponentShow2)}>
                    <span>HỆ THỐNG</span>
                </a>
                <div
                    id='collapseTwo1'
                    className={'collapse' + (isComponentShow2 ? ' show' : '')}
                    aria-labelledby='headingTwo'
                    data-parent='#accordionSidebar'>
                    <div className='collapse-inner rounded' style={{ backgroundColor: '#9c0206' }}>
                        {/* <Link className={`collapse-item ${isLinkActive('/tai-khoan') ? 'link-active' : ''}`} to='/tai-khoan'>
                            Tài khoản
                        </Link> */}
                        <Link className='collapse-item' to='/#' onClick={() => dispatch(logout())}>
                            Thoát chương trình
                        </Link>
                    </div>
                </div>
            </li>

            <hr className='sidebar-divider d-none d-md-block' />
            {/* Sidebar Toggler (Sidebar) */}
            <div className='text-center d-none d-md-inline mt-2'>
                <button
                    className='rounded-circle border-0'
                    id='sidebarToggle'
                    onClick={() => setIsToggled(!isToggled)}
                />
            </div>
        </ul>
    );
};