import { api } from '../helpers';

const getAll = async () => {
    return await api.get('/admin-dcct/muc-vu/all').then((response) => {
        return response.data;
    });
};

const filterList = async (page: number | null, size: number | null, sort: string | null) => {
    const params = { page, size, sort };
    return await api.get('/muc-vus', { params }).then((response: any) => {
        return response;
    });
};

const create = async (id: any, tenMucVu: string) => {
    const payLoad = {
        id, tenMucVu
    };
    return await api.post('/muc-vus', payLoad).then((response: any) => {
        return response;
    });
};

const getById = async (id: any) => {
    return await api.get(`/muc-vus/${id}`).then((response: any) => {
        return response;
    });
};

const update = async (id: any, tenMucVu: string) => {
    const payLoad = {
        id, tenMucVu
    };
    return await api.patch(`/muc-vus/${id}`, payLoad).then((response: any) => {
        return response;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/muc-vus/${id}`).then((response: any) => {
        return response;
    });
};

export const mucVuService = {
    getAll,
    filterList,
    create,
    getById,
    update,
    deleteById
};