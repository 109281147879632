import React, { Fragment, useEffect, useState } from 'react';
import { homeService, tintucService } from '../../services';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { message } from 'antd';
import MoveButtons from '../../components/MoveButtons';

export const LoaiThongTin = () => {
    const [loading, setLoading] = useState(true);
    const [lstLoaiTin, setLstLoaiTin] = useState<any[]>([]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await tintucService.getAllDanhSachLoaiTinPhanCap();
            if (response.code === 0) {
                setLstLoaiTin(response.data);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDeleteLoaiTin = async (id: any) => {
        const result = await Swal.fire({
            title: 'Xóa Loại sẽ xóa các loại con và xóa tất cả các tin tức của Loại đó. Bạn có chắc chắn muốn xóa tin tức này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const response = await tintucService.deleteLoaiThongTin(id);
                if (response.code === 0) {
                    message.success('Xóa loại tin tức thành công');
                    fetchData();
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setLoading(false);
            }
        }
    };

    const move = async (id: any, idCha: any, type: string, flagUpDown: boolean) => {
        setLoading(true);
        try {
            const response = await homeService.moveUpDown(id, idCha, type, flagUpDown);
            if (response.code === 0) {
                fetchData();
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>THÔNG TIN / Phân Loại</h1>
                </div>
                {/* Content Row */}

                <div className='row'>
                    <div className='col-12 text-right'>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                )}
                                Reload
                            </button>
                            <Link className="btn btn-info ml-2" type="button" to="/loai-tin-tuc/them-moi" >Tạo mới</Link>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover table-custom-with" id="dataTable">
                                <thead style={{ backgroundColor: '#fac2b5' }}>
                                    <tr>
                                        <th>Tên phân loại</th>
                                        <th>Thứ tự</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={6}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        <Fragment>
                                            {lstLoaiTin?.map((item: any, index: number) => (
                                                <Fragment key={index}>
                                                    <tr className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                        <td>{item.tenLoai}</td>
                                                        <td style={{ width: '10%' }}>
                                                            <MoveButtons onMoveUp={() => move(item.id, null, 'LOAI_TIN_TUC_CHA', true)} onMoveDown={() => move(item.id, null, 'LOAI_TIN_TUC_CHA', false)} />
                                                        </td>
                                                        <td>
                                                            <Link to={`/loai-tin-tuc/chinh-sua/${item.id}`} style={{ marginRight: '10px' }}>
                                                                {/* Chèn nội dung bên trong thẻ Link */}
                                                                <img style={{ width: '22px' }} src='/images/web_new.png' alt={item.tenLoai} />
                                                            </Link>
                                                            <img onClick={() => handleDeleteLoaiTin(item.id)} style={{ width: '23px', cursor: 'pointer' }} src='/images/web_delete.png' alt={item.tenLoai} />
                                                        </td>
                                                    </tr>
                                                    {item.loaiTinTucCon?.map((subItem: any, subIndex: number) => (
                                                        <tr key={subIndex}>
                                                            <td>
                                                                <span style={{ marginLeft: '7%' }}>
                                                                    {subItem.tenLoai}
                                                                </span>
                                                            </td>
                                                            <td style={{ width: '10%' }}>
                                                                <MoveButtons onMoveUp={() => move(subItem.id, item.id, 'LOAI_TIN_TUC_CON', true)} onMoveDown={() => move(subItem.id, item.id, 'LOAI_TIN_TUC_CON', false)} />
                                                            </td>
                                                            <td>
                                                                <Link to={`/loai-tin-tuc/chinh-sua/${subItem.id}`} style={{ marginRight: '10px' }}>
                                                                    {/* Chèn nội dung bên trong thẻ Link */}
                                                                    <img style={{ width: '22px' }} src='/images/web_new.png' alt={subItem.tenLoai} />
                                                                </Link>
                                                                <img onClick={() => handleDeleteLoaiTin(subItem.id)} style={{ width: '23px', cursor: 'pointer' }} src='/images/web_delete.png' alt={subItem.tenLoai} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
