import { api } from '../helpers';

const filterList = async (page: number | null, size: number | null, sort: string | null, idLoaiTaiLieu: any, keySearch: string) => {
    const params = { page, size, sort, idLoaiTaiLieu, keySearch };
    return await api.get('/admin-dcct/tai-lieu/filter', { params }).then((response: any) => {
        return response;
    });
};

const createOrUpdate = async (id: any, tieuDe: string, chiTiet: string, tacGia: string, ngayDang: string, enable: boolean,
    hinhAnhDaiDien: string, fileNoiDung: string, idLoaiTaiLieu: number) => {
    const payLoad = {
        id, tieuDe, chiTiet, tacGia, ngayDang, enable,
        hinhAnhDaiDien, fileNoiDung, idLoaiTaiLieu
    };
    return await api.post('/admin-dcct/tai-lieu/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const getById = async (id: any) => {
    return await api.get(`/tai-lieus/${id}`).then((response: any) => {
        return response;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/tai-lieus/${id}`).then((response: any) => {
        return response;
    });
};

export const taiLieuService = {
    filterList,
    createOrUpdate,
    getById,
    deleteById
};