import React, { Fragment, useState } from 'react'
import { chucThanhService } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, message } from 'antd';

export const ThemMoiChucThanh = () => {
    const onClose = () => {
        navigate('/chuc-thanh');
    };
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    // cho image
    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await chucThanhService.create(null, values.tenChucThanh, values.tenGoiTac);
            if (response.status === 201) {
                message.success('Tạo mới Chức Thánh thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Chức Thánh / Tạo Mới</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={form} name="detail" onFinish={onSubmit} className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/chuc-thanh">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-12'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tên Chức Thánh</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tenChucThanh"
                                                rules={[{ required: true, message: 'Vui lòng nhập Tên Chức Thánh !' }]}>
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tên gọi tắc (Dùng để ghép với tên hiển thị)</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="tenGoiTac">
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/chuc-thanh">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
