import {
    HomeActionTypes,
    LOAD_REQUEST,
    LOAD_SUCCESS,
    LOAD_FAILURE
} from './types';

import { homeService } from '../../services/home.service';

export const getDataHome = () => {
    return async (dispatch: React.Dispatch<HomeActionTypes>) => {
        dispatch({
            type: LOAD_REQUEST,
        });
        try {
            const response = await homeService.getDataThongKe();
            if (response.code === 0) {
                dispatch({
                    type: LOAD_SUCCESS,
                    payload: {
                        lstChuThanh: response.data.lstChuThanh,
                        lstCongDoan: response.data.lstCongDoan,
                        lstQuocGia: response.data.lstQuocGia
                    },
                });
            } else {
                dispatch({
                    type: LOAD_FAILURE,
                    payload: {
                        error: response.message
                    },
                });
            }
        } catch (error: any) {
            const message = (error.response && error.response.data &&
                error.response.data.message) || error.message || error.toString();
            dispatch({
                type: LOAD_FAILURE,
                payload: {
                    error: message
                },
            });
        }
    };
};
