import React, { Fragment, useEffect, useState } from 'react'
import { chucThanhService, congDoanService, mucVuService, nhanSuService, quocGiaService, tenThanhService, tinhTrangService } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Radio, Select, message } from 'antd';
import dayjs from 'dayjs';
import InputImage from '../../components/InputImage';
import { ANH_DAI_DIEN_MAC_DINH, STORARE_NAME_LIST_NHAN_SU } from '../../helpers/constants';

export const ThemMoiNhanSu = () => {
    const navigate = useNavigate();
    const onClose = () => {
        navigate('/nhan-su');
    };
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    const [lstTenThanh, setLstTenThanh] = useState<any[]>([]);
    const [lstTinhTrang, setLstTinhTrang] = useState<any[]>([]);
    const [lstCongDoan, setLstCongDoan] = useState<any[]>([]);
    const [lstChucThanh, setLstChucThanh] = useState<any[]>([]);
    const [lstMucVu, setLstMucVu] = useState<any[]>([]);
    const [lstQuocGia, setLstQuocGia] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tenThanhResponse = await tenThanhService.getAll();
                if (tenThanhResponse.code === 0) {
                    setLstTenThanh(tenThanhResponse.data);
                }

                const tinhTrangResponse = await tinhTrangService.getAll();
                if (tinhTrangResponse.code === 0) {
                    setLstTinhTrang(tinhTrangResponse.data);
                }

                const congDoanResponse = await congDoanService.getAll();
                if (congDoanResponse.code === 0) {
                    setLstCongDoan(congDoanResponse.data);
                }

                const chucThanhResponse = await chucThanhService.getAll();
                if (chucThanhResponse.code === 0) {
                    setLstChucThanh(chucThanhResponse.data);
                }

                const mucVuResponse = await mucVuService.getAll();
                if (mucVuResponse.code === 0) {
                    setLstMucVu(mucVuResponse.data);
                }

                const quocGiaResponse = await quocGiaService.getAll();
                if (quocGiaResponse.code === 0) {
                    setLstQuocGia(quocGiaResponse.data);
                }
            } catch (error: any) {
                message.error(error.message, onClose);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    // cho image
    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState(ANH_DAI_DIEN_MAC_DINH);
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };

    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await nhanSuService.createOrUpdate(null, values.ma, values.hoTenDem, values.ten, values.idTenThanh, values.idTinhTrang, values.idChucThanh,
                values.idCongDoan, values.idMucVu, values.idQuocGia,
                values.ngayPhoTe ? values.ngayPhoTe : null,
                values.ngayLinhMuc ? values.ngayLinhMuc : null,
                values.email, values.diaChi, values.dienThoai, values.userLevel, values.ghiChu,
                urlImgAnhDaiDien ? urlImgAnhDaiDien : ANH_DAI_DIEN_MAC_DINH,
                values.ngaySinh ? values.ngaySinh : null,
                values.ngayKhan ? values.ngayKhan : null,
                values.ngayMat ? values.ngayMat : null,
                values.idTenThanhBa, values.hoTenBa,
                values.ngayBaMat ? values.ngayBaMat : null,
                values.idTenThanhMe, values.hoTenMe,
                values.ngayMeMat ? values.ngayMeMat : null)
            if (response.code === 0) {
                localStorage.removeItem(STORARE_NAME_LIST_NHAN_SU);
                message.success('Tạo mới Nhân sự thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Tạo Mới</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form
                            form={form}
                            name="detail"
                            onFinish={onSubmit}
                            initialValues={{
                                ngayDang: dayjs().startOf('day'), // Lấy ngày hiện tại
                            }}
                            className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/nhan-su">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Mã</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ma"
                                                        rules={[{ required: true, message: 'Vui lòng nhập mã!' }]}>
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Họ, tên đệm</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="hoTenDem"
                                                        rules={[{ required: true, message: 'Vui lòng nhập Họ, tên đêm!' }]}>
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Tên</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ten"
                                                        rules={[{ required: true, message: 'Vui lòng nhập Tên!' }]}>
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Tên Thánh</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idTenThanh"
                                                            rules={[{ required: true, message: 'Vui lòng chọn Tên Thánh!' }]}>
                                                            <Select placeholder="Chọn Tên Thánh" size="large" showSearch optionFilterProp="label">
                                                                <option value="" label=''></option>
                                                                {lstTenThanh?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenThanh}>{item.tenThanh}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Tình trạng</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idTinhTrang"
                                                            rules={[{ required: true, message: 'Vui lòng chọn Tình trạng!' }]}>
                                                            <Select placeholder="Chọn tình trạng" size="large">
                                                                <option value=""></option>
                                                                {lstTinhTrang?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id}>{item.tenTinhTrang}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Chức Thánh</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idChucThanh"
                                                            rules={[{ required: true, message: 'Vui lòng chọn Chức Thánh!' }]}>
                                                            <Select placeholder="Chọn Chức Thánh" size="large" showSearch optionFilterProp="label">
                                                                <option value="" label=''></option>
                                                                {lstChucThanh?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenChucThanh}>{item.tenChucThanh}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Cộng đoàn</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idCongDoan">
                                                            <Select placeholder="Chọn cộng đoàn" size="large" showSearch optionFilterProp="label">
                                                                <option value="" label=''></option>
                                                                {lstCongDoan?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenCongDoan}>{item.tenCongDoan}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Muc vụ</label>
                                                <div className="col-sm-8">
                                                    <Form.Item name="idMucVu">
                                                        <Select placeholder="Chọn Mục Vụ" size="large">
                                                            <option value=""></option>
                                                            {lstMucVu?.map((item: any, index) => (
                                                                <Select.Option key={index} value={item.id}>{item.tenMucVu}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Quốc Gia</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idQuocGia">
                                                            <Select placeholder="Chọn Quốc Gia" size="large" showSearch optionFilterProp="label">
                                                                <option value="" label=''></option>
                                                                {lstQuocGia?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenQuocGia}>{item.tenQuocGia}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Email</label>
                                                <div className="col-sm-10">
                                                    <Form.Item name="email"
                                                        rules={[
                                                            {
                                                                type: 'email', // Kiểu kiểm tra là email
                                                                message: 'Vui lòng nhập đúng định dạng email!',
                                                            },
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng nhập email!',
                                                            },
                                                        ]}>
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-12'>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Địa chỉ</label>
                                                <div className="col-sm-10">
                                                    <Form.Item name="diaChi">
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-12'>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Điện thoại</label>
                                                <div className="col-sm-10">
                                                    <Form.Item name="dienThoai">
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-12'>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">User level</label>
                                                <div className="col-sm-10">
                                                    <Form.Item name="userLevel" rules={[{ required: true, message: 'Vui lòng chọn User level' }]}>
                                                        <Radio.Group value={'ROLE_USER'}>
                                                            <Radio value="ROLE_ADMIN">Administrator</Radio>
                                                            <Radio value="ROLE_USER_CO">User-co</Radio>
                                                            <Radio value="ROLE_USER">User</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Hình ảnh</label>
                                                <div className="col-sm-8">
                                                    <Form.Item name="anhDaiDien">
                                                        <InputImage onChange={handleImageChange} initialValue={ANH_DAI_DIEN_MAC_DINH} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày sinh</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngaySinh"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày khấn</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayKhan"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày phó tế</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayPhoTe"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày linh mục</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayLinhMuc"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày mất</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayMat"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row border-customer mb-3'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ông cố</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idTenThanhBa">
                                                            <Select placeholder="Chọn Tên Thánh" size="large" showSearch optionFilterProp="label">
                                                                <option value="" label=''></option>
                                                                {lstTenThanh?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenThanh}>{item.tenThanh}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <Form.Item name="hoTenBa">
                                                        <Input placeholder="nhập họ tên ông cố" className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày mất</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayBaMat"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row border-customer'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Bà cố</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idTenThanhMe">
                                                            <Select placeholder="Chọn Tên Thánh" size="large" showSearch optionFilterProp="label">
                                                                <option value="" label=''></option>
                                                                {lstTenThanh?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenThanh}>{item.tenThanh}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <Form.Item name="hoTenMe">
                                                        <Input placeholder="nhập họ tên bà cố" className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày mất</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayMeMat"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='row'>
                                <label className="col-sm-3 col-form-label">Ghi chú</label>
                                <div className="col-sm-12">
                                    <Form.Item name="ghiChu">
                                        <Input.TextArea
                                            rows={1}
                                            value={''}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/nhan-su">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
