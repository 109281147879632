import { api } from '../helpers';

const getAll = async () => {
    return await api.get('/admin-dcct/ten-thanh/all').then((response) => {
        return response.data;
    });
};

const filterList = async (page: number | null, size: number | null, sort: string | null, keySearch: any) => {
    const params = { page, size, sort, keySearch };
    return await api.get('/ten-thanhs', { params }).then((response: any) => {
        return response;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/admin-dcct/ten-thanh/delete/${id}`).then((response: any) => {
        return response.data;
    });
};

const createOrUpdate = async (id: any, tenThanh: string, ngayKinh: string, gioiTinh: string, hinhAnhViThanh: string) => {
    const payLoad = {
        id, tenThanh, ngayKinh, gioiTinh, hinhAnhViThanh
    };
    return await api.post('/admin-dcct/ten-thanh/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const getById = async (id: any) => {
    return await api.get(`/ten-thanhs/${id}`).then((response: any) => {
        return response;
    });
};

export const tenThanhService = {
    getAll,
    filterList,
    deleteById,
    createOrUpdate,
    getById
};