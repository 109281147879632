import React, { Fragment, useState } from 'react'
import { lichDongService, tenThanhService } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { DatePicker, Form, Input, Radio, Select, message } from 'antd';
import InputImage from '../../components/InputImage';
import EditorCustom from '../../components/EditorCustom';
import { ANH_MAC_DINH } from '../../helpers/constants';

export const ThemMoiLichDong = () => {
    // Function to get the current date with time set to 00:00:00
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loadingLinhMuc, setLoadingLinhMuc] = useState(false);
    const [dateSelected, setDateSelected] = useState(null);

    // cho image
    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState(ANH_MAC_DINH);
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };
    const onClose = () => {
        navigate('/lich-dong');
    };
    const onSubmit = async (values: any) => {
        setLoadingLinhMuc(true);
        try {
            setDateSelected(values.ngay.format("DD.MM.YYYY"));

            const response = await lichDongService.createOrUpdate(null, values.ngay ? values.ngay.format("DD.MM.YYYY") : null,
                values.tieuDe, values.tieuDePhu, urlImgAnhDaiDien ? urlImgAnhDaiDien : ANH_MAC_DINH, values.mauAoLe, values.ghiChu);
            if (response.code === 0) {
                message.success('Tạo mới Lịch dòng thành công', onClose)
            } else {
                setLoadingLinhMuc(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoadingLinhMuc(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    // cho trình soạn thảo văn bản
    const [chiTietThongTin, setChiTietThongTin] = useState('');
    const handleEditorChange = (value: string) => {
        setChiTietThongTin(value);
    };
    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Lịch Dòng / Tạo Mới</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4' style={{ backgroundColor: 'rgb(255 201 201)' }}>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={form} name="detail" onFinish={onSubmit} className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/lich-dong">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loadingLinhMuc}> <>
                                            {loadingLinhMuc && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Ngày</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="ngay" rules={[{ required: true, message: 'Vui lòng chọn ngày!' }]}>
                                                <DatePicker
                                                    size="large"
                                                    format="DD.MM.YYYY"
                                                    showTime={false}
                                                    inputReadOnly={true} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tiêu đề</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="tieuDe">
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tiêu đề phụ</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="tieuDePhu">
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                        <div className="col-sm-9">
                                            <Form.Item name="anhDaiDien">
                                                <InputImage onChange={handleImageChange} initialValue={ANH_MAC_DINH} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Màu áo lễ</label>
                                        <div className="col-sm-9">
                                            <Form.Item name="mauAoLe" rules={[{ required: true, message: 'Vui lòng chọn Màu áo lễ' }]}>
                                                <Radio.Group value={'green'}>
                                                    <Radio value="green">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_green.png'} />
                                                    </Radio>
                                                    <Radio value="white">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_white.png'} />
                                                    </Radio>
                                                    <Radio value="red">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_red.png'} />
                                                    </Radio>
                                                    <Radio value="violet">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_violet.png'} />
                                                    </Radio>
                                                    <Radio value="pink">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_pink.png'} />
                                                    </Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <label className="col-sm-3 col-form-label">Ghi chú</label>
                                <div className="col-sm-12">
                                    <Form.Item name="ghiChu">
                                        <EditorCustom onChange={handleEditorChange} initialValue={''} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to={`/lich-dong?${dateSelected}`}>Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loadingLinhMuc}> <>
                                            {loadingLinhMuc && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
