import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DatePicker, Form, Input, message } from 'antd';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { suKienService } from '../../services';

export const SuKien = () => {
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);
    const fetchData = (date: any) => {
        setLoading(true);
        suKienService.getAll(date)
            .then((response) => {
                if (response.code === 0) {
                    setLstDanhSach(response.data);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData(dayjs().startOf('day').format("DD.MM.YYYY"));
    }, []);

    const refreshData = async () => {
        try {
            const values = await form.validateFields();
            fetchData(values.mmDDSelected.format("DD.MM.YYYY"));
        } catch (error: any) {
            message.error({ content: "Chưa chọn năm", duration: 2 });
        }
    };

    const handleDelete = async (id: any) => {
        const result = await Swal.fire({
            title: 'Xác nhận xóa Sự Kiện Này này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const response = await suKienService.deleteById(id);
                if (response.status === 204) {
                    message.success('Xóa Sự Kiện thành công');
                    const values = await form.validateFields();
                    fetchData(values.mmDDSelected.format("DD.MM.YYYY"));
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>Lịch Dòng / Sự Kiện</h1>
                </div>
                {/* Content Row */}
                <Form form={form}
                    name="search"
                    initialValues={{
                        mmDDSelected: dayjs().startOf('day'), // Lấy ngày hiện tại
                    }}>
                    <div className="row">
                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                <Form.Item name="keySearch">
                                    <Input className='form-control' placeholder="Tìm kiếm theo tiêu đề" autoComplete="off" />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                <Form.Item name="mmDDSelected">
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        size="large"
                                        format="DD.MM.YYYY"
                                        inputReadOnly={true} />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>

                <div className="row">
                    <div className="col-12">
                        <div className='row'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary" onClick={refreshData} disabled={loading}>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Lọc dữ liệu
                                    </button>
                                    <Link type="button" className="btn btn-info ml-2" to="/su-kien/them-moi" >Tạo mới</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead style={{ backgroundColor: '#fac2b5' }}>
                                    <tr>
                                        <th className='text-center'>Thời gian</th>
                                        <th className='text-center'>Phân loại</th>
                                        <th className='text-center'>Tên sự kiện</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={3}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach?.map((item, index) => (
                                                <tr style={{ color: '#000', fontWeight: '400' }} key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td style={{ color: 'rgb(232, 59, 70)', fontWeight: 'bold' }} >{item.tuGio} - {item.denGio}</td>
                                                    <td>{
                                                        item.loaiSuKien ? (
                                                            <span>
                                                                {item.loaiSuKien === 'TOAN_TINH' ? "Toàn Tỉnh"
                                                                    : item.loaiSuKien === 'CONG_DOAN' ? item.congDoans[0]?.tenCongDoan
                                                                        : item.loaiSuKien === 'CA_NHAN' ? item.nhanSus[0]?.user?.firstName + ' ' + item.nhanSus[0]?.user?.lastName
                                                                            : ""}
                                                            </span>
                                                        ) : (<span>Chứa xác thực thông tin</span>)
                                                    }
                                                    </td>
                                                    <td>
                                                        {item.tenSuKien}
                                                    </td>
                                                    <td style={{ display: 'flex' }}>
                                                        <Link to={`/su-kien/chinh-sua/${item.id}`} style={{ marginLeft: '10px' }}>
                                                            {/* Chèn nội dung bên trong thẻ Link */}
                                                            <img style={{ width: '22px' }} src='/images/web_new.png' alt={item.ma} />
                                                        </Link>
                                                        <img onClick={() => handleDelete(item.id)} style={{ width: '23px', cursor: 'pointer', marginLeft: '10px' }} src='/images/web_delete.png' alt={item.ma} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}
