import { api } from '../helpers';

const getAll = async () => {
    return await api.get('/admin-dcct/thu-cha-giam-tinh/all').then((response) => {
        return response.data;
    });
};

const getAllByYear = async (year: any) => {
    const params = { year };
    return await api.get('/admin-dcct/thu-cha-giam-tinh/list-by-date', { params }).then((response: any) => {
        return response.data;
    });
};

const themMoiOrChinhSuaTCGT = async (id: any, so: number, thangNam: string, tieuDe: string, gioiThieu: string, chiTietTin: string, hinhAnhDaiDien: string, fileNoiDung: string) => {
    const payLoad = { id, so, thangNam, tieuDe, gioiThieu, chiTietTin, hinhAnhDaiDien, fileNoiDung };
    return await api.post('/admin-dcct/thu-cha-giam-tinh/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/admin-dcct/thu-cha-giam-tinh/delete/${id}`).then((response: any) => {
        return response.data;
    });
};

const getById = async (id: any) => {
    return await api.get(`/thu-cha-giam-tinhs/${id}`).then((response: any) => {
        return response;
    });
};


export const thuChaGiamTinhService = {
    getAllByYear,
    themMoiOrChinhSuaTCGT,
    deleteById,
    getById,
    getAll
};