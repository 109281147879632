import './styles/sb-admin-2.css';
import './assets/font-awesome/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AccountRoute } from './components/AccountRoute';
import { PrivateRoute } from './components/PrivateRoute';
import { ThongTin } from './pages/admin/ThongTin.DanhSach';
import { ThemMoiThongTin } from './pages/admin/ThongTin.ThemMoi';
import { ChinhSuaThongTin } from './pages/admin/ThongTin.ChinhSua';
import { LoaiThongTin } from './pages/admin/LoaiThongTin.DanhSach';
import { ThemMoiLoaiThongTin } from './pages/admin/LoaiThongTin.ThemMoi';
import { ChinhSuaLoaiThongTin } from './pages/admin/LoaiThongTin.ChinhSua';
import { ThuChaGiamTinh } from './pages/admin/ThuChaGiamTinh.DanhSach';
import { ThemMoiThuChaGiamTinh } from './pages/admin/ThuChaGiamTinh.ThemMoi';
import { ChinhSuaThuChaGiamTinh } from './pages/admin/ThuChaGiamTinh.ChinhSua';
import { NhanSu } from './pages/admin/NhanSu.DanhSach';
import { ThemMoiNhanSu } from './pages/admin/NhanSu.ThemMoi';
import { ChinhSuaNhanSu } from './pages/admin/NhanSu.ChinhSua';
import { CongDoan } from './pages/admin/CongDoan.DanhSach';
import { ThemMoiCongDoan } from './pages/admin/CongDoan.ThemMoi';
import { ChinhSuaCongDoan } from './pages/admin/CongDoan.ChinhSua';
import { TenThanh } from './pages/admin/TenThanh.DanhSach';
import { ThemMoiTenThanh } from './pages/admin/TenThanh.ThemMoi';
import { ChinhSuaTenThanh } from './pages/admin/TenThanh.ChinhSua';
import { LichDong } from './pages/admin/LichDong.DanhSach';
import { ThemMoiLichDong } from './pages/admin/LichDong.ThemMoi';
import { ChinhSuaLichDong } from './pages/admin/LichDong.ChinhSua';
import { SuKien } from './pages/admin/SuKien.DanhSach';
import { ThemMoiSuKien } from './pages/admin/Sukien.ThemMoi';
import { ChinhSuaSuKien } from './pages/admin/SuKien.ChinhSua';
import { TaiLieu } from './pages/admin/TaiLieu.DanhSach';
import { ThemMoiTaiLieu } from './pages/admin/TaiLieu.ThemMoi';
import { ChinhSuaTaiLieu } from './pages/admin/TaiLieu.ChinhSua';
import { LoaiTaiLieu } from './pages/admin/LoaiTaiLieu.DanhSach';
import { ThemMoiLoaiTaiLieu } from './pages/admin/LoaiTaiLieu.ThemMoi';
import { ChinhSuaLoaiTaiLieu } from './pages/admin/LoaiTaiLieu.ChinhSua';
import { ChucThanh } from './pages/admin/ChucThanh.DanhSach';
import { ThemMoiChucThanh } from './pages/admin/ChucThanh.ThemMoi';
import { ChinhSuaChucThanh } from './pages/admin/ChucThanh.ChinhSua';
import { MucVu } from './pages/admin/MucVu.DanhSach';
import { ThemMoiMucVu } from './pages/admin/MucVu.ThemMoi';
import { ChinhSuaMucVu } from './pages/admin/MucVu.ChinhSua';
import { Vung } from './pages/admin/Vung.DanhSach';
import { ThemMoiVung } from './pages/admin/Vung.ThemMoi';
import { ChinhSuaVung } from './pages/admin/Vung.ChinhSua';
import { QuocGia } from './pages/admin/QuocGia.DanhSach';
import { ThemMoiQuocGia } from './pages/admin/QuocGia.ThemMoi';
import { ChinhSuaQuocGia } from './pages/admin/QuocGia.ChinhSua';
import { TinhTrang } from './pages/admin/TinhTrang.DanhSach';
import { ThemMoiTinhTrang } from './pages/admin/TinhTrang.ThemMoi';
import { ChinhSuaTinhTrang } from './pages/admin/TinhTrang.ChinhSua';
import { useSelector } from 'react-redux';
import { AppState } from './store';
import { AuthenticatedUser } from './store/account/types';
import { useEffect } from 'react';
import { Policy } from './pages/login';
import { ChatDanhSachNhom } from './pages/admin/Chat.DanhSachNhom';

function App() {
  const account = useSelector<AppState>((state) => state.account.user) as AuthenticatedUser;
  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <div className='App' id='wrapper'>
      <Router>
        <Routes>
          <Route path='/policy.html' element={<Policy />} />
          <Route path='/' element={<AccountRoute />} />
          <Route path='/login' element={<AccountRoute />} />
          <Route path='/tin-tuc' element={
            <PrivateRoute>
              <ThongTin />
            </PrivateRoute>
          } />
          <Route path='/tin-tuc/them-moi' element={
            <PrivateRoute>
              <ThemMoiThongTin />
            </PrivateRoute>
          } />
          <Route path="/tin-tuc/chinh-sua/:id" element={
            <PrivateRoute>
              <ChinhSuaThongTin />
            </PrivateRoute>
          } />
          <Route path='/loai-tin-tuc' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<LoaiThongTin />)}
            </PrivateRoute>
          } />
          <Route path='/loai-tin-tuc/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiLoaiThongTin />)}
            </PrivateRoute>
          } />
          <Route path='/loai-tin-tuc/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaLoaiThongTin />)}
            </PrivateRoute>
          } />
          <Route path='/thu-cha-giam-tinh' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThuChaGiamTinh />)}
            </PrivateRoute>
          } />
          <Route path='/thu-cha-giam-tinh/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiThuChaGiamTinh />)}
            </PrivateRoute>
          } />
          <Route path='/thu-cha-giam-tinh/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaThuChaGiamTinh />)}
            </PrivateRoute>
          } />
          <Route path='/nhan-su' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<NhanSu />)}
            </PrivateRoute>
          } />
          <Route path='/nhan-su/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiNhanSu />)}
            </PrivateRoute>
          } />
          <Route path="/nhan-su/chinh-sua/:id" element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaNhanSu />)}
            </PrivateRoute>
          } />
          <Route path='/cong-doan' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<CongDoan />)}
            </PrivateRoute>
          } />
          <Route path='/cong-doan/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiCongDoan />)}
            </PrivateRoute>
          } />
          <Route path="/cong-doan/chinh-sua/:id" element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaCongDoan />)}
            </PrivateRoute>
          } />
          <Route path='/ten-thanh' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<TenThanh />)}
            </PrivateRoute>
          } />
          <Route path='/ten-thanh/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiTenThanh />)}
            </PrivateRoute>
          } />
          <Route path='/ten-thanh/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaTenThanh />)}
            </PrivateRoute>
          } />
          <Route path='/lich-dong' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<LichDong />)}
            </PrivateRoute>
          } />
          <Route path='/lich-dong/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiLichDong />)}
            </PrivateRoute>
          } />
          <Route path='/lich-dong/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaLichDong />)}
            </PrivateRoute>
          } />
          <Route path='/su-kien' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<SuKien />)}
            </PrivateRoute>
          } />
          <Route path='/su-kien/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiSuKien />)}
            </PrivateRoute>
          } />
          <Route path='/su-kien/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaSuKien />)}
            </PrivateRoute>
          } />
          <Route path='/tai-lieu' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<TaiLieu />)}
            </PrivateRoute>
          } />
          <Route path='/tai-lieu/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiTaiLieu />)}
            </PrivateRoute>
          } />
          <Route path='/tai-lieu/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaTaiLieu />)}
            </PrivateRoute>
          } />
          <Route path='/phan-loai-tai-lieu' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<LoaiTaiLieu />)}
            </PrivateRoute>
          } />
          <Route path='/phan-loai-tai-lieu/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiLoaiTaiLieu />)}
            </PrivateRoute>
          } />
          <Route path='/phan-loai-tai-lieu/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaLoaiTaiLieu />)}
            </PrivateRoute>
          } />
          <Route path='/chuc-thanh' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChucThanh />)}
            </PrivateRoute>
          } />
          <Route path='/chuc-thanh/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiChucThanh />)}
            </PrivateRoute>
          } />
          <Route path='/chuc-thanh/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaChucThanh />)}
            </PrivateRoute>
          } />
          <Route path='/muc-vu' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<MucVu />)}
            </PrivateRoute>
          } />
          <Route path='/muc-vu/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiMucVu />)}
            </PrivateRoute>
          } />
          <Route path='/muc-vu/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaMucVu />)}
            </PrivateRoute>
          } />
          <Route path='/vung' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<Vung />)}
            </PrivateRoute>
          } />
          <Route path='/vung/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiVung />)}
            </PrivateRoute>
          } />
          <Route path='/vung/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaVung />)}
            </PrivateRoute>
          } />
          <Route path='/quoc-gia' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<QuocGia />)}
            </PrivateRoute>
          } />
          <Route path='/quoc-gia/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiQuocGia />)}
            </PrivateRoute>
          } />
          <Route path='/quoc-gia/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaQuocGia />)}
            </PrivateRoute>
          } />
          <Route path='/tinh-trang' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<TinhTrang />)}
            </PrivateRoute>
          } />
          <Route path='/tinh-trang/them-moi' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ThemMoiTinhTrang />)}
            </PrivateRoute>
          } />
          <Route path='/tinh-trang/chinh-sua/:id' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChinhSuaTinhTrang />)}
            </PrivateRoute>
          } />
          <Route path='/chat/danh-sach-nhom' element={
            <PrivateRoute>
              {account?.userLevel !== 'Administrator' ? (<ThongTin />) : (<ChatDanhSachNhom />)}
            </PrivateRoute>
          } />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
