import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { chucThanhService, congDoanService, mucVuService, nhanSuService, quocGiaService, tenThanhService, tinhTrangService } from '../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Radio, Select, message } from 'antd';
import InputImage from '../../components/InputImage';
import { STORARE_NAME_LIST_NHAN_SU } from '../../helpers/constants';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');


export const ChinhSuaNhanSu = () => {
    // Function to get the current date with time set to 00:00:00
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const [lstTenThanh, setLstTenThanh] = useState<any[]>([]);
    const [lstTinhTrang, setLstTinhTrang] = useState<any[]>([]);
    const [lstCongDoan, setLstCongDoan] = useState<any[]>([]);
    const [lstChucThanh, setLstChucThanh] = useState<any[]>([]);
    const [lstMucVu, setLstMucVu] = useState<any[]>([]);
    const [lstQuocGia, setLstQuocGia] = useState<any[]>([]);
    const [objectDetail, setChiTiet] = useState<any>(null);

    const onClose = useCallback(() => {
        navigate('/nhan-su');
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tenThanhResponse = await tenThanhService.getAll();
                if (tenThanhResponse.code === 0) {
                    setLstTenThanh(tenThanhResponse.data);
                }

                const tinhTrangResponse = await tinhTrangService.getAll();
                if (tinhTrangResponse.code === 0) {
                    setLstTinhTrang(tinhTrangResponse.data);
                }

                const congDoanResponse = await congDoanService.getAll();
                if (congDoanResponse.code === 0) {
                    setLstCongDoan(congDoanResponse.data);
                }

                const chucThanhResponse = await chucThanhService.getAll();
                if (chucThanhResponse.code === 0) {
                    setLstChucThanh(chucThanhResponse.data);
                }

                const mucVuResponse = await mucVuService.getAll();
                if (mucVuResponse.code === 0) {
                    setLstMucVu(mucVuResponse.data);
                }

                const quocGiaResponse = await quocGiaService.getAll();
                if (quocGiaResponse.code === 0) {
                    setLstQuocGia(quocGiaResponse.data);
                }
                await nhanSuService.getById(id).then((response) => {
                    if (response.status === 200) {
                        setChiTiet(response.data);
                    } else if (response.status === 404) {
                        message.error('Id không tồn tại', onClose);
                    } else {
                        message.error(response.data.title, onClose);
                    }
                });
            } catch (error: any) {
                message.error(error.message, onClose);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchData();
        } else {
            message.error('Ngoại lệ, liên hệ hổ trợ', onClose);
        }
    }, [id, onClose]);

    useEffect(() => {
        if (objectDetail && tenThanhService && tinhTrangService && congDoanService && chucThanhService && quocGiaService && mucVuService) {
            const userAuthorities = objectDetail.user?.authorities || [];
            const authoritiesArray = userAuthorities.map((item: any) => item.name)
            const isAdmin = authoritiesArray.includes('ROLE_ADMIN');
            const isUserCo = authoritiesArray.includes('ROLE_USER_CO');

            dayjs.extend(utc);
            dayjs.extend(timezone);
            dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

            form.setFieldsValue({
                ma: objectDetail.ma,
                hoTenDem: objectDetail.user?.firstName,
                ten: objectDetail.user?.lastName,
                idTenThanh: objectDetail.tenThanh?.id,
                idTinhTrang: objectDetail.tinhTrang?.id,
                idChucThanh: objectDetail.chucThanh?.id,
                idCongDoan: objectDetail.congDoan?.id,
                idMucVu: objectDetail.mucVu?.id,
                idQuocGia: objectDetail.quocGia?.id,
                email: objectDetail.user?.login,
                diaChi: objectDetail.diaChi,
                dienThoai: objectDetail.dienThoai,
                userLevel: isAdmin ? 'ROLE_ADMIN' : isUserCo ? 'ROLE_USER_CO' : 'ROLE_USER',
                ghiChu: objectDetail.ghiChu,
                anhDaiDien: objectDetail.avatar,
                ngaySinh: objectDetail.ngaySinh ? dayjs(objectDetail.ngaySinh).format("DD.MM.YYYY") : '',
                ngayKhan: objectDetail.ngayKhan ? dayjs(objectDetail.ngayKhan).format("DD.MM.YYYY") : '',
                ngayPhoTe: objectDetail.ngayPhoTe ? dayjs(objectDetail.ngayPhoTe).format("DD.MM.YYYY") : '',
                ngayLinhMuc: objectDetail.ngayLinhMuc ? dayjs(objectDetail.ngayLinhMuc).format("DD.MM.YYYY") : '',
                ngayMat: objectDetail.ngayChet ? dayjs(objectDetail.ngayChet).format("DD.MM.YYYY") : '',
                idTenThanhBa: objectDetail.tenThanhCuaBa?.id,
                hoTenBa: objectDetail.hoTenBa,
                ngayBaMat: objectDetail.ngayMatCuaBa ? dayjs(objectDetail.ngayMatCuaBa).format("DD.MM.YYYY") : '',
                idTenThanhMe: objectDetail.tenThanhCuaMe?.id,
                hoTenMe: objectDetail.hoTenMe,
                ngayMeMat: objectDetail.ngayMatCuaMe ? dayjs(objectDetail.ngayMatCuaMe).format("DD.MM.YYYY") : '',
            });
            setUrlImgAnhDaiDien(objectDetail.avatar);
        }
    }, [objectDetail, form]);

    // cho image
    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState('');
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };

    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await nhanSuService.createOrUpdate(id, values.ma, values.hoTenDem, values.ten, values.idTenThanh, values.idTinhTrang, values.idChucThanh,
                values.idCongDoan, values.idMucVu, values.idQuocGia,
                values.ngayPhoTe ? values.ngayPhoTe : null,
                values.ngayLinhMuc ? values.ngayLinhMuc : null,
                values.email, values.diaChi, values.dienThoai, values.userLevel, values.ghiChu, values.anhDaiDien,
                values.ngaySinh ? values.ngaySinh : null,
                values.ngayKhan ? values.ngayKhan : null,
                values.ngayMat ? values.ngayMat : null,
                values.idTenThanhBa, values.hoTenBa,
                values.ngayBaMat ? values.ngayBaMat : null,
                values.idTenThanhMe, values.hoTenMe,
                values.ngayMeMat ? values.ngayMeMat : null)
            if (response.code === 0) {
                localStorage.removeItem(STORARE_NAME_LIST_NHAN_SU);
                message.success('Chỉnh sửa Nhân sự thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Chỉnh Sửa</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form
                            form={form}
                            name="detail"
                            onFinish={onSubmit}
                            initialValues={{
                                ngayDang: dayjs().startOf('day'), // Lấy ngày hiện tại
                            }}
                            className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/nhan-su">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Mã</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ma"
                                                        rules={[{ required: true, message: 'Vui lòng nhập mã!' }]}>
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Họ, tên đệm</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="hoTenDem"
                                                        rules={[{ required: true, message: 'Vui lòng nhập Họ, tên đêm!' }]}>
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Tên</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ten"
                                                        rules={[{ required: true, message: 'Vui lòng nhập Tên!' }]}>
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Tên Thánh</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idTenThanh"
                                                            rules={[{ required: true, message: 'Vui lòng chọn Tên Thánh!' }]}>
                                                            <Select placeholder="Chọn Tên Thánh" size="large"
                                                                showSearch
                                                                optionFilterProp="label"
                                                            >
                                                                <option value="" label={''}></option>
                                                                {lstTenThanh?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenThanh}>{item.tenThanh}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Tình trạng</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idTinhTrang"
                                                            rules={[{ required: true, message: 'Vui lòng chọn Tình trạng!' }]}>
                                                            <Select placeholder="Chọn tình trạng" size="large">
                                                                <option value=""></option>
                                                                {lstTinhTrang?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id}>{item.tenTinhTrang}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Chức Thánh</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idChucThanh"
                                                            rules={[{ required: true, message: 'Vui lòng chọn Chức Thánh!' }]}>
                                                            <Select placeholder="Chọn Chức Thánh" size="large">
                                                                <option value=""></option>
                                                                {lstChucThanh?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id}>{item.tenChucThanh}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Cộng đoàn</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idCongDoan">
                                                            <Select placeholder="Chọn cộng đoàn" size="large"
                                                                showSearch optionFilterProp="label">
                                                                <option value="" label={''}></option>
                                                                {lstCongDoan?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenCongDoan}>{item.tenCongDoan}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Muc vụ</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idMucVu">
                                                            <Select placeholder="Chọn Mục Vụ" size="large">
                                                                <option value=""></option>
                                                                {lstMucVu?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id}>{item.tenMucVu}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Quốc Gia</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idQuocGia">
                                                            <Select placeholder="Chọn Quốc Gia" size="large"
                                                                showSearch optionFilterProp="label">
                                                                <option value="" label=""></option>
                                                                {lstQuocGia?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenQuocGia}>{item.tenQuocGia}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Email</label>
                                                <div className="col-sm-10">
                                                    <Form.Item name="email"
                                                        rules={[
                                                            {
                                                                type: 'email', // Kiểu kiểm tra là email
                                                                message: 'Vui lòng nhập đúng định dạng email!',
                                                            },
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng nhập email!',
                                                            },
                                                        ]}>
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-12'>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Địa chỉ</label>
                                                <div className="col-sm-10">
                                                    <Form.Item name="diaChi">
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-12'>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">Điện thoại</label>
                                                <div className="col-sm-10">
                                                    <Form.Item name="dienThoai">
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-12'>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">User level</label>
                                                <div className="col-sm-10">
                                                    <Form.Item name="userLevel" rules={[{ required: true, message: 'Vui lòng chọn User level' }]}>
                                                        <Radio.Group>
                                                            <Radio value="ROLE_ADMIN">Administrator</Radio>
                                                            <Radio value="ROLE_USER_CO">User-co</Radio>
                                                            <Radio value="ROLE_USER">User</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Hình ảnh</label>
                                                <div className="col-sm-8">
                                                    <Form.Item name="anhDaiDien">
                                                        {objectDetail ? (
                                                            <InputImage onChange={handleImageChange} initialValue={objectDetail.avatar} />
                                                        ) : (
                                                            <p><span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...</p>
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày sinh</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngaySinh"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày khấn</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayKhan"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày phó tế</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayPhoTe"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày linh mục</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayLinhMuc"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày mất</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayMat"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row border-customer mb-3'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ông cố</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idTenThanhBa">
                                                            <Select placeholder="Chọn Tên Thánh"
                                                                size="large"
                                                                showSearch
                                                                optionFilterProp="label">
                                                                <option value=""></option>
                                                                {lstTenThanh?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenThanh}>{item.tenThanh}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <Form.Item name="hoTenBa">
                                                        <Input placeholder="nhập họ tên ông cố" className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày mất</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayBaMat"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row border-customer'>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Bà cố</label>
                                                <div className="col-sm-8">
                                                    {loading ? (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    ) : (
                                                        <Form.Item name="idTenThanhMe">
                                                            <Select placeholder="Chọn Tên Thánh" size="large"
                                                                showSearch optionFilterProp="label">
                                                                <option value="" label=""></option>
                                                                {lstTenThanh?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id} label={item.tenThanh}>{item.tenThanh}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <Form.Item name="hoTenMe">
                                                        <Input placeholder="nhập họ tên bà cố" className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-4 col-form-label">Ngày mất</label>
                                                <div className="col-sm-8">
                                                    <Form.Item
                                                        name="ngayMeMat"
                                                        rules={[
                                                            {
                                                                pattern: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
                                                                message: 'Định dạng ngày tháng năm không hợp lệ. Vui lòng nhập theo định dạng DD.MM.YYYY!',
                                                            }
                                                        ]}>
                                                        <Input className='form-control' placeholder='DD.MM.YYYY' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className='row'>
                                <label className="col-sm-3 col-form-label">Ghi chú</label>
                                <div className="col-sm-12">
                                    <Form.Item name="ghiChu">
                                        <Input.TextArea
                                            rows={1}
                                            value={''}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/nhan-su">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
