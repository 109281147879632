import { api } from '../helpers';

const getAll = async () => {
    const params = { page: 0, size: 100, sort: 'id' };
    return await api.get('/vungs', { params }).then((response) => {
        return response;
    });
};

const filterList = async (page: number | null, size: number | null, sort: string | null) => {
    const params = { page, size, sort };
    return await api.get('/vungs', { params }).then((response: any) => {
        return response;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/vungs/${id}`).then((response: any) => {
        return response;
    });
};

const create = async (id: any, tenVung: string) => {
    const payLoad = {
        id, tenVung
    };
    return await api.post('/vungs', payLoad).then((response: any) => {
        return response;
    });
};

const getById = async (id: any) => {
    return await api.get(`/vungs/${id}`).then((response: any) => {
        return response;
    });
};

const update = async (id: any, tenVung: string) => {
    const payLoad = {
        id, tenVung
    };
    return await api.patch(`/vungs/${id}`, payLoad).then((response: any) => {
        return response;
    });
};

export const vungService = {
    getAll,
    filterList,
    deleteById,
    create,
    getById,
    update
};