import { api } from '../helpers';

const authenticate = async (email: string, matKhau: string, rememberMe: boolean) => {
    const body = { email, matKhau, rememberMe };
    return await api.post('/admin-dcct/login', body).then((response) => {
        return response.data;
    });
};

const logout = () => {
    sessionStorage.removeItem('user');
};


const getCurrentLoginUser = async () => {
    return await api.get('/dcct/tai-khoan/chi-tiet').then((response) => {
        return response.data;
    });
}

export const userService = {
    authenticate,
    logout,
    getCurrentLoginUser
};