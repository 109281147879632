import React, { useState } from 'react';
import { imageService } from '../services';
import resizeImage from './ImageUtils';

interface InputImageProps {
    onChange: (content: string) => void;
    initialValue: string;
}

const InputImage: React.FC<InputImageProps> = ({ onChange, initialValue }) => {
    const [imageUrl, setImageUrl] = useState<string>(initialValue);
    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            try {
                const resizedFile = await resizeImage(file);
                const response = await imageService.uploadImage(resizedFile);
                if (response.status === 200) {
                    const imageUrl = response.data;
                    setImageUrl(imageUrl);
                    onChange(imageUrl);
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    return (
        <div>
            {imageUrl && <img style={{ width: '150px', border: '1px solid #ddd', padding: '10px' }} src={imageUrl} />}
            <input type="file" className="btn btn-primary" accept="image/*" onChange={handleFileChange} />
        </div>
    );
};

export default InputImage;
