import React, { Fragment, useEffect, useState } from 'react'
import { tintucService } from '../../services';
import InputImage from '../../components/InputImage';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Select, message } from 'antd';
import { ANH_MAC_DINH } from '../../helpers/constants';

export const ChinhSuaLoaiThongTin = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const onClose = () => {
        navigate('/loai-tin-tuc');
    };
    const [lstLoaiTin, setLstLoaiTin] = useState<any[]>([]);
    const [objectDetail, setChiTiet] = useState<any>(null);
    useEffect(() => {
        if (id) {
            tintucService.getAllDanhSachLoaiTinPhanCap().then((response) => {
                if (response.code === 0) {
                    setLstLoaiTin(response.data);
                }
            });

            tintucService.getLoaiTinTucTheoId(id).then((response) => {
                if (response.status === 200) {
                    setChiTiet(response.data);
                } else if (response.status === 404) {
                    message.error('Id không tồn tại', onClose);
                } else {
                    message.error(response.data.title, onClose);
                }
            });
        } else {
            message.error('Ngoại lệ, liên hệ hổ trợ', onClose);
        }
        setLoading(false);
    }, [id]);

    useEffect(() => {
        if (objectDetail) {
            form.setFieldsValue({
                tieuDe: objectDetail.tenLoai,
                idLoaiCha: objectDetail.loaiTinTucCha?.id,
                anhDaiDien: objectDetail.hinhDaiDien,
            });
            setUrlImgAnhDaiDien(objectDetail.hinhDaiDien);
            setLoading(false)
        }
    }, [objectDetail]);

    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await tintucService.themMoiOrChinhSuaLoaiThongTin(id, values.idLoaiCha, values.tieuDe, values.anhDaiDien ? values.anhDaiDien : ANH_MAC_DINH)
            if (response.code === 0) {
                message.success('Tạo mới loại tin tức thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 1 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    // cho image
    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState('');
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>THÔNG TIN / Loại Tin Tức / Chỉnh Sửa</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form
                            form={form}
                            name="detail"
                            onFinish={onSubmit}
                            className="c-formNew"
                        >
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/loai-tin-tuc">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    {objectDetail ? (
                                        <div className="form-group row">
                                            {objectDetail.loaiTinTucCha ? (
                                                <Fragment>
                                                    <label className="col-sm-3 col-form-label">Phân loại</label>
                                                    <div className="col-sm-8">
                                                        <Form.Item name="idLoaiCha"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Vui lòng chọn loại cha',
                                                                }
                                                            ]}>
                                                            <Select placeholder="Chọn Loại" size="large">
                                                                <option value=""></option>
                                                                {lstLoaiTin?.map((item: any, index) => (
                                                                    <Select.Option key={index} value={item.id}>{item.tenLoai}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </Fragment>
                                            ) : null}
                                        </div>
                                    ) : null}

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tiêu đề</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tieuDe"
                                                rules={[{ required: true, message: 'Vui lòng nhập tiêu đề !' }]}>
                                                <Input autoComplete="off"  className='form-control' />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="anhDaiDien"
                                                rules={[{ required: true, message: 'Vui lòng chọn ảnh đại diện !' }]}>
                                                {objectDetail ? (
                                                    <InputImage onChange={handleImageChange} initialValue={objectDetail.hinhDaiDien} />
                                                ) : (
                                                    <p><span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...</p>
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/loai-tin-tuc">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
