import { api } from '../helpers';

const getAll = async (date: string) => {
    const params = { date };
    return await api.get('/admin-dcct/su-kien/get-by-date', { params }).then((response: any) => {
        return response.data;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/su-kiens/${id}`).then((response: any) => {
        return response;
    });
};

const createOrUpdate = async (id: any, trongNgay: string, loaiSuKien: string, tuGio: string, denGio: string, tenSuKien: string,
    chiTietSuKien: string, idCongDoan: number, idNhanSu: number, denNgay: string) => {
    const payLoad = {
        id, trongNgay, loaiSuKien, tuGio, denGio, tenSuKien, chiTietSuKien, idCongDoan, idNhanSu, denNgay
    };
    return await api.post('/admin-dcct/su-kien/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const getById = async (id: any) => {
    return await api.get(`/su-kiens/${id}`).then((response: any) => {
        return response;
    });
};

export const suKienService = {
    getAll,
    deleteById,
    createOrUpdate,
    getById
};