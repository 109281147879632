import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DatePicker, Form, message } from 'antd';
import { homeService, thuChaGiamTinhService } from '../../services';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import MoveButtons from '../../components/MoveButtons';

export const ThuChaGiamTinh = () => {
    const [loading, setLoading] = useState(true);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);
    const [form] = Form.useForm();

    const fetchData = async (yearSelected: dayjs.Dayjs) => {
        setLoading(true);
        try {
            const response = await thuChaGiamTinhService.getAllByYear(yearSelected.year());
            if (response.code === 0) {
                setLstDanhSach(response.data);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(dayjs()); // Fetch data with current year as default
    }, []);

    const refreshData = async () => {
        try {
            const values = await form.validateFields();
            fetchData(values.yearSelected);
        } catch (error: any) {
            message.error({ content: "Chưa chọn năm", duration: 2 });
        }
    };

    const handleDelete = async (id: any) => {
        const result = await Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa tin tức này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const response = await thuChaGiamTinhService.deleteById(id);
                if (response.code === 0) {
                    const selectedYear = form.getFieldValue('yearSelected');
                    fetchData(selectedYear);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setLoading(false);
            }
        }
    };

    const move = async (id: any, type: string, flagUpDown: boolean) => {
        setLoading(true);
        try {
            const response = await homeService.moveUpDown(id, null, type, flagUpDown);
            if (response.code === 0) {
                const selectedYear = form.getFieldValue('yearSelected');
                fetchData(selectedYear);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>THÔNG TIN / Thư Cha Giám Tỉnh</h1>
                </div>
                {/* Content Row */}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className='col-xl-3 col-md-6'>
                                <div className="form-group">
                                    <Form form={form}
                                        name="detail"
                                        initialValues={{
                                            yearSelected: dayjs().startOf('day'), // Lấy ngày hiện tại
                                        }}
                                        onFinish={refreshData}>
                                        <Form.Item name="yearSelected">
                                            <DatePicker style={{width: '100%'}}
                                                size="large"
                                                picker="year"
                                                format="YYYY"
                                                inputReadOnly={true}
                                                onChange={() => form.submit()}
                                            />
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary" onClick={refreshData} disabled={loading}>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Lọc dữ liệu
                                    </button>
                                    <Link type="button" className="btn btn-info ml-2" to="/thu-cha-giam-tinh/them-moi" >Tạo mới</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover table-custom-with" id="dataTable" width="100%" cellSpacing={0}>
                                <thead style={{ backgroundColor: '#fac2b5' }}>
                                    <tr>
                                        <th>Số</th>
                                        <th>Tháng năm</th>
                                        <th>Người tạo / ngày giờ</th>
                                        <th>Thứ tự</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={6}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        <Fragment>
                                            {lstDanhSach?.map((item: any, index: number) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td>{item.so}</td>
                                                    <td>{item.strThangNam}</td>
                                                    <td>{item.nguoiTao} / {item.ngayGioTao}</td>
                                                    <td style={{ width: '10%' }}>
                                                        <MoveButtons onMoveUp={() => move(item.id, 'THU_CHA_GIAM_TINH', true)} onMoveDown={() => move(item.id, 'THU_CHA_GIAM_TINH', false)} />
                                                    </td>
                                                    <td>
                                                        <Link to={`/thu-cha-giam-tinh/chinh-sua/${item.id}`} style={{ marginRight: '10px' }}>
                                                            {/* Chèn nội dung bên trong thẻ Link */}
                                                            <img style={{ width: '22px' }} src='/images/web_new.png' alt={item.tenLoai} />
                                                        </Link>
                                                        <img onClick={() => handleDelete(item.id)} style={{ width: '23px', height: '23px', cursor: 'pointer' }} src='/images/web_delete.png' alt={item.tenLoai} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
