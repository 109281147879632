import { api } from '../helpers';

const getDataThongKe = async () => {
    return await api.get('/admin-dcct/data-home').then((response) => {
        return response.data;
    });
};

const moveUpDown = async (id: number, idCha: any, type: string, flagUpDown: boolean) => {
    const payLoad = {
        id, idCha, type, flagUpDown
    };
    return await api.post('/admin-dcct/move-up-down', payLoad).then((response: any) => {
        return response.data;
    });
};

export const homeService = {
    getDataThongKe,
    moveUpDown
};