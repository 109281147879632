import React, { Fragment } from 'react'

export const Policy = () => {
  return (
    <Fragment>
      <style dangerouslySetInnerHTML={{ __html: "\n        #wrapper {\n          display: block;\n        }\n      " }} />

      <div className="row" style={{ margin: 30, border: '1px solid #000' }}>
        <div className="col-lg-12">

          <p style={{ textAlign: 'right' }}>
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>TỈNH DÒNG CHÚA CỨU THẾ VIỆT NAM</span><br />
            <span style={{ fontSize: 18 }}>Địa chỉ: <a href="https://www.google.com/maps/place/38+%C4%90.+K%E1%BB%B3+%C4%90%E1%BB%93ng,+Ph%C6%B0%E1%BB%9Dng+9,+Qu%E1%BA%ADn+3,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam/@10.7814676,106.6784438,17z/data=!3m1!4b1!4m6!3m5!1s0x31752f28dc912901:0x2cd8fce11bc41e63!8m2!3d10.7814623!4d106.6810241!16s%2Fg%2F11t7t4_b8y?hl=vi-VN&entry=ttu" target='_blank'>38 Kỳ Đồng, Phường 9, Quận 3, HCM.</a>
            </span><br />
            <span style={{ fontSize: 18 }}>Liên hệ: <a href="tel:38438559">(84) (28) 3843 8559</a> Fax: <a href="tel:38438559">(84) (28) 3843 8559</a></span><br />
            <span style={{ fontSize: 18 }}>Email: <a href="mailto:dcctvn@gmail.com">dcctvn@gmail.com</a></span>
          </p>

          <h1 style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 20 }}><strong>ĐIỀU KHOẢN VÀ CHÍNH SÁCH BẢO MẬT APP DÒNG CHÚA CỨU THẾ</strong></span>
          </h1>
          <p>
            &nbsp;</p>
          <h3 style={{ textAlign: 'left' }}>
            <strong><span style={{ fontSize: 18 }}>1. Chính sách bảo mật (Privacy Policy)</span></strong>
          </h3>
          <p>
            <span style={{ fontSize: 18 }}>Ứng dụng CSSRVN là một ứng dụng private dùng để quản lý nhận sự nội bộ của Tỉnh Dòng Chúa Cứu Thế Việt năm.</span>
          </p>
          <p>
            <span style={{ fontSize: 18 }}>Cả 2 phiên bản trên iOS và Android đều không có chức năng đăng ký tài khoản, mà tài khoản sẽ được quản lý thêm mới, xóa, sửa tại trang web admin https://cssrvn.org/</span>
          </p>
          <p>
            <span style={{ fontSize: 18 }}>Khi khởi chạy, ứng dụng sẽ yêu cầu các quyền sau:</span>
          </p>
          <p>
            <span style={{ fontSize: 18 }}>&nbsp;&nbsp;- Quyền hiển thị thông báo khi có Tin Tức mới nội bộ của tỉnh dòng</span>
          </p>
          <p>
            <span style={{ fontSize: 18 }}>&nbsp;&nbsp;- Quyền được truy cập Internet</span>
          </p>
          <p>
            <span style={{ fontSize: 18 }}>&nbsp;&nbsp;- Quyền lưu file phục vụ cho gửi tài liệu nội bộ đến các thành viên trong tỉnh dòng</span>
          </p>
          <p>
            <span style={{ fontSize: 18 }}>Vì là ứng dụng quản lý nội bộ nên chúng tôi tuyệt đối không chia sẻ các thông tin cá nhân như email, họ tên... của các thành viên trong tỉnh dòng</span>
          </p>
          <h3 style={{ textAlign: 'left' }}>
            <strong><span style={{ fontSize: 18 }}>2. Vấn đề đăng nhập<param name="" value="" /></span></strong>
          </h3>
          <p>
            <span style={{ fontSize: 18 }}>Ưng dụng không cho phép người dùng tạo tài khoản mà tài khoản sẽ được admin tạo trên trang quản lý của Ứng dụng tại https://cssrvn.org/.</span>
          </p>
          <p>
            <span style={{ fontSize: 18 }}>Mật khẩu sẽ được người quản lý gửi qua email và thanh viên dùng mật khẩu đó để đăng nhập Ứng dụng</span>
          </p>
          <p>
            <span style={{ fontSize: 18 }}>Trên ứng dụng có chức năng quên mật khẩu và đổi mật khẩu. Với 2 chức năng này yêu cầu nhập email đã tồn tại trên hệ thống mới có thể sử dụng</span>
          </p>
          <p>
            <span style={{ fontSize: 18 }}> <strong>Lưu ý:</strong> Tài khoản sau khi đăng nhập sẽ được lưu lại trong ứng dụng để tự động đăng nhập cho những lần sử dụng sau này.</span>
          </p>
        </div>
        <p>&nbsp;</p>
      </div>
    </Fragment>
  );
}
