import { api } from '../helpers';

const getAll = async () => {
    return await api.get('/admin-dcct/cong-doan/all').then((response) => {
        return response.data;
    });
};

const getAllLoaiCongDoan = async () => {
    const params = { page: 0, size: 1000, sort: 'id' };
    return await api.get('/loai-cong-doans', { params }).then((response) => {
        return response;
    });
};

const filterList = async (page: number | null, size: number | null, sort: string | null, keySearch: any) => {
    const params = { page, size, sort, keySearch };
    return await api.get('/cong-doans', { params }).then((response: any) => {
        return response;
    });
};

const createOrUpdate = async (id: any, tenCongDoan: string, tenBonMang: string, idPhanLoai: number, idChaBeTren: number,
    anhDaiDien: string, ngayThanhLap: string, ngayBonMang: any, idVung: number, diaChi: string) => {
    const payLoad = {
        id, tenCongDoan, tenBonMang, idPhanLoai, idChaBeTren,
        anhDaiDien, ngayThanhLap, ngayBonMang, idVung, diaChi
    };
    return await api.post('/admin-dcct/cong-doan/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const getById = async (id: any) => {
    return await api.get(`/cong-doans/${id}`).then((response: any) => {
        return response;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/admin-dcct/cong-doan/delete/${id}`).then((response: any) => {
        return response.data;
    });
};

export const congDoanService = {
    getAll,
    filterList,
    getAllLoaiCongDoan,
    createOrUpdate,
    getById,
    deleteById
};