import { api } from '../helpers';

const getAll = async () => {
    return await api.get('/admin-dcct/loai-tai-lieu/all').then((response) => {
        return response.data;
    });
};

const filterList = async (page: number | null, size: number | null, sort: string | null) => {
    const params = { page, size, sort };
    return await api.get('/loai-tai-lieus', { params }).then((response: any) => {
        return response;
    });
};

const createOrUpdate = async (id: any, tenLoaiTaiLieu: string, hinhAnhDaiDien: string) => {
    const payLoad = {
        id, tenLoaiTaiLieu, hinhAnhDaiDien
    };
    return await api.post('/admin-dcct/loai-tai-lieu/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const getById = async (id: any) => {
    return await api.get(`/loai-tai-lieus/${id}`).then((response: any) => {
        return response;
    });
};

export const loaiTaiLieuService = {
    getAll,
    filterList,
    createOrUpdate,
    getById
};