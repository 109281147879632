import React, { Fragment, useEffect, useState } from 'react'
import { loaiTaiLieuService, taiLieuService } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { DatePicker, Form, Input, Select, Switch, message } from 'antd';
import InputImage from '../../components/InputImage';
import InputFile from '../../components/InputFile';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import EditorCustom from '../../components/EditorCustom';
import { ANH_MAC_DINH_TAI_LIEU } from '../../helpers/constants';

export const ThemMoiTaiLieu = () => {
    const onClose = () => {
        navigate('/tai-lieu');
    };

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [lstLoaiTaiLieu, setLstLoaiTaiLieu] = useState<any[]>([]);
    useEffect(() => {
        setLoading(true);
        Promise.all([
            loaiTaiLieuService.getAll(),
        ]).then(([loaiTaiLieuResponse]) => {
            if (loaiTaiLieuResponse.code === 0) setLstLoaiTaiLieu(loaiTaiLieuResponse.data);
        });
        setLoading(false);
    }, []);

    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await taiLieuService.createOrUpdate(null, values.tieuDe, chiTietThongTin, values.tacGia, values.ngayDang.format("DD.MM.YYYY"),
                values.trangThai ? true : false, urlImgAnhDaiDien ? urlImgAnhDaiDien : ANH_MAC_DINH_TAI_LIEU, urlFileUpload, values.idLoaiTaiLieu);
            if (response.code === 0) {
                message.success('Tạo mới Tài liệu thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const [urlFileUpload, setUrlFileUpload] = useState('');
    const handleFileChange = (value: string) => {
        setUrlFileUpload(value);
    };

    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState(ANH_MAC_DINH_TAI_LIEU);
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };

    // cho trình soạn thảo văn bản
    const [chiTietThongTin, setChiTietThongTin] = useState('');
    const handleEditorChange = (value: string) => {
        setChiTietThongTin(value);
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>TÀI LIỆU / Tạo Mới</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={form} name="detail" onFinish={onSubmit} className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/tai-lieu">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Ngày đăng</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="ngayDang"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng chọn Ngày đăng !'
                                                    }
                                                ]}
                                            >
                                                <DatePicker
                                                    size="large"
                                                    format="DD.MM.YYYY"
                                                    showTime={false}
                                                    inputReadOnly={true}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tiêu đề</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tieuDe"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập Tiêu đề !'
                                                    }
                                                ]}
                                            >
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tác giả</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tacGia"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập Tác giả !'
                                                    }
                                                ]}
                                            >
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>

                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tình trạng</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="trangThai" valuePropName="checked">
                                                <Switch defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Phân loại</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="idLoaiTaiLieu"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng chọn Loại tài liệu !'
                                                    }
                                                ]}
                                            >
                                                <Select placeholder="Loại tài liệu" size="large">
                                                    <Select.Option value="">{''}</Select.Option>
                                                    {lstLoaiTaiLieu?.map((item: any, index) => (
                                                        <Select.Option key={index} value={item.id}>{item.tenLoaiTaiLieu}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="anhDaiDien">
                                                <InputImage onChange={handleImageChange} initialValue={ANH_MAC_DINH_TAI_LIEU} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">File nội dung</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="fileNoiDung"
                                                rules={[{ required: true, message: 'Vui lòng upload lên file nội dung !' }]}>
                                                <InputFile onChange={handleFileChange} initialValue={''} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <label className="col-sm-3 col-form-label">Nội dung tài liệu</label>
                                <div className="col-sm-12">
                                    <Form.Item name="chiTietThongTin">
                                        <EditorCustom onChange={handleEditorChange} initialValue={''} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/tai-lieu">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
