import React, { useState, useEffect, useCallback } from 'react';
import SunEditor, { buttonList } from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import { isMobile } from 'react-device-detect';

interface EditorCustomProps {
    onChange: (content: string) => void;
    initialValue: string;
}

const EditorCustom: React.FC<EditorCustomProps> = ({ onChange, initialValue }) => {
    const [editorContent, setEditorContent] = useState<string>('');

    useEffect(() => {
        setEditorContent(initialValue);
    }, [initialValue]);

    const handleEditorChange = (content: string) => {
        setEditorContent(content);
        onChange(content);
    };

    return (
        <SunEditor height={isMobile ? "250px" : "500px"}
            setOptions={{
                buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    // ['paragraphStyle', 'blockquote'],
                    [
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript"
                    ],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],
                    ["outdent", "indent"],

                    ["table", "horizontalRule", "link", "audio", "image", "video"],
                    ["preview", "print", "codeView"],
                    ["removeFormat"]
                ],
                defaultTag: "div",
                minHeight: "250px",
                showPathLabel: false,
                imageWidth: '512px'
                
            }}
            onChange={handleEditorChange} setContents={editorContent} />
    );
};

export default EditorCustom;
