import React from 'react';

interface MoveButtonsProps {
    onMoveUp: () => void;
    onMoveDown: () => void;
}

const MoveButtons: React.FC<MoveButtonsProps> = ({ onMoveUp, onMoveDown }) => {
    return (
        <div>
            <img onClick={onMoveUp} style={{ width: '23px', cursor: 'pointer', marginLeft: '10px' }} src='/images/web_up.png' alt="Move Up" />
            <img onClick={onMoveDown} style={{ width: '23px', cursor: 'pointer', marginLeft: '10px' }} src='/images/web_down.png' alt="Move Down" />
        </div>
    );
};

export default MoveButtons;
