import React, { useState } from 'react';
import { imageService } from '../services';

interface InputFileProps {
    onChange: (content: string) => void;
    initialValue: string;
}

const InputFile: React.FC<InputFileProps> = ({ onChange, initialValue }) => {
    const [fileUrl, setFileUrl] = useState<string>(initialValue);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            try {
                const response = await imageService.uploadFile(file);
                if (response.status === 200) {
                    const imageUrl = response.data;
                    setFileUrl(imageUrl);
                    onChange(imageUrl);
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    return (
        <div>
            {fileUrl && <span>{fileUrl}</span>}
            <input type="file" className="btn btn-primary" onChange={handleFileChange} />
        </div>
    );
};

export default InputFile;
