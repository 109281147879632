import React, { Fragment, useEffect, useState } from 'react'
import { congDoanService, nhanSuService, suKienService, tenThanhService } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { DatePicker, Form, Input, Select, message } from 'antd';
import dayjs from 'dayjs';
import EditorCustom from '../../components/EditorCustom';
import { STORARE_NAME_LIST_NHAN_SU } from '../../helpers/constants';

export const ThemMoiSuKien = () => {
    const onClose = () => {
        navigate('/su-kien');
    };
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [lstCongDoan, setLstCongDoan] = useState<any[]>([]);
    const [lstNhanSu, setLstNhanSu] = useState<any[]>([]);

    useEffect(() => {
        const fetchLinhMucData = async () => {
            try {
                setLoading(true);
                const congDoanResponse = await congDoanService.getAll();
                if (congDoanResponse.code === 0) {
                    setLstCongDoan(congDoanResponse.data);
                }
                // lưu trữ và localStorage
                const storedUserList = localStorage.getItem(STORARE_NAME_LIST_NHAN_SU);
                if (storedUserList) {
                    // case đã tồn tại
                    setLstNhanSu(JSON.parse(storedUserList));
                } else {
                    // chưa tồn tại thì get ra
                    const linhMucResponse = await nhanSuService.getAll();
                    if (linhMucResponse.code === 0) {
                        setLstNhanSu(linhMucResponse.data);
                    }
                    localStorage.setItem(STORARE_NAME_LIST_NHAN_SU, JSON.stringify(linhMucResponse.data));
                }
            } catch (error: any) {
                message.error(error.message, onClose);
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        };
        fetchLinhMucData();
    }, []);

    // cho trình soạn thảo văn bản
    const [chiTietThongTin, setChiTietThongTin] = useState('');
    const handleEditorChange = (value: string) => {
        setChiTietThongTin(value);
    };

    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await suKienService.createOrUpdate(null, values.trongNgay.format("DD.MM.YYYY"), values.loaiSuKien, values.tuGio, values.denGio,
                values.tieuDeSuKien, chiTietThongTin, values.idCongDoan, values.idNhanSu, values.denNgay ? values.denNgay.format("DD.MM.YYYY") : null);
            if (response.code === 0) {
                message.success('Tạo mới Sự Kiện thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Sự Kiện / Tạo Mới</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={form} name="detail"
                            onFinish={onSubmit}
                            className="c-formNew"
                            initialValues={{
                                trongNgay: dayjs().startOf('day'), // Lấy ngày hiện tại
                            }}
                        >
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/su-kien">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Sự kiện trong ngày</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="trongNgay" // Tên của trường trong form
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng chọn ngày đăng',
                                                    }
                                                ]}>
                                                <DatePicker
                                                    size="large"
                                                    format="DD.MM.YYYY"
                                                    showTime={false}
                                                    inputReadOnly={true}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Từ giờ</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tuGio"
                                                rules={[
                                                    {
                                                        pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
                                                        message: 'Định dạng giờ không hợp lệ. Vui lòng nhập theo định dạng HH:MM!'
                                                    }
                                                ]}>
                                                <Input className='form-control' placeholder='HH:MM' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tiêu đề sự kiện</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tieuDeSuKien"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập tiêu đề sự kiện!'
                                                    }
                                                ]}>
                                                <Input className='form-control' placeholder='Tiêu đề sự kiện' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Đến ngày</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="denNgay"
                                                rules={[
                                                    {
                                                        validator: async (_, value) => {
                                                            const trongNgayValue = form.getFieldValue('trongNgay');
                                                            if (value && trongNgayValue) {
                                                                // Chuyển đổi giá trị ngày thành định dạng ngày tháng năm
                                                                const denNgayDate = dayjs(value.format('YYYY-MM-DD'));
                                                                const trongNgayDate = dayjs(trongNgayValue.format('YYYY-MM-DD'));
                                                                // So sánh ngày "denNgay" lớn hơn "trongNgay"
                                                                if (denNgayDate <= trongNgayDate) {
                                                                    return Promise.reject('Ngày kết thúc phải lớn hơn ngày bắt đầu');
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    size="large"
                                                    format="DD.MM.YYYY"
                                                    showTime={false}
                                                    inputReadOnly={true}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Đến giờ</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="denGio"
                                                rules={[
                                                    {
                                                        pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
                                                        message: 'Định dạng giờ không hợp lệ. Vui lòng nhập theo định dạng HH:MM!'
                                                    }
                                                ]}
                                            >
                                                <Input className='form-control' placeholder='HH:MM' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Phân loại</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="loaiSuKien"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng chọn Loại sự kiên',
                                                    }
                                                ]}
                                            >
                                                <Select placeholder="Chọn loại" size="large">
                                                    <Select.Option value={'TOAN_TINH'}>Toàn Tỉnh</Select.Option>
                                                    <Select.Option value={'CONG_DOAN'}>Cộng Đoàn</Select.Option>
                                                    <Select.Option value={'CA_NHAN'}>Cá Nhân</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Chọn cộng đoàn</label>
                                        <div className="col-sm-8">
                                            {loading ? (
                                                // Hiển thị bộ Loading khi loadingLoadList là true
                                                <tr className='text-center'>
                                                    <td colSpan={6}>
                                                        <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                                    </td>
                                                </tr>
                                            ) : (
                                                // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                                <Form.Item name="idCongDoan"
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (getFieldValue('loaiSuKien') === 'CONG_DOAN' && !value) {
                                                                    return Promise.reject('Vui lòng chọn Cộng Đoàn');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Select placeholder="Chọn Cộng Đoàn" size="large" showSearch optionFilterProp="label">
                                                        <Select.Option value={''} label=''>{''}</Select.Option>
                                                        {lstCongDoan.map((item: any, index) => (
                                                            <Select.Option key={index} value={item.id} label={item.tenCongDoan}>{item.tenCongDoan}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Chọn Nhân Sự</label>
                                        <div className="col-sm-8">
                                            {loading ? (
                                                // Hiển thị bộ Loading khi loadingLoadList là true
                                                <tr className='text-center'>
                                                    <td colSpan={6}>
                                                        <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                                    </td>
                                                </tr>
                                            ) : (
                                                // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                                <Form.Item name="idNhanSu"
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (getFieldValue('loaiSuKien') === 'CA_NHAN' && !value) {
                                                                    return Promise.reject('Vui lòng chọn Nhân Sự');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}>
                                                    <Select placeholder="Chọn Nhân Sự" size="large" showSearch optionFilterProp="label">
                                                        <Select.Option value={''} label=''>{''}</Select.Option>
                                                        {lstNhanSu.map((item: any, index) => (
                                                            <Select.Option key={index} value={item.id} label={item.hoTen}>{item.hoTen}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="col-sm-3 col-form-label">Nội dung sự kiện</label>
                                <div className="col-sm-12">
                                    <Form.Item name="chiTietThongTin">
                                        <EditorCustom onChange={handleEditorChange} initialValue={''} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/su-kien">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
