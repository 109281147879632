import React, { Fragment, useEffect, useState } from 'react'
import { congDoanService, imageService, thuChaGiamTinhService, tintucService } from '../../services';
import InputImage from '../../components/InputImage';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, DatePicker, Form, Input, Select, Switch, Upload, message } from 'antd';
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import EditorCustom from '../../components/EditorCustom';
import { ANH_MAC_DINH_TIN_TUC } from '../../helpers/constants';
import { useSelector } from 'react-redux';
import { AuthenticatedUser } from '../../store/account/types';
import { AppState } from '../../store';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import resizeImage from '../../components/ImageUtils';
import { CSS } from '@dnd-kit/utilities';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext, DragEndEvent, PointerSensor, useSensor } from '@dnd-kit/core';

interface DraggableUploadListItemProps {
    originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    file: UploadFile<any>;
}

const DraggableUploadListItem = ({ originNode, file }: DraggableUploadListItemProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: file.uid,
    });

    const style: React.CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: 'move',
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            // prevent preview event when drag end
            className={isDragging ? 'is-dragging' : ''}
            {...attributes}
            {...listeners}
        >
            {/* hide error tooltip when dragging */}
            {file.status === 'error' && isDragging ? originNode.props.children : originNode}
        </div>
    );
};


export const ChinhSuaThongTin = () => {
    const accountAdminLogin = useSelector<AppState>((state) => state.account.user) as AuthenticatedUser;

    const [fileList, setFileList] = useState<UploadFile[]>();
    const sensor = useSensor(PointerSensor, {
        activationConstraint: { distance: 10 },
    });
    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setFileList((prev = []) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id);
                const overIndex = prev.findIndex((i) => i.uid === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    // Function to get the current date with time set to 00:00:00
    const navigate = useNavigate();
    const onClose = () => {
        navigate('/tin-tuc');
    };

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    // get chi tiet thong tin
    const [objectDetail, getChiTietTinTuc] = useState<any>(null);
    // get lstCongDoan cần thiết
    const [lstCongDoan, setLstCongDoan] = useState<any[]>([]);
    // get lst loai tin
    const [lstLoaiTin, setLstLoaiTin] = useState<any[]>([]);
    // get lst thu cha giam tinh
    const [lstThuChaGiamTinh, setLstThuChaGiamTinh] = useState<any[]>([]);
    const [listIdCongDoan, setListIdCongDoan] = useState<any[]>([]);
    const [listIdLoaiTin, setListIdLoaiTin] = useState<any[]>([]);

    useEffect(() => {
        const fetchCongDoanData = async () => {
            try {
                const reponseCongDoan = await congDoanService.getAll();
                if (reponseCongDoan.code === 0) {
                    setLstCongDoan(reponseCongDoan.data);
                }
                const reponseTintuc = await tintucService.getAllDanhSachLoaiTinPhanCap();
                if (reponseTintuc.code === 0) {
                    setLstLoaiTin(reponseTintuc.data);
                }
                const responeThuChaGiamTinh = await thuChaGiamTinhService.getAll();
                if (responeThuChaGiamTinh.code === 0) {
                    setLstThuChaGiamTinh(responeThuChaGiamTinh.data);
                }

                const responeHinhAnh = await tintucService.getListHinhAnhByNewId(id);
                if (responeHinhAnh.status === 200) {
                    setLstImgUpload(responeHinhAnh.data);
                }

                const responeTinTuc = await tintucService.getTinTucTheoId(id);
                if (responeTinTuc.status === 200) {
                    getChiTietTinTuc(responeTinTuc.data);
                } else if (responeTinTuc.status === 404) {
                    message.error('Id không tồn tại', onClose);
                } else {
                    message.error(responeTinTuc.data.title, onClose);
                }

                const danhSachQuyen = await tintucService.getIdTongQuyen();
                if (danhSachQuyen.code === 0) {
                    setListIdCongDoan(danhSachQuyen.data[0]);
                    setListIdLoaiTin(danhSachQuyen.data[1])
                }
            } catch (error: any) {
                message.error(error.message, onClose);
            } finally {
                setLoading(false);
            }
        };
        fetchCongDoanData();
    }, [id]);

    useEffect(() => {
        if (objectDetail) {
            const ngayDang = dayjs(objectDetail.ngayDangUpdate);
            form.setFieldsValue({
                tieuDe: objectDetail.tieuDe,
                ngayDang: ngayDang,
                tacGia: objectDetail.tacGia,
                trangThai: objectDetail.enable,
                idLoai: objectDetail.loaiTinTucs[0]?.id,
                idCongDoan: objectDetail.congDoans[0]?.id,
                idThuChaGiamTinh: objectDetail.thuChaGiamTinhs[0]?.id,
                anhDaiDien: objectDetail.hinhAnhDaiDien ? objectDetail.hinhAnhDaiDien : ANH_MAC_DINH_TIN_TUC,
                chiTietThongTin: objectDetail.detail,
                choPhepGuiThongBao: objectDetail.choPhepGuiThongBao
            });
            setChiTietThongTin(objectDetail.detail);
            convertToUploadFileList(lstImgUpload);
        }
    }, [objectDetail]);

    // cho trình soạn thảo văn bản
    const [chiTietThongTin, setChiTietThongTin] = useState('');
    const handleEditorChange = (value: string) => {
        setChiTietThongTin(value);
    };

    // cho image
    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState('');
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };

    const sortAndFilterArrayB = (arrayA: UploadFile[], arrayB: string[]): string[] => {
        const uidOrderMap = new Map<string, number>();

        // Tạo map từ uid của mảng A với thứ tự index tương ứng
        arrayA.forEach((item, index) => {
            uidOrderMap.set(item.uid, index);
        });

        // Lọc và sắp xếp mảng B dựa trên thứ tự của các uid trong mảng A
        const sortedAndFilteredB = arrayB
            .filter(itemB => {
                const uidB = itemB.split('/').pop();
                return uidOrderMap.has(uidB!);
            })
            .sort((itemB1, itemB2) => {
                const uidB1 = itemB1.split('/').pop();
                const uidB2 = itemB2.split('/').pop();

                const indexA1 = uidOrderMap.get(uidB1!);
                const indexA2 = uidOrderMap.get(uidB2!);

                return (indexA1 ?? 0) - (indexA2 ?? 0);
            });

        return sortedAndFilteredB;
    }

    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const ngayDangString = dayjs(values.ngayDang).format("DD.MM.YYYY HH:mm");

            let thongBao = values.choPhepGuiThongBao;
            if (accountAdminLogin.userLevel !== 'Administrator') {
                thongBao = false
            }

            const lstImg = sortAndFilterArrayB(fileList ?? [], lstImgUpload);
            const response = await tintucService.themMoiOrChinhSuaThongTin(id, ngayDangString, values.tieuDe, values.tacGia,
                values.trangThai ? true : false, values.idLoai, values.idCongDoan, values.idThuChaGiamTinh, values.anhDaiDien ? values.anhDaiDien : ANH_MAC_DINH_TIN_TUC, chiTietThongTin, thongBao, lstImg)
            if (response.code === 0) {
                message.success('Chỉnh sửa tin tức thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const convertToUploadFileList = (imgss: any) => {
        const newFileList = imgss.map((imgUrl: any) => ({
            uid: imgUrl.substring(imgUrl.lastIndexOf('/') + 1),
            name: imgUrl.substring(imgUrl.lastIndexOf('/') + 1),
            status: 'done',
            url: imgUrl
        }));
        setFileList(newFileList);
        setLstImgUpload(imgss);
    };

    const [lstImgUpload, setLstImgUpload] = useState<any[]>([]);
    const addImageToUploadList = (newImage: any) => {
        setLstImgUpload((prevList) => [...prevList, newImage]);
    };

    const uploadImage = async (file: any, fileList: any) => {
        try {
            const resizedFile = await resizeImage(file);
            const response = await imageService.uploadImage(resizedFile);
            if (response.status === 200) {
                console.log(lstImgUpload);
                const imageUrl = response.data + '|' + file.name;
                addImageToUploadList(imageUrl);
            }
            return true;
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>THÔNG TIN / Tin Tức / Chỉnh Sửa</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={form}
                            name="detail"
                            onFinish={onSubmit}
                            className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/tin-tuc">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group row">
                                        <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Ngày đăng</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="ngayDang" // Tên của trường trong form
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng chọn ngày đăng',
                                                    }
                                                ]}>
                                                <DatePicker
                                                    size="large"
                                                    format="DD.MM.YYYY HH:mm"
                                                    showTime={{ format: 'HH:mm' }}
                                                    inputReadOnly={true}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tiêu đề</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tieuDe"
                                                rules={[{ required: true, message: 'Vui lòng nhập tiêu đề !' }]}>
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tác giả</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tacGia"
                                                rules={[{ required: true, message: 'Vui lòng nhập tác giả !' }]}>
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tình trạng</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="trangThai" valuePropName="checked">
                                                <Switch defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    {accountAdminLogin.userLevel === 'Administrator' &&
                                        (<div className="form-group row">
                                            <label className="col-sm-3 col-form-label">Cho phép gửi thông báo</label>
                                            <div className="col-sm-8">
                                                <Form.Item name="choPhepGuiThongBao" valuePropName="checked">
                                                    <Switch defaultChecked={false} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                                </Form.Item>
                                            </div>
                                        </div>)
                                    }
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Phân loại</label>
                                        <div className="col-sm-8">
                                            {loading ? (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            ) : (
                                                <Form.Item name="idLoai">
                                                    <Select placeholder="Chọn Phân Loại Tin" size="large">
                                                        <Select.Option key={''} value={''}>{''}</Select.Option>
                                                        {lstLoaiTin?.map((item: any) => (
                                                            <Select.OptGroup key={item.id} label={item.tenLoai}>
                                                                {item.loaiTinTucCon?.map((itemCon: any) => (
                                                                    (accountAdminLogin.userLevel === 'Administrator' || listIdLoaiTin.includes(itemCon.id)) && (
                                                                        <Select.Option key={itemCon.id} value={itemCon.id}>
                                                                            {itemCon.tenLoai}
                                                                        </Select.Option>
                                                                    )
                                                                ))}
                                                            </Select.OptGroup>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Cộng đoàn</label>
                                        <div className="col-sm-8">
                                            {loading ? (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            ) : (
                                                <Form.Item name="idCongDoan">
                                                    <Select placeholder="Chọn Cộng Đoàn" size="large" showSearch optionFilterProp="label">
                                                        <Select.Option key={''} value={''} label=''>{''}</Select.Option>
                                                        {lstCongDoan?.map((item: any, index) => (
                                                            (accountAdminLogin.userLevel === 'Administrator' || listIdCongDoan.includes(item.id)) && (
                                                                <Select.Option key={index} value={item.id} label={item.tenCongDoan}>{item.tenCongDoan}</Select.Option>
                                                            )
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Thuộc Thư Cha Giám Tỉnh</label>
                                        <div className="col-sm-8">
                                            {loading ? (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            ) : (
                                                <Form.Item name="idThuChaGiamTinh">
                                                    <Select placeholder="Chọn Thư Cha Giám Tỉnh" size="large" showSearch optionFilterProp="label">
                                                        <Select.Option key={''} value={''} label=''>{''}</Select.Option>
                                                        {lstThuChaGiamTinh?.map((item: any, index) => {
                                                            const month = item.thangNam.slice(0, 2);
                                                            const year = item.thangNam.slice(3); // Lấy chuỗi năm từ vị trí 6 đến hết chuỗi
                                                            const formattedDate = `Tháng ${month} năm ${year}`;
                                                            return <Select.Option key={index} value={item.id} label={formattedDate + ' số ' + item.so}>{formattedDate + ' số ' + item.so}</Select.Option>;
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="anhDaiDien">
                                                {objectDetail ? (
                                                    <InputImage onChange={handleImageChange} initialValue={objectDetail.hinhAnhDaiDien ? objectDetail.hinhAnhDaiDien : ANH_MAC_DINH_TIN_TUC} />
                                                ) : (
                                                    <p><span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...</p>
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className="col-sm-3 col-form-label">Nội dung thông tin</label>
                                <div className="col-sm-12">
                                    <Form.Item name="chiTietThongTin"
                                        rules={[{ required: true, message: 'Vui lòng nhập nội dung tin tức !' }]}>
                                        {objectDetail ? (
                                            <EditorCustom onChange={handleEditorChange} initialValue={objectDetail.detail} />
                                        ) : (
                                            <p><span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...</p>
                                        )}
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className="col-sm-12">
                                    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                                        <SortableContext items={(fileList || []).map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                                            <Upload
                                                multiple={true}
                                                fileList={fileList}
                                                onChange={onChange}
                                                listType="picture"
                                                beforeUpload={uploadImage}
                                                itemRender={(originNode, file) => (
                                                    <DraggableUploadListItem originNode={originNode} file={file} />
                                                )}>
                                                <Button style={{ marginBottom: '40px' }} icon={<UploadOutlined />}>Click to Upload</Button>
                                            </Upload>
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/tin-tuc">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
