import React, { Fragment, useEffect, useState } from 'react'
import { loaiTaiLieuService } from '../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Input, message } from 'antd';
import InputImage from '../../components/InputImage';
import { ANH_MAC_DINH_TAI_LIEU } from '../../helpers/constants';

export const ChinhSuaLoaiTaiLieu = () => {
    const onClose = () => {
        navigate('/phan-loai-tai-lieu');
    };
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    // cho image
    const [urlImgAnhDaiDien, setUrlImgAnhDaiDien] = useState('');
    const [objectDetail, setChiTietTaiLieu] = useState<any>(null);

    useEffect(() => {
        setLoading(true);
        loaiTaiLieuService.getById(id).then((response) => {
            if (response.status === 200) {
                setChiTietTaiLieu(response.data);
            } else if (response.status === 404) {
                message.error('Id không tồn tại', onClose);
            } else {
                message.error(response.data.title, onClose);
            }
        });
        setLoading(false);
    }, []);

    useEffect(() => {
        if (objectDetail) {
            form.setFieldsValue({
                tenLoaiTaiLieu: objectDetail.tenLoaiTaiLieu,
                hinhAnhDaiDien: objectDetail.hinhAnhDaiDien
            });
            setUrlImgAnhDaiDien(objectDetail.hinhAnhDaiDien);
            setLoading(false)
        }
    }, [objectDetail]);

    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDien(value);
    };

    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await loaiTaiLieuService.createOrUpdate(id, values.tenLoaiTaiLieu,  urlImgAnhDaiDien ? urlImgAnhDaiDien : ANH_MAC_DINH_TAI_LIEU);
            if (response.code === 0) {
                message.success('Tạo mới Phân loại thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>TÀI LIỆU / Phân Loại / Chỉnh Sửa</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={form} name="detail" onFinish={onSubmit} className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/phan-loai-tai-lieu">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tên phân loại</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tenLoaiTaiLieu"
                                                rules={[{ required: true, message: 'Vui lòng nhập Tên phân loại!' }]}>
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="hinhAnhDaiDien"
                                                rules={[{ required: true, message: 'Vui lòng nhập hình ảnh đại diện!' }]}
                                            >
                                                {objectDetail ?
                                                    (<InputImage onChange={handleImageChange} initialValue={objectDetail.hinhAnhDaiDien} />) :
                                                    (<p><span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...</p>)
                                                }
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/phan-loai-tai-lieu">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
