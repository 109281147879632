import React, { Fragment, useEffect, useState } from 'react'
import { tinhTrangService } from '../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Input, message } from 'antd';

export const ChinhSuaTinhTrang = () => {
    const onClose = () => {
        navigate('/tinh-trang');
    };
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);

    const { id } = useParams();
    const [objectDetail, setChiTiet] = useState<any>(null);

    useEffect(() => {
        const fetchCongDoanData = async () => {
            try {
                tinhTrangService.getById(id).then((response) => {
                    if (response.status === 200) {
                        setChiTiet(response.data);
                    } else if (response.status === 404) {
                        message.error('Id không tồn tại', onClose);
                    } else {
                        message.error(response.data.title, onClose);
                    }
                });
            } catch (error: any) {
                message.error(error.message, onClose);
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        };
        fetchCongDoanData();
    }, [id]);

    useEffect(() => {
        if (objectDetail) {
            form.setFieldsValue({
                tenTinhTrang: objectDetail.tenTinhTrang,
            });
        }
    }, [objectDetail, form]);

    // cho image
    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await tinhTrangService.update(id, values.tenTinhTrang);
            if (response.status === 200) {
                message.success('Chỉnh sửa Tình Trạng thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Tình Trạng / Chỉnh Sửa</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={form} name="detail" onFinish={onSubmit} className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/tinh-trang">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tên Tình Trạng</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tenTinhTrang"
                                                rules={[{ required: true, message: 'Vui lòng nhập Tên Tình Trạng !' }]}>
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/tinh-trang">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
