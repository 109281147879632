import React, { Fragment, useEffect, useState } from 'react';
import { congDoanService, nhanSuService } from '../../services';
import { MAX_ITEMS_PER_PAGE, STORARE_NAME_LIST_NHAN_SU } from '../../helpers/constants';
import { Form, Input, Modal, Pagination, Select, message } from 'antd';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Swal from 'sweetalert2';

export const ChatDanhSachNhom = () => {
    const [loading, setLoading] = useState(true);
    const [loadingSumit, setLoadingSumit] = useState(false);
    const [form] = Form.useForm();
    const [formThemMoiNhom] = Form.useForm();
    const [formThemThanhVienNhom] = Form.useForm();
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPageSize, setMaxPageSize] = useState(MAX_ITEMS_PER_PAGE);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);
    const [lstNhanSu, setLstNhanSu] = useState<any[]>([]);
    const [lstDanhSachUidUserNhomChat, setLstDanhSachUidUserNhomChat] = useState<String[]>([]);

    const firebaseConfig = {
        apiKey: "AIzaSyDATMHy3jPBUDSMCFU46ZNKkb3IVgz-MCQ",
        authDomain: "cssrvn-e52ca.firebaseapp.com",
        projectId: "cssrvn-e52ca",
        storageBucket: "cssrvn-e52ca.appspot.com",
    };

    firebase.initializeApp(firebaseConfig);
    const db = firebase.firestore();

    const fetchData = async (loaiNhom: string, pageNumber: number, keySearch: any) => {
        setLoading(true);
        if (loaiNhom === 'CONG_DOAN') {
            setMaxPageSize(MAX_ITEMS_PER_PAGE);
            congDoanService.filterList(pageNumber - 1, maxPageSize, null, keySearch)
                .then((response) => {
                    if (response.status === 200) {
                        setTotal(response.headers['x-total-count']);
                        setLstDanhSach(response.data);
                    } else {
                        message.error({ content: response.message, duration: 2 });
                    }
                    setLoading(false);
                });
        } else {
            try {
                const groupChatsRef = db.collection('rooms');
                const snapshot = await groupChatsRef.get();
                const groupChats = snapshot.docs
                    .map((doc) => {
                        if (doc.data().type === "group" && !doc.data().name.includes("Nhóm chat CĐ.")) {
                            return {
                                tenCongDoan: doc.data().name,
                                roomsId: doc.id,
                                typeCheckTuTao: "TU_TAO"
                            };
                        }
                        return null;
                    })
                    .filter((item) => item !== null);
                setLstDanhSach(groupChats);
            } catch (error) {
                console.error('Error getting group chats: ', error);
            } finally {
                setLoading(false);
            }
            setMaxPageSize(1000);
        }
    };

    const intiData = async () => {
        const storedUserList = localStorage.getItem(STORARE_NAME_LIST_NHAN_SU);
        if (storedUserList) {
            // case đã tồn tại
            setLstNhanSu(JSON.parse(storedUserList));
        } else {
            // chưa tồn tại thì get ra
            const linhMucResponse = await nhanSuService.getAll();
            if (linhMucResponse.code === 0) {
                setLstNhanSu(linhMucResponse.data);
            }
            localStorage.setItem(STORARE_NAME_LIST_NHAN_SU, JSON.stringify(linhMucResponse.data));
        }
    };

    useEffect(() => {
        fetchData('CONG_DOAN', 1, null);
        form.setFieldsValue({
            loaiNhomChat: 'CONG_DOAN'
        });
        intiData();
    }, []);

    const onPageChanged = async (pageNumber: number) => {
        setCurrentPage(pageNumber);
        const values = await form.validateFields();
        fetchData(values.loaiNhomChat, pageNumber, values.keySearch);
    };

    const refreshData = async () => {
        try {
            const values = await form.validateFields();
            fetchData(values.loaiNhomChat, 1, values.keySearch);
            setCurrentPage(1);
        } catch (error: any) {
            message.error({ content: "Chưa chọn năm", duration: 2 });
        }
    };

    const handleCloseModal = () => {
        setShowChiTietThanhVien(false);
        setShowThemNhom(false);
        formThemMoiNhom.resetFields();
        setLstDanhSachUidUserNhomChat([]);
        setShowThemThanhVienNhom(false);
        formThemThanhVienNhom.resetFields();
    }
    const [showChiTietThanhVien, setShowChiTietThanhVien] = useState(false);
    const [lstThanhVienChat, setLstThanhVienChat] = useState<any[]>([]);
    const handleShowModalChiTietThanhVien = async (congDoanId: any, roomsUid: any) => {
        setLoadingSumit(true);
        nhanSuService.getNhanSuTheoUid(congDoanId, roomsUid)
            .then((response) => {
                if (response.code === 0) {
                    setLstThanhVienChat(response.data);
                    const lstUserUid = response.data
                        .map((doc: any) => {
                            return doc.uid
                        })
                    setLstDanhSachUidUserNhomChat(lstUserUid);
                    setShowChiTietThanhVien(true);
                    console.info(lstUserUid);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
                setLoadingSumit(false);
            });
    };

    const handleDongBoData = async (congDoanId: any) => {
        setLoadingSumit(true);
        nhanSuService.dongBoNhomChat(congDoanId)
            .then((response) => {
                if (response.code === 0) {
                    message.success({ content: "Đồng bộ thành công", duration: 2 });
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
                setLoadingSumit(false);
            });
    };

    const [showThemNhom, setShowThemNhom] = useState(false);
    const handleShowTaoNhomChat = async () => {
        setShowThemNhom(true);
    };

    const onSubmitTaoNhom = async (values: any) => {
        try {
            setLoadingSumit(true);
            const dataNhomChat = {
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                imageUrl: 'https://cdn-icons-png.flaticon.com/512/5328/5328050.png',
                metadata: null,
                type: 'group',
                name: values.tenNhomChat,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                userIds: values.idNhanSus,
                userRoles: null
            };
            console.info(dataNhomChat);
            db.collection('rooms').add(dataNhomChat)
                .then((docRef) => {
                    refreshData();
                    handleCloseModal();
                    // bắn thông báo
                    nhanSuService.banThongBaoVaoNhom(values.tenNhomChat, values.idNhanSus, docRef.id);
                    message.success({ content: "Tạo nhóm chat thành công" as string, duration: 1 });
                })
                .catch((error) => {
                    message.error({ content: error.message as string, duration: 1 });
                });
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoadingSumit(false);
        }
    };

    const xoaThanhVienNhomChat = async (uid: any, roomsId: any) => {
        try {
            setLoadingSumit(true);
            const userIds = lstDanhSachUidUserNhomChat.filter((item) => item !== uid);

            if (userIds.length < 2) {
                const result = await Swal.fire({
                    title: 'Nhóm chat chỉ còn 2 thành viên, nếu loại bỏ thành viên này nhóm chat sẽ bị xóa',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Xóa',
                    cancelButtonText: 'Hủy',
                    reverseButtons: true,
                });

                if (result.isConfirmed) {
                    try {
                        const docRef = db.collection('rooms').doc(roomsId);
                        docRef.delete()
                            .then(() => {
                                refreshData();
                                message.success({ content: "Xóa nhóm chat thành công" as string, duration: 1 });
                            })
                            .catch((error) => {
                                message.error({ content: error.message as string, duration: 1 });
                            });
                        setLoadingSumit(false);
                    } catch (error: any) {
                        message.error({ content: error.message as string, duration: 1 });
                        setLoadingSumit(false);
                    }
                } else {
                    setLoadingSumit(false);
                }
            } else {
                const dataNhomChat = {
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    userIds: userIds,
                };
                db.collection('rooms').doc(roomsId).update(dataNhomChat)
                    .then(() => {
                        message.success({ content: "Cập nhật thành công" as string, duration: 1 });
                        setLoadingSumit(false);
                    })
                    .catch((error) => {
                        message.error({ content: error.message as string, duration: 1 });
                        setLoadingSumit(false);
                    });
            }
            handleCloseModal();
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
            setLoadingSumit(false);
        }
    };

    const [showThemThanhVienNhom, setShowThemThanhVienNhom] = useState(false);
    const handleShowModalThemThanhVienNhomChat = async (congDoanId: any, roomsUid: any, tenNhomChat: string) => {
        setLoadingSumit(true);
        nhanSuService.getNhanSuTheoUid(congDoanId, roomsUid)
            .then((response) => {
                if (response.code === 0) {
                    setLstThanhVienChat(response.data);
                    const lstUserUid = response.data
                        .map((doc: any) => {
                            return doc.uid
                        })
                    setLstDanhSachUidUserNhomChat(lstUserUid);
                    formThemThanhVienNhom.setFieldsValue({
                        tenNhomChat: tenNhomChat,
                        roomsId: roomsUid
                    });
                    setShowThemThanhVienNhom(true);
                } else {
                    message.error({ content: response.message, duration: 4 });
                }
                setLoadingSumit(false);
            });
    };

    const onSubmitThemThanVienNhom = async (values: any) => {
        try {
            setLoadingSumit(true);
            let listBanThongTin: string[] = [];
            for (let index = 0; index < values.idNhanSus.length; index++) {
                const uidNew = values.idNhanSus[index] as string;
                if (!lstDanhSachUidUserNhomChat.includes(uidNew)) {
                    lstDanhSachUidUserNhomChat.push(uidNew); // Nếu chưa tồn tại, thêm vào mảng
                    listBanThongTin.push(uidNew);
                }
            }
            const dataNhomChat = {
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                userIds: lstDanhSachUidUserNhomChat,
            };
            db.collection('rooms').doc(values.roomsId).update(dataNhomChat)
                .then(() => {
                    // bắn thông báo
                    if (listBanThongTin.length > 0) {
                        nhanSuService.banThongBaoVaoNhom(values.tenNhomChat, listBanThongTin, values.roomsId);
                    }
                    message.success({ content: "Cập nhật thành công" as string, duration: 1 });
                    setLoadingSumit(false);
                    handleCloseModal();
                })
                .catch((error) => {
                    message.error({ content: error.message as string, duration: 1 });
                    setLoadingSumit(false);
                });

        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoadingSumit(false);
            handleCloseModal();
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                <Modal
                    title={'Thành viên chat'}
                    open={showChiTietThanhVien}
                    onCancel={handleCloseModal}
                    onOk={handleCloseModal}
                    maskClosable={false}
                    width={1000}>
                    <table className="table table-bordered table-hover table-custom-with" id="dataTable" width="100%" cellSpacing={0}>
                        <thead style={{ backgroundColor: '#fac2b5' }} className='text-center'>
                            <tr>
                                <th>Hình ảnh</th>
                                <th>Họ tên</th>
                                <th>Thuộc cộng đoàn</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody className='text-center'>
                            {loading ? (
                                // Hiển thị bộ Loading khi loadingLoadList là true
                                <tr className='text-center'>
                                    <td colSpan={3}>
                                        <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                    </td>
                                </tr>
                            ) : (
                                <Fragment>
                                    {lstThanhVienChat?.map((item, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                            <td>
                                                <img style={{ height: '120px', borderRadius: '50%' }} src={item.avatar} />
                                            </td>
                                            <td>{item.hoTen}</td>
                                            <td>{item.tenCongDoan}</td>
                                            <td>
                                                <button type="button" className="btn btn-danger ml-2" disabled={loadingSumit}
                                                    onClick={() => xoaThanhVienNhomChat(item.uid, item.roomsId)}> <>
                                                        {loadingSumit && (
                                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                                        )}
                                                        Xóa khỏi Group
                                                    </></button>
                                            </td>
                                        </tr>
                                    ))}
                                </Fragment>
                            )}
                        </tbody>
                    </table>
                </Modal>

                <Modal
                    title={'Thêm mới Nhóm'}
                    open={showThemNhom}
                    onCancel={handleCloseModal}
                    maskClosable={false}
                    width={1000}
                    footer={[]}>
                    <Form form={formThemMoiNhom} name="formThemMoiNhom"
                        onFinish={onSubmitTaoNhom}>
                        <div className="row">
                            <div className='col-sm-12'>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Tên nhóm</label>
                                    <div className="col-sm-9">
                                        <Form.Item
                                            name="tenNhomChat"
                                            rules={[{ required: true, message: 'Vui lòng nhập tên nhóm !' }]}>
                                            <Input className='form-control' autoComplete="off" />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-12'>
                                <Form.Item name="idNhanSus"
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (value && value.length >= 3) {
                                                    return Promise.resolve(); // Hợp lệ
                                                }
                                                return Promise.reject('Nhóm chat phải có ít nhất 3 thành viên'); // Không hợp lệ
                                            },
                                        },
                                    ]}>
                                    <Select placeholder="Chọn Nhân Sự"
                                        size="large"
                                        mode="multiple"
                                        showSearch optionFilterProp="label">
                                        {lstNhanSu.map((item: any, index) =>
                                            item.uid ? (
                                                <Select.Option
                                                    key={index}
                                                    value={item.uid}
                                                    label={item.hoTen}>
                                                    {item.hoTen}
                                                </Select.Option>
                                            ) : null
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-info" onClick={handleCloseModal}>Cancel</button>
                                    <button type="submit" className="btn btn-primary ml-2" disabled={loadingSumit}> <>
                                        {loadingSumit && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Tạo Mới
                                    </></button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal>

                <Modal
                    title={'Thêm thành viên nhóm chat'}
                    open={showThemThanhVienNhom}
                    onCancel={handleCloseModal}
                    maskClosable={false}
                    width={1000}
                    footer={[]}>
                    <Form form={formThemThanhVienNhom} name="formThemThanhVienNhom"
                        onFinish={onSubmitThemThanVienNhom}>
                        <div className="row">
                            <Form.Item
                                name="roomsId" hidden={true}>
                                <Input />
                            </Form.Item>

                            <div className='col-sm-12'>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Tên nhóm</label>
                                    <div className="col-sm-9">
                                        <Form.Item
                                            name="tenNhomChat"
                                            rules={[{ required: true, message: 'Vui lòng nhập tên nhóm !' }]}>
                                            <Input className='form-control' autoComplete="off" readOnly={true} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-12'>
                                <Form.Item name="idNhanSus"
                                    rules={[{ required: true, message: 'Vui chọn nhân sự muôn thêm mới !' }]}>
                                    <Select placeholder="Chọn Nhân Sự"
                                        size="large"
                                        mode="multiple"
                                        showSearch optionFilterProp="label">
                                        {lstNhanSu.map((item: any, index) =>
                                            item.uid ? (
                                                <Select.Option
                                                    key={index}
                                                    value={item.uid}
                                                    label={item.hoTen}>
                                                    {item.hoTen}
                                                </Select.Option>
                                            ) : null
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>

                        <div className='row mt-2'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-info" onClick={handleCloseModal}>Cancel</button>
                                    <button type="submit" className="btn btn-primary ml-2" disabled={loadingSumit}> <>
                                        {loadingSumit && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Thêm thành viên
                                    </></button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal>

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>Quản lý nhóm chat</h1>
                </div>

                <Form form={form} name="search" onFinish={refreshData}>
                    <div className="row">
                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                <Form.Item name="keySearch">
                                    <Input className='form-control' placeholder="Tìm kiếm theo tên Cộng Đoàn" autoComplete="off" />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                <Form.Item name="loaiNhomChat">
                                    <Select placeholder="Chọn loại nhóm chat" size="large">
                                        <Select.Option key={1} value={'CONG_DOAN'}>Nhóm chat theo Cộng Đoàn</Select.Option>
                                        <Select.Option key={1} value={'TU_TAO'}>Admin tạo</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className='row'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Lọc dữ liệu
                                        </button>

                                        <button type="button" className="btn btn-info ml-2" onClick={() => handleShowTaoNhomChat()}>
                                            Tạo mới
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>

                <div className="row">
                    <div className="col-12">
                        <div className='text-right mb-2'>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={maxPageSize}
                                onChange={onPageChanged}
                                showSizeChanger={false}
                            />
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                                <thead style={{ backgroundColor: '#fac2b5' }}>
                                    <tr>
                                        <th>Tên nhóm chat</th>
                                        <th>Chi tiết thành viên</th>
                                        <th>Cập nhật thành viên</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={3}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach?.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td>
                                                        {item.typeCheckTuTao ? item.tenCongDoan : ('Nhóm chat CĐ.' + item.tenCongDoan)}
                                                    </td>
                                                    <td>
                                                        {item.typeCheckTuTao ?
                                                            (<button className="btn btn-primary" disabled={loadingSumit} onClick={() => handleShowModalChiTietThanhVien(0, item.roomsId)}>
                                                                {loadingSumit && (
                                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                                )}
                                                                Chi tiết thành viên
                                                            </button>)
                                                            : (<button className="btn btn-primary" disabled={loadingSumit} onClick={() => handleShowModalChiTietThanhVien(item.id, "")}>
                                                                {loadingSumit && (
                                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                                )}
                                                                Chi tiết thành viên
                                                            </button>)
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.typeCheckTuTao ?
                                                            (<button className="btn btn-info" disabled={loadingSumit} onClick={() => handleShowModalThemThanhVienNhomChat(0, item.roomsId, item.tenCongDoan)}>
                                                                {loadingSumit && (
                                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                                )}
                                                                Thêm thành viên
                                                            </button>) :
                                                            (<button className="btn btn-info" disabled={loadingSumit} onClick={() => handleDongBoData(item.id)}>
                                                                {loadingSumit && (
                                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                                )}
                                                                Đồng bộ data
                                                            </button>)
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='text-right'>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={maxPageSize}
                                onChange={onPageChanged}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
