import React from 'react';
import { useSelector } from 'react-redux';
import { AccountState } from '../store/account/types';
import { AppState } from '../store';
import { Login } from '../pages/login/Login';
import { Admin } from '../pages/admin/Admin';
import { TrangChu } from '../pages/admin/TrangChu';
import { ThongTin } from '../pages/admin/ThongTin.DanhSach';


export const AccountRoute = () => {
  const account: AccountState = useSelector((state: AppState) => state.account);
  if (account.token && account.user?.userLevel === 'Administrator') {
    return (
      <Admin>
        <TrangChu />
      </Admin>
    );
  } else if (account.token && account.user?.userLevel === 'User-co') {
    return (
      <Admin>
        <ThongTin />
      </Admin>
    );
  } else {
    return <Login />;
  }

};
