import { api } from '../helpers';

const filterList = async (page: number | null, size: number | null, sort: string | null, mm: number, yyyy: number, keySearch: any) => {
    const params = { page, size, sort, mm, yyyy, keySearch };
    return await api.get('/admin-dcct/lich-dong/filter', { params }).then((response: any) => {
        return response;
    });
};

const updateKinh = async (data: string, chiTietKinh: string) => {
    const payLoad = { data, chiTietKinh };
    return await api.post('/admin-dcct/lich-dong/update-kinh', payLoad).then((response: any) => {
        return response.data;
    });
};

const updateKinhCoDinh = async (value: string, id: any, key: any) => {
    const payLoad = {
        id,
        key,
        value
    };
    return await api.put(`/project-settings/${id}`, payLoad).then((response: any) => {
        return response;
    });
};

const createOrUpdate = async (id: any, ngay: string, tieuDe: string, tieuDePhu: string, anhDaiDien: string, mauAoLe: string, ghiChu: any) => {
    const payLoad = { id, ngay, tieuDe, tieuDePhu, anhDaiDien, mauAoLe, ghiChu };
    return await api.post('/admin-dcct/lich-dong/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/linh-dongs/${id}`).then((response: any) => {
        return response;
    });
};

const getById = async (id: any) => {
    return await api.get(`/linh-dongs/${id}`).then((response: any) => {
        return response;
    });
};

const genDuLieuLichDong = async (mm: number, yyyy: number) => {
    const params = { mm, yyyy };
    return await api.get('/admin-dcct/lich-dong/gen-du-lieu', { params }).then((response: any) => {
        return response;
    });
};

const getKinhCoDinh = async (id: any) => {
    return await api.get(`/project-settings/${id}`).then((response: any) => {
        return response;
    });
};

export const lichDongService = {
    filterList,
    genDuLieuLichDong,
    updateKinh,
    createOrUpdate,
    deleteById,
    getById,
    updateKinhCoDinh,
    getKinhCoDinh
};