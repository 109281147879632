export const LOAD_REQUEST = 'LOAD_REQUEST';
export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_FAILURE = 'LOAD_FAILURE';

export interface DataThongKeQuocGia {
    tenQuocGia: string;
    soNhanSu: any;
    priority: any;
}

export interface DataThongKeChucThanh {
    tenChucThanh: string;
    soNhanSu: any;
    priority: any;
}

export interface DataThongKeCongDoan {
    tenCongDoan: string;
    soNhanSu: any;
    priority: any;
}


interface LoadRequest {
    type: typeof LOAD_REQUEST;
}

interface LoadSuccess {
    type: typeof LOAD_SUCCESS;
    payload: {
        lstCongDoan: DataThongKeCongDoan[],
        lstChuThanh: DataThongKeChucThanh[],
        lstQuocGia: DataThongKeQuocGia[]
    };
}

interface LoadFailure {
    type: typeof LOAD_FAILURE;
    payload: {
        error: string;
    };
}

export interface HomeState {
    loading: boolean;
    error: string | null;
    lstCongDoan: DataThongKeCongDoan[],
    lstChuThanh: DataThongKeChucThanh[],
    lstQuocGia: DataThongKeQuocGia[]
}


export type HomeActionTypes =
    | LoadRequest
    | LoadSuccess
    | LoadFailure