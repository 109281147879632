import { api } from '../helpers';

const getAll = async () => {
    return await api.get('/dcct/chuc-thanh/list-all').then((response) => {
        return response.data;
    });
};

const filterList = async (page: number | null, size: number | null, sort: string | null) => {
    const params = { page, size, sort };
    return await api.get('/chu-thanhs', { params }).then((response: any) => {
        return response;
    });
};

const create = async (id: any, tenChucThanh: string, tenGoiTac: string) => {
    const payLoad = {
        id, tenChucThanh, tenGoiTac
    };
    return await api.post('/chu-thanhs', payLoad).then((response: any) => {
        return response;
    });
};

const update = async (id: any, tenChucThanh: string, tenGoiTac: string) => {
    const payLoad = {
        id, tenChucThanh, tenGoiTac
    };
    return await api.patch(`/chu-thanhs/${id}`, payLoad).then((response: any) => {
        return response;
    });
};

const getById = async (id: any) => {
    return await api.get(`/chu-thanhs/${id}`).then((response: any) => {
        return response;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/chu-thanhs/${id}`).then((response: any) => {
        return response;
    });
};

export const chucThanhService = {
    getAll,
    filterList,
    create,
    getById,
    update,
    deleteById
};