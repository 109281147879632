import { api } from '../helpers';

const filterList = async (page: number | null, size: number | null, sort: string | null, idLoai: number | null, idCongDoan: number | null, trangThai: boolean | null, keySearch: string | null) => {
    const params = { page, size, sort, idLoai, idCongDoan, trangThai, keySearch };
    return await api.get('/admin-dcct/thong-tin/list', { params }).then((response: any) => {
        return response;
    });
};

const getTinTucTheoId = async (id: any) => {
    return await api.get(`/tin-tucs/${id}`).then((response: any) => {
        return response;
    });
};

const deleteThongTin = async (id: any) => {
    return await api.delete(`/tin-tucs/${id}`).then((response: any) => {
        return response;
    });
};

const themMoiOrChinhSuaThongTin = async (id: any, ngayDang: string, tieuDe: string, tacGia: string,
    trangThai: Boolean, idLoai: number, idCongDoan: number, idThuChaGiamTinh: number, anhDaiDien: string, chiTietTin: string, choPhepGuiThongBao: boolean, lstImg: any) => {
    const payLoad = { id, ngayDang, tieuDe, tacGia, trangThai, idLoai, idCongDoan, idThuChaGiamTinh, anhDaiDien, chiTietTin, choPhepGuiThongBao, lstImg };
    return await api.post('/admin-dcct/thong-tin/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const getAllDanhSachLoaiTin = async () => {
    return await api.get('/admin-dcct/loai-tin/all').then((response) => {
        return response.data;
    });
};

const getAllDanhSachLoaiTinPhanCap = async () => {
    return await api.get('/admin-dcct/loai-tin/all-phan-cap').then((response) => {
        return response.data;
    });
};

const themMoiOrChinhSuaLoaiThongTin = async (id: any, idLoaiCha: number, tieuDe: string, anhDaiDien: string) => {
    const payLoad = { id, idLoaiCha, tieuDe, anhDaiDien };
    return await api.post('/admin-dcct/loai-tin/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const getLoaiTinTucTheoId = async (id: any) => {
    return await api.get(`/loai-tin-tucs/${id}`).then((response: any) => {
        return response;
    });
};

const deleteLoaiThongTin = async (id: any) => {
    return await api.delete(`/admin-dcct/loai-tin/delete/${id}`).then((response: any) => {
        return response.data;
    });
};

const getListHinhAnhByNewId = async (id: any) => {
    return await api.get(`/hinh-anh-tin-tucs/new/${id}`).then((response: any) => {
        return response;
    });
};

const getIdTongQuyen = async () => {
    return await api.get('/admin-dcct/get-id-tong-quyen').then((response) => {
        return response.data;
    });
};

export const tintucService = {
    filterList,
    themMoiOrChinhSuaThongTin,
    getTinTucTheoId,
    deleteThongTin,
    getAllDanhSachLoaiTin,
    getAllDanhSachLoaiTinPhanCap,
    themMoiOrChinhSuaLoaiThongTin,
    getLoaiTinTucTheoId,
    deleteLoaiThongTin,
    getIdTongQuyen,
    getListHinhAnhByNewId
};