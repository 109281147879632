import React, { Fragment, useEffect, useState } from 'react';
import { loaiTaiLieuService, taiLieuService } from '../../services';
import { Link } from 'react-router-dom';
import { MAX_ITEMS_PER_PAGE } from '../../helpers/constants';
import { Form, Input, Pagination, Select, message } from 'antd';
import Swal from 'sweetalert2';

export const TaiLieu = () => {
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();

    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstLoaiTaiLieu, setLstLoaiTaiLieu] = useState<any[]>([]);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);

    // init
    useEffect(() => {
        Promise.all([
            loaiTaiLieuService.getAll(),
        ]).then(([loaiTaiLieuResponse]) => {
            if (loaiTaiLieuResponse.code === 0) setLstLoaiTaiLieu(loaiTaiLieuResponse.data);
        });
    }, []);

    const fetchData = (pageNumber: number, idLoaiTaiLieu: any, keySearch: any) => {
        setLoading(true);
        taiLieuService.filterList(pageNumber - 1, MAX_ITEMS_PER_PAGE, null, idLoaiTaiLieu, keySearch)
            .then((response) => {
                if (response.data.code === 0) {
                    setTotal(response.headers['x-total-count']);
                    setLstDanhSach(response.data.data);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        const validateAndFetch = async () => {
            try {
                const values = await form.validateFields(); // Kiểm tra và lấy giá trị từ các trường
                const { idLoaiTaiLieu, keySearch } = values;
                fetchData(currentPage, idLoaiTaiLieu, keySearch);
            } catch (error) {
                // Xử lý lỗi khi kiểm tra trường không hợp lệ
            }
        };
        validateAndFetch();
    }, [currentPage]);

    const onPageChanged = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const refreshData = async () => {
        try {
            const values = await form.validateFields();
            const { idLoaiTaiLieu, keySearch } = values;
            fetchData(1, idLoaiTaiLieu, keySearch);
            setCurrentPage(1);
        } catch (error: any) {
            message.error({ content: "Chưa chọn năm", duration: 2 });
        }
    };

    const handleDelete = async (id: any) => {
        const result = await Swal.fire({
            title: 'Xác nhận xóa Tài liệu này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const response = await taiLieuService.deleteById(id);
                if (response.status === 204) {
                    message.success('Xóa Tài liệu thành công');
                    setCurrentPage(1);
                    const values = await form.validateFields();
                    const { idLoaiTaiLieu, keySearch } = values;
                    fetchData(1, idLoaiTaiLieu, keySearch);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>Tài Liệu</h1>
                </div>
                {/* Content Row */}

                <Form form={form} name="search">
                    <div className="row">
                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                <Form.Item name="keySearch">
                                    <Input className='form-control' placeholder="Tìm kiếm theo tiêu đề" autoComplete="off" />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='col-xl-3 col-md-6'>
                            <Form.Item name="idLoaiTaiLieu">
                                <Select placeholder="Loại tài liệu" size="large">
                                    <Select.Option value="">{''}</Select.Option>
                                    {lstLoaiTaiLieu?.map((item: any, index) => (
                                        <Select.Option key={index} value={item.id}>{item.tenLoaiTaiLieu}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </Form>

                <div className="row">
                    <div className="col-12">
                        <Form form={form} name="search">
                            <div className='row'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <button type="button" className="btn btn-primary" onClick={refreshData} disabled={loading}>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Lọc dữ liệu
                                        </button>
                                        <Link type="button" className="btn btn-info ml-2" to="/tai-lieu/them-moi">Tạo mới</Link>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>

                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                                <thead style={{ backgroundColor: '#fac2b5' }}>
                                    <tr>
                                        <th>Tiêu đề</th>
                                        <th>Phân loại</th>
                                        <th>Tác giả</th>
                                        <th>Người đăng</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={5}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach?.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td>{item.tieuDe}</td>
                                                    <td>{item.loaiTaiLieu}</td>
                                                    <td>{item.tacGia}</td>
                                                    <td>{item.nguoiDang}</td>
                                                    <td style={{ display: 'flex' }}>
                                                        <Link to={`/tai-lieu/chinh-sua/${item.id}`} style={{ marginLeft: '10px' }}>
                                                            {/* Chèn nội dung bên trong thẻ Link */}
                                                            <img style={{ width: '22px' }} src='/images/web_new.png' alt={item.ma} />
                                                        </Link>
                                                        <img onClick={() => handleDelete(item.id)} style={{ width: '23px', cursor: 'pointer', marginLeft: '10px' }} src='/images/web_delete.png' alt={item.ma} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='text-right'>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={MAX_ITEMS_PER_PAGE}
                                onChange={onPageChanged}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
