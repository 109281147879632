import React, { Fragment, useEffect, useState } from 'react';
import { congDoanService, tenThanhService, tinhTrangService, chucThanhService, quocGiaService, nhanSuService, tintucService } from '../../services';
import { Link } from 'react-router-dom';
import { MAX_ITEMS_PER_PAGE } from '../../helpers/constants';
import { Form, Input, Modal, Pagination, Radio, RadioChangeEvent, Select, Switch, message } from 'antd';
import Swal from 'sweetalert2';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const NhanSu = () => {
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [lstTenThanh, setLstTenThanh] = useState<any[]>([]);
    const [lstCongDoan, setLstCongDoan] = useState<any[]>([]);
    const [lstChucThanh, setLstChucThanh] = useState<any[]>([]);
    const [lstQuocGia, setLstQuocGia] = useState<any[]>([]);
    const [lstTinhTrang, setLstTinhTrang] = useState<any[]>([]);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);
    const [lstLoaiTin, setLstLoaiTin] = useState<any[]>([]);

    const [userLevel, setUserLevel] = useState('ROLE_USER'); // Giá trị mặc định
    const [nhanSuIdSeleced, setNhanSuIdSeleced] = useState<any>(null); // Giá trị mặc định

    const [showChinhQuyen, setShowChinhQuyen] = useState(false);
    const [formChinhQuyen] = Form.useForm();
    const [titlePopup, setTitlePopup] = useState('');

    const handleUserLevelChange = (e: RadioChangeEvent) => {
        setUserLevel(e.target.value);
    };

    const fetchData = (pageNumber: number) => {
        setLoading(true);
        const filters = {
            idTenThanh: form.getFieldValue('idTenThanh'),
            idCongDoan: form.getFieldValue('idCongDoan'),
            idChucThanh: form.getFieldValue('idChucThanh'),
            idQuocGia: form.getFieldValue('idQuocGia'),
            idTinhTrang: form.getFieldValue('idTinhTrang'),
            keySearch: form.getFieldValue('keySearch'),
        };

        nhanSuService.filterList(pageNumber - 1, MAX_ITEMS_PER_PAGE, null, ...Object.values(filters) as [number, number, number, number, number, string])
            .then((response) => {
                if (response.status === 200) {
                    setTotal(response.headers['x-total-count']);
                    setLstDanhSach(response.data.data);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        const initData = async () => {
            try {
                const tenThanhResponse = await tenThanhService.getAll();
                if (tenThanhResponse.code === 0) {
                    setLstTenThanh(tenThanhResponse.data);
                }

                const tinhTrangResponse = await tinhTrangService.getAll();
                if (tinhTrangResponse.code === 0) {
                    setLstTinhTrang(tinhTrangResponse.data);
                }

                const congDoanResponse = await congDoanService.getAll();
                if (congDoanResponse.code === 0) {
                    setLstCongDoan(congDoanResponse.data);
                }

                const chucThanhResponse = await chucThanhService.getAll();
                if (chucThanhResponse.code === 0) {
                    setLstChucThanh(chucThanhResponse.data);
                }

                const quocGiaResponse = await quocGiaService.getAll();
                if (quocGiaResponse.code === 0) {
                    setLstQuocGia(quocGiaResponse.data);
                }

                await tintucService.getAllDanhSachLoaiTinPhanCap().then((data) => {
                    if (data.code === 0) {
                        setLstLoaiTin(data.data);
                    }
                });

                fetchData(currentPage);
            } catch (error: any) {
                message.error(error.message);
            } finally {
                setLoading(false);
            }
        };
        initData();
    }, []);

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const onPageChanged = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const refreshData = () => {
        fetchData(1);
        setCurrentPage(1);
    };

    const handleDelete = async (id: any) => {
        const result = await Swal.fire({
            title: 'Xác nhận xóa Nhân sự này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const response = await nhanSuService.deleteById(id);
                if (response.code === 0) {
                    message.success('Xóa Nhân sự thành công');
                    refreshData();
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSendMailResetPassword = async (id: any, name: string, email: string) => {
        const result = await Swal.fire({
            title: 'Xác nhận gửi email đặt lại mật khẩu',
            html: '<span>- Reset mật khẩu cho Nhân Sự: </span><span style="color: red;">' + name + '</span><br><span>- Email: </span><span style="color: red;">' + email + '</span>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Gửi Mail reset mật khẩu',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            try {
                const response = await nhanSuService.resetMatKhau(email);
                if (response.code === 0) {
                    message.success('Gửi email thành công');
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            }
        }
    };

    const handleCloseEditorModal = () => {
        setObjectPhanQuyen(null);
        setShowChinhQuyen(false);
        setNhanSuIdSeleced(null);
        setSelectedCongDoanIds([]);
        setSelectedLoaiTinIds([]);

    }

    const [objectPhanQuyen, setObjectPhanQuyen] = useState<any>(null);
    const handleShowPopupChinhSuaQuyen = async (id: any, titel: string, userLevelPram: string) => {
        setShowChinhQuyen(true);
        setNhanSuIdSeleced(id);
        setTitlePopup(titel);
        const phanQuyenResponse = await nhanSuService.getPhanQuyen(id);
        if (phanQuyenResponse.code === 0) {
            await setObjectPhanQuyen(phanQuyenResponse.data);
            let userLevelValue;
            if (phanQuyenResponse.data.userLevel === 'Administrator') {
                userLevelValue = 'ROLE_ADMIN';
            } else if (phanQuyenResponse.data.userLevel === 'User-co') {
                userLevelValue = 'ROLE_USER_CO';
            } else {
                userLevelValue = 'ROLE_USER';
            }

            formChinhQuyen.setFieldsValue({
                userLevel: userLevelValue,
            });
            setUserLevel(userLevelValue);
            setSelectedCongDoanIds(phanQuyenResponse.data.congDoan);
            setSelectedLoaiTinIds(phanQuyenResponse.data.phanLoai);
        } else {
            message.error({ content: phanQuyenResponse.message as string });
        }
    }

    const onSubmit = async (values: any) => {
        try {
            const response = await nhanSuService.updatePhanQuyen(nhanSuIdSeleced, values.userLevel, selectedListIdCongDoan, selectedListIdLoaiTin)
            if (response.code === 0) {
                message.success({ content: "Cập nhật phân quyền thành công" });
                setObjectPhanQuyen(null);
                handleCloseEditorModal();
                refreshData();
            } else {
                message.error({ content: response.message as string });
            }
        } catch (error: any) {
            message.error({ content: error.message as string });
        }
    };

    // Hàm xử lý thay đổi trạng thái Cộng đoàn
    const [selectedListIdCongDoan, setSelectedCongDoanIds] = useState<number[]>([]); // Danh sách các id đã được chọn
    const handleCongDoanChange = (id: number, checked: boolean) => {
        if (checked) {
            setSelectedCongDoanIds([...selectedListIdCongDoan, id]); // Thêm id vào danh sách đã chọn
        } else {
            setSelectedCongDoanIds(selectedListIdCongDoan.filter((selectedId) => selectedId !== id)); // Loại bỏ id khỏi danh sách đã chọn
        }
    };

    // Hàm xử lý thay đổi trạng thái LoaiTin
    const [selectedListIdLoaiTin, setSelectedLoaiTinIds] = useState<number[]>([]); // Danh sách các id đã được chọn
    const handleLoaiTinChange = (id: number, checked: boolean) => {
        if (checked) {
            setSelectedLoaiTinIds([...selectedListIdLoaiTin, id]); // Thêm id vào danh sách đã chọn
        } else {
            setSelectedLoaiTinIds(selectedListIdLoaiTin.filter((selectedId) => selectedId !== id)); // Loại bỏ id khỏi danh sách đã chọn
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                <Modal
                    title={titlePopup}
                    open={showChinhQuyen}
                    onCancel={handleCloseEditorModal}
                    maskClosable={false}
                    footer={[
                    ]}
                    width={1200}>
                    <Form form={formChinhQuyen} name="formChinhQuyen"
                        onFinish={onSubmit}
                        className="c-formNew">
                        <div className="card shadow mb-4">
                            <div className='card-body'>
                                <div className='row mb-2'>
                                    <div className='col-12 text-right'>
                                        <div className="form-group">
                                            <button type="button" onClick={handleCloseEditorModal} className="btn btn-info" >Cancel</button>
                                            <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                Cập Nhật
                                            </></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-sm-12'>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">User level</label>
                                            <div className="col-sm-10">
                                                <Form.Item name="userLevel" rules={[{ required: true, message: 'Vui lòng chọn User level' }]}>
                                                    <Radio.Group onChange={handleUserLevelChange} value={objectPhanQuyen?.userLevel}>
                                                        <Radio value="ROLE_ADMIN">Administrator</Radio>
                                                        <Radio value="ROLE_USER_CO">User-co</Radio>
                                                        <Radio value="ROLE_USER">User</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {userLevel === 'ROLE_USER_CO' ?
                                    (<div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="card-header text-center" style={{ borderTop: '1px solid', borderLeft: '1px solid', borderRight: '1px solid' }}>
                                                <h6 className="m-0 font-weight-bold text-black">Cộng Đoàn</h6>
                                            </div>
                                            {/* Card Body */}
                                            <div className="card-body" style={{ padding: 'inherit', overflowY: 'auto', maxHeight: '450px', border: '1px solid' }}>
                                                <table className=" table table-bordered dataTable text-center" style={{ color: '#000' }}>
                                                    <tbody>
                                                        {objectPhanQuyen && (
                                                            <Fragment>
                                                                {lstCongDoan.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className='text-left'>{item.tenCongDoan}</td>
                                                                        <td>
                                                                            <Form.Item valuePropName={item.id} name={'congDoan' + item.id}>
                                                                                <Switch defaultChecked={objectPhanQuyen.congDoan?.includes(item.id) ? true : false} onChange={(checked) => handleCongDoanChange(item.id, checked)} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                                                            </Form.Item>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </Fragment>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="card-header text-center" style={{ borderTop: '1px solid', borderLeft: '1px solid', borderRight: '1px solid' }}>
                                                <h6 className="m-0 font-weight-bold text-black">Phân Loại (Các Ban)</h6>
                                            </div>
                                            {/* Card Body */}
                                            <div className="card-body" style={{ padding: 'inherit', overflowY: 'auto', maxHeight: '450px', border: '1px solid' }}>
                                                <table className=" table table-bordered dataTable text-center" style={{ color: '#000' }}>
                                                    <tbody>
                                                        {objectPhanQuyen && (
                                                            <Fragment>
                                                                {lstLoaiTin?.map((item) => (
                                                                    <Fragment key={item.id}>
                                                                        <tr className='text-left text-bold'>
                                                                            <td colSpan={2}>{item.tenLoai}</td>
                                                                        </tr>
                                                                        {item.loaiTinTucCon?.map((itemCon: any) => (
                                                                            <tr key={itemCon.id}>
                                                                                <td className='text-left'>{itemCon.tenLoai}</td>
                                                                                <td>
                                                                                    <Form.Item name={'loaiTin' + itemCon.id} valuePropName={itemCon.id}>
                                                                                        <Switch
                                                                                            defaultChecked={objectPhanQuyen.phanLoai?.includes(itemCon.id) ? true : false}
                                                                                            onChange={(checked) => handleLoaiTinChange(itemCon.id, checked)} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                                                                    </Form.Item>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </Fragment>
                                                                ))}
                                                            </Fragment>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>)
                                    : (<div className='row'></div>)
                                }

                                <div className='row mt-4'>
                                    <div className='col-12 text-right'>
                                        <div className="form-group">
                                            <button type="button" onClick={handleCloseEditorModal} className="btn btn-info" >Cancel</button>
                                            <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                Cập nhật
                                            </></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal>

                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>Nhân Sự</h1>
                </div>
                {/* Content Row */}
                <div className="row">
                    <div className="col-12">
                        <Form form={form} name="search" onFinish={refreshData}>
                            <div className="row">
                                <div className='col-xl-3 col-md-6'>
                                    <Form.Item name="idTenThanh">
                                        <Select placeholder="Tên thánh" size="large" showSearch optionFilterProp="label" onChange={() => form.submit()}>
                                            <Select.Option value="" labe=''>{''}</Select.Option>
                                            {lstTenThanh?.map((item: any, index) => (
                                                <Select.Option key={index} value={item.id} label={item.tenThanh}>{item.tenThanh}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className='col-xl-3 col-md-6'>
                                    <Form.Item name="idCongDoan">
                                        <Select placeholder="Cộng đoàn" size="large" showSearch optionFilterProp="label" onChange={() => form.submit()}>
                                            <option value="" label=''></option>
                                            {lstCongDoan?.map((item: any, index) => (
                                                <Select.Option key={index} value={item.id} label={item.tenCongDoan}>{item.tenCongDoan}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className='col-xl-3 col-md-6'>
                                    <div className="form-group">
                                        <Form.Item name="idChucThanh">
                                            <Select placeholder="Chức Thánh" size="large" onChange={() => form.submit()}>
                                                <option value=""></option>
                                                {lstChucThanh?.map((item: any, index) => (
                                                    <Select.Option key={index} value={item.id}>{item.tenChucThanh}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className='col-xl-3 col-md-6'>
                                    <div className="form-group">
                                        <Form.Item name="idQuocGia">
                                            <Select placeholder="Quốc Gia" size="large" showSearch optionFilterProp="label" onChange={() => form.submit()}>
                                                <option value="" label=''></option>
                                                {lstQuocGia?.map((item: any, index) => (
                                                    <Select.Option key={index} value={item.id} label={item.tenQuocGia}>{item.tenQuocGia}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className='col-xl-3 col-md-6'>
                                    <div className="form-group">
                                        <Form.Item name="idTinhTrang">
                                            <Select placeholder="Tình trạng" size="large" onChange={() => form.submit()}>
                                                <option value=""></option>
                                                {lstTinhTrang?.map((item: any, index) => (
                                                    <Select.Option key={index} value={item.id}>{item.tenTinhTrang}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className='col-xl-3 col-md-6'>
                                    <Form.Item name="keySearch">
                                        <Input className='form-control' placeholder="Tư khóa tìm kiếm" autoComplete="off" />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Lọc dữ liệu
                                        </button>
                                        <Link type="button" className="btn btn-info ml-2" to="/nhan-su/them-moi" >Tạo mới</Link>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>

                    <div className="col-12">
                        <div className='text-right mb-2'>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={MAX_ITEMS_PER_PAGE}
                                onChange={onPageChanged}
                                showSizeChanger={false}
                            />
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                                <thead style={{ backgroundColor: '#fac2b5' }}>
                                    <tr>
                                        <th>Mã</th>
                                        <th>Tên Thánh, Họ Tên</th>
                                        <th>Cộng đoàn</th>
                                        <th>Email</th>
                                        <th>Điện thoại</th>
                                        <th>User level</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={6}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td>{item.ma}</td>
                                                    <td>{item.hoTen}</td>
                                                    <td>{item.tenCongDoan}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.dienThoai}</td>
                                                    <td>
                                                        <span className={
                                                            item.userLevel === 'Administrator' ? "badge text-success"
                                                                : item.userLevel === 'User-co' ? "badge text-warning"
                                                                    : "badge"
                                                        }
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleShowPopupChinhSuaQuyen(item.id, "Chỉnh sửa quyền nhân sự: " + item.hoTen, item.userLevel)}>{item.userLevel}</span>
                                                    </td>
                                                    <td style={{ display: 'flex' }}>
                                                        <img onClick={() => handleSendMailResetPassword(item.id, item.hoTen, item.email)} style={{ width: '23px', height: '23px', cursor: 'pointer' }} src='/images/web_pass.png' alt={item.ma} />
                                                        <Link to={`/nhan-su/chinh-sua/${item.id}`} style={{ marginLeft: '10px' }}>
                                                            {/* Chèn nội dung bên trong thẻ Link */}
                                                            <img style={{ width: '22px' }} src='/images/web_new.png' alt={item.ma} />
                                                        </Link>
                                                        <img onClick={() => handleDelete(item.id)} style={{ width: '23px', cursor: 'pointer', marginLeft: '10px' }} src='/images/web_delete.png' alt={item.ma} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='text-right'>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={MAX_ITEMS_PER_PAGE}
                                onChange={onPageChanged}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
