import React, { Fragment, useEffect, useState } from 'react'
import { lichDongService, tenThanhService } from '../../services';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DatePicker, Form, Input, Radio, Select, message } from 'antd';
import InputImage from '../../components/InputImage';
import dayjs from 'dayjs';
import EditorCustom from '../../components/EditorCustom';

export const ChinhSuaLichDong = () => {
    // Function to get the current date with time set to 00:00:00
    const navigate = useNavigate();
    const [formUpdateLichDong] = Form.useForm();
    const [loading, setLoading] = useState(true);
    // cho image
    const [urlImgAnhDaiDienUpdate, setUrlImgAnhDaiDienUpdate] = useState('');
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDienUpdate(value);
    };
    const onClose = () => {
        navigate('/lich-dong');
    };

    const { id } = useParams();
    const [objectDetailUpdate, setChiTietUpdate] = useState<any>(null);
    useEffect(() => {
        const fetchCongDoanData = async () => {
            try {
                const response = await lichDongService.getById(id); // Gọi API chỉ một lần
                if (response.status === 200) {
                    setChiTietUpdate(response.data);
                    formUpdateLichDong.setFieldsValue({
                        ngay: response.data.ngay ? dayjs(response.data.ngay) : null,
                        tieuDe: response.data.tieuDe,
                        tieuDePhu: response.data.tieuDePhu,
                        anhDaiDien: response.data.hinhDaiDien,
                        mauAoLe: response.data.mauAoLe,
                        ghiChu: response.data.ghiChu
                    });
                    setUrlImgAnhDaiDienUpdate(response.data.hinhAnhDaiDien);
                } else if (response.status === 404) {
                    message.error('Id không tồn tại', onClose);
                } else {
                    message.error(response.data.title, onClose);
                }
            } catch (error: any) {
                message.error(error.message, onClose);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchCongDoanData();
        }
    }, [id, formUpdateLichDong]);

    const onSubmitUpdateLichDong = async (values: any) => {
        setLoading(true);
        try {
            const response = await lichDongService.createOrUpdate(id, values.ngay ? values.ngay.format("DD.MM.YYYY") : null,
                values.tieuDe, values.tieuDePhu, values.anhDaiDien, values.mauAoLe, values.ghiChu === '<div><br></div>' ? null : values.ghiChu);
            if (response.code === 0) {
                message.success('Chỉnh sửa Lịch dòng thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    // cho trình soạn thảo văn bản
    const [chiTietThongTinUpdate, setChiTietThongTinUpdate] = useState('');
    const handleEditorChangeUpdateLichDong = (value: string) => {
        setChiTietThongTinUpdate(value);
    };
    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Lịch Dòng / Chỉnh sửa</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4' style={{ backgroundColor: 'rgb(255 201 201)' }}>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={formUpdateLichDong} name="detail" onFinish={onSubmitUpdateLichDong} className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/lich-dong">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Ngày</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="ngay" rules={[{ required: true, message: 'Vui lòng chọn ngày!' }]}>
                                                <DatePicker
                                                    size="large"
                                                    format="DD.MM.YYYY"
                                                    showTime={false}
                                                    inputReadOnly={true} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tiêu đề</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="tieuDe">
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tiêu đề phụ</label>
                                        <div className="col-sm-8">
                                            <Form.Item name="tieuDePhu">
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                        <div className="col-sm-9">
                                            <Form.Item name="anhDaiDien">
                                                {objectDetailUpdate ? (
                                                    <InputImage onChange={handleImageChange} initialValue={objectDetailUpdate.hinhDaiDien} />
                                                ) : (
                                                    <p><span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...</p>
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Màu áo lễ</label>
                                        <div className="col-sm-9">
                                            <Form.Item name="mauAoLe" rules={[{ required: true, message: 'Vui lòng chọn Màu áo lễ' }]}>
                                                <Radio.Group value={'green'}>
                                                    <Radio value="green">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_green.png'} />
                                                    </Radio>
                                                    <Radio value="white">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_white.png'} />
                                                    </Radio>
                                                    <Radio value="red">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_red.png'} />
                                                    </Radio>
                                                    <Radio value="violet">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_violet.png'} />
                                                    </Radio>
                                                    <Radio value="pink">
                                                        <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_pink.png'} />
                                                    </Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <label className="col-sm-3 col-form-label">Ghi chú</label>
                                <div className="col-sm-12">
                                    <Form.Item name="ghiChu">
                                        {objectDetailUpdate ? (
                                            <EditorCustom onChange={handleEditorChangeUpdateLichDong} initialValue={objectDetailUpdate.ghiChu} />
                                        ) : (
                                            <p><span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...</p>
                                        )}
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/lich-dong">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Chỉnh sửa
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
