import { api } from '../helpers';

const getAll = async () => {
    const params = { page: 0, size: 10000, sort: 'id' };
    return await api.get('/dcct/nhan-su/list', { params }).then((response: any) => {
        return response.data;
    });
};

const getAllLinhMuc = async () => {
    const params = { page: 0, size: 1000, sort: 'id', idChucThanh: 1 };
    return await api.get('/dcct/nhan-su/list', { params }).then((response: any) => {
        return response.data;
    });
};

const filterList = async (page: number | null, size: number | null,
    sort: string | null, idTenThanh: number | null, idCongDoan: number | null,
    idChucThanh: number | null, idQuocGia: number | null, idTinhTrang: number | null, keySearch: string | null) => {
    const params = { page, size, sort, idTenThanh, idCongDoan, idChucThanh, idQuocGia, idTinhTrang, keySearch };
    return await api.get('/admin-dcct/nhan-su/filter', { params }).then((response: any) => {
        return response;
    });
};

const createOrUpdate = async (id: any, ma: string, hoTenDem: string, ten: string, idTenThanh: number, idTinhTrang: number,
    idChucThanh: number, idCongDoan: number, idMucVu: number, idQuocGia: number, ngayPhoTe: string, ngayLinhMuc: string,
    email: string, diaChi: string, dienThoai: string, userLevel: string, ghiChu: string, anhDaiDien: string, ngaySinh: string, ngayKhan: string, ngayMat: string, idTenThanhBa: number, hoTenBa: string, ngayBaMat: string, idTenThanhMe: number, hoTenMe: string, ngayMeMat: string) => {
    const payLoad = {
        id, ma, hoTenDem, ten, idTenThanh, idTinhTrang, idChucThanh, idCongDoan, idMucVu, idQuocGia, ngayPhoTe, ngayLinhMuc, email, diaChi, dienThoai, userLevel,
        ghiChu, anhDaiDien, ngaySinh, ngayKhan, ngayMat, idTenThanhBa, hoTenBa, ngayBaMat, idTenThanhMe, hoTenMe, ngayMeMat
    };
    return await api.post('/admin-dcct/nhan-su/create-or-update', payLoad).then((response: any) => {
        return response.data;
    });
};

const getById = async (id: any) => {
    return await api.get(`/nhan-sus/${id}`).then((response: any) => {
        return response;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/admin-dcct/nhan-su/delete/${id}`).then((response: any) => {
        return response.data;
    });
};

const searchByName = async (keySearch: string) => {
    const payLoad = {
        keySearch
    };
    return await api.post('/admin-dcct/nhan-su/search-by-name', payLoad).then((response: any) => {
        return response.data;
    });
};

const resetMatKhau = async (email: string) => {
    const params = { email };
    return await api.get('/admin-dcct/nhan-su/get-mail-reset-password', { params }).then((response: any) => {
        return response.data;
    });
};

const getPhanQuyen = async (id: any) => {
    const params = { id };
    return await api.get('/admin-dcct/phan-quyen/get-by-userid', { params }).then((response: any) => {
        return response.data;
    });
};

const updatePhanQuyen = async (id: any, userLevel: string, congDoan: number[], phanLoai: number[]) => {
    const payLoad = {
        userLevel,
        congDoan,
        phanLoai
    };
    return await api.post(`/admin-dcct/phan-quyen/update-by-userid/${id}`, payLoad).then((response: any) => {
        return response.data;
    });
};

const getNhanSuTheoUid = async (congDoanId: any, roomsUid: any) => {
    const params = { congDoanId, roomsUid };
    return await api.get('/admin-dcct/firebase/get-user-chat-by-cong-doan', { params }).then((response: any) => {
        return response.data;
    });
};

const dongBoNhomChat = async (idCongDoan: any) => {
    const params = { idCongDoan };
    return await api.get('/admin-dcct/firebase/dong-bo-group-chat', { params }).then((response: any) => {
        return response.data;
    });
};

const banThongBaoVaoNhom = async (tenNhom: any, userIds: any, roomsId: any) => {
    const payLoad = {
        userIds,
        tenNhom,
        roomsId
    };
    return await api.post(`/admin-dcct/firebase/post-thong-bao-them-vao-nhom-chat`, payLoad).then((response: any) => {
        return response.data;
    });
};

export const nhanSuService = {
    filterList,
    createOrUpdate,
    getById,
    deleteById,
    getAllLinhMuc,
    getAll,
    searchByName,
    resetMatKhau,
    getPhanQuyen,
    updatePhanQuyen,
    getNhanSuTheoUid,
    dongBoNhomChat,
    banThongBaoVaoNhom
};