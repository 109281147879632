import { api } from '../helpers';

const getAll = async () => {
    return await api.get('/admin-dcct/quoc-gia/all').then((response) => {
        return response.data;
    });
};

const filterList = async (page: number | null, size: number | null, sort: string | null) => {
    const params = { page, size, sort };
    return await api.get('/quoc-gias', { params }).then((response: any) => {
        return response;
    });
};

const deleteById = async (id: any) => {
    return await api.delete(`/quoc-gias/${id}`).then((response: any) => {
        return response;
    });
};

const create = async (id: any, tenQuocGia: string, chauLuc: string) => {
    const payLoad = {
        id, tenQuocGia, chauLuc
    };
    return await api.post('/quoc-gias', payLoad).then((response: any) => {
        return response;
    });
};

const getById = async (id: any) => {
    return await api.get(`/quoc-gias/${id}`).then((response: any) => {
        return response;
    });
};

const update = async (id: any, tenQuocGia: string, chauLuc: string) => {
    const payLoad = {
        id, tenQuocGia, chauLuc
    };
    return await api.patch(`/quoc-gias/${id}`, payLoad).then((response: any) => {
        return response;
    });
};

export const quocGiaService = {
    getAll,
    filterList,
    deleteById,
    create,
    getById,
    update
};