import React, { Fragment, useEffect, useState } from 'react';
import { congDoanService, lichDongService, nhanSuService } from '../../services';
import { Link } from 'react-router-dom';
import { ANH_MAC_DINH, MAX_ITEMS_PER_PAGE_32, STORARE_NAME_LIST_NHAN_SU } from '../../helpers/constants';
import { DatePicker, Form, Input, Modal, Pagination, Radio, Select, message } from 'antd';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import EditorCustom from '../../components/EditorCustom';
import { suKienService } from '../../services/sukien.service';
import InputImage from '../../components/InputImage';

export const LichDong = () => {
    const [loading, setLoading] = useState(true);
    const [formSearch] = Form.useForm();
    const [formKinh] = Form.useForm();
    const [formSuKien] = Form.useForm();
    const [formKinhCoDinh] = Form.useForm();

    const [showEditor, setShowEditor] = useState(false);
    const [showEvent, setShowEventInfo] = useState(false);
    const [titlePopup, setTitlePopup] = useState('');
    const [showThemMoiSuKien, setShowThemMoiSuKien] = useState(false);
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);

    const [lstCongDoan, setLstCongDoan] = useState<any[]>([]);
    const [lstNhanSu, setLstNhanSu] = useState<any[]>([]);

    useEffect(() => {
        const InitData = async () => {
            try {
                setLoading(true);
                const congDoanResponse = await congDoanService.getAll();
                if (congDoanResponse.code === 0) {
                    setLstCongDoan(congDoanResponse.data);
                }
                // lưu trữ và localStorage
                const storedUserList = localStorage.getItem(STORARE_NAME_LIST_NHAN_SU);
                if (storedUserList) {
                    // case đã tồn tại
                    setLstNhanSu(JSON.parse(storedUserList));
                } else {
                    // chưa tồn tại thì get ra
                    const linhMucResponse = await nhanSuService.getAll();
                    if (linhMucResponse.code === 0) {
                        setLstNhanSu(linhMucResponse.data);
                    }
                    localStorage.setItem(STORARE_NAME_LIST_NHAN_SU, JSON.stringify(linhMucResponse.data));
                }

                // set tháng hiện tại
                formSearch.setFieldsValue({
                    mmDDSelected: dayjs().startOf('month'),
                });
                const values = await formSearch.validateFields();
                await fetchData(1, '', values.mmDDSelected);
            } catch (error: any) {
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        };
        InitData();
    }, []);

    const fetchData = (pageNumber: number, keySearch: any, date: any) => {
        setLoading(true);

        if (!date) {
            formSearch.setFieldsValue({
                mmDDSelected: dayjs(),
            });
        }
        const mm = date ? date.month() + 1 : dayjs().month() + 1;
        const year = date ? date.year() : dayjs().year();

        lichDongService.filterList(pageNumber - 1, MAX_ITEMS_PER_PAGE_32, null, mm, year, keySearch)
            .then((response) => {
                if (response.status === 200) {
                    setTotal(response.headers['x-total-count']);
                    setLstDanhSach(response.data.data);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
                setLoading(false);
            });
    };

    const onPageChanged = async (pageNumber: number) => {
        setCurrentPage(pageNumber);

        const values = await formSearch.validateFields();
        fetchData(pageNumber, values.keySearch, values.mmDDSelected);
    };

    const refreshData = async () => {
        try {
            const values = await formSearch.validateFields();
            fetchData(1, values.keySearch, values.mmDDSelected);
            setCurrentPage(1);
        } catch (error: any) {
            message.error({ content: "Chưa chọn năm", duration: 2 });
        }
    };

    // cho trình soạn thảo văn bản
    const [chiTietKinh, setChiTietThongTin] = useState('');
    const [loaiKinhUpdate, setLoaiKinhUpdate] = useState('');
    const handleEditorChange = (value: string) => {
        setChiTietThongTin(value);
    };

    const handleShowPopup = async (typeUpdate: string, contents: string, titel: string) => {
        setChiTietThongTin(contents);
        setTitlePopup(titel);
        setLoaiKinhUpdate(typeUpdate);
        setShowEditor(true);
    }

    const [lstDanhSachSuKien, setLstDanhSachSuKien] = useState<any[]>([]);
    const handleShowPopupSuKien = async (date: any, titel: string) => {
        setTitlePopup(titel);
        setShowEventInfo(true);
        suKienService.getAll(date)
            .then((response) => {
                if (response.code === 0) {
                    setLstDanhSachSuKien(response.data);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            });
    }

    const handleShowPopupThemMoiSuKien = async (date: any, titel: string) => {
        setTitlePopup(titel);
        setShowThemMoiSuKien(true);
    }

    const handleCloseEditorModal = () => {
        setShowEditor(false);
        setShowEventInfo(false);
        setShowThemMoiSuKien(false);
        setShowEditorKinhCoDinh(false);
    }

    const updateKinh = async () => {
        setLoading(true);
        try {
            const response = await lichDongService.updateKinh(loaiKinhUpdate, chiTietKinh === '<p><br></p>' ? '' : chiTietKinh);
            if (response.code === 0) {
                message.success('Cập nhật thành công');
                refreshData();
            } else {
                message.error({ content: response.message, duration: 2 });
            }
            setLoading(false);
            setShowEditor(false);
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const handleDelete = async (id: any) => {
        const result = await Swal.fire({
            title: 'Xác nhận xóa Lịch Dòng này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const response = await lichDongService.deleteById(id);
                if (response.status === 204) {
                    message.success('Xóa Lịch Dòng thành công');
                    const values = await formSearch.validateFields();
                    fetchData(currentPage, values.keySearch, values.mmDDSelected);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setLoading(false);
            }
        }
    };

    const taoNhanhLichTrongThang = async (id: any) => {
        const values = await formSearch.validateFields();
        if (!values.mmDDSelected) {
            message.error({ content: "Chọn thời gian muốn tạo ổ tìm kiếm", duration: 2 });
            return
        }
        const result = await Swal.fire({
            title: 'Xác nhận tạo nhanh Lịch Dòng trong tháng ' + values.mmDDSelected.format("MM-YYYY"),
            html: 'Khi bạn xác nhận tất cả dư liệu hiện tại của tháng sẽ bị xóa để cập nhật lại',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const mm = values.mmDDSelected.month() + 1;
                const year = values.mmDDSelected.year();
                const response = await lichDongService.genDuLieuLichDong(mm, year);
                if (response.status === 200) {
                    message.success('Tạo nhanh Lịch Dòng thành công');
                    const values = await formSearch.validateFields();
                    setCurrentPage(1);
                    fetchData(1, values.keySearch, values.mmDDSelected);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setLoading(false);
            }
        }
    };

    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await suKienService.createOrUpdate(null, values.trongNgay.format("DD.MM.YYYY"), values.loaiSuKien, values.tuGio, values.denGio,
                values.tieuDeSuKien, values.chiTietThongTin, values.idCongDoan, values.idNhanSu, values.denNgay ? values.denNgay.format("DD.MM.YYYY") : null);
            if (response.code === 0) {
                message.success('Tạo mới Sự Kiện thành công');
                refreshData();
                setLoading(false);
                handleCloseEditorModal();
                formSuKien.resetFields();
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const [showEditorKinhCoDinh, setShowEditorKinhCoDinh] = useState(false);
    const [chiTietKinhCoDinh, setChiTietKinhCoDinh] = useState('');
    const [titleKinhCoDinh, setTitleKinhCoDinh] = useState('');

    const handleEditorChangeKinhCoDinh = (value: string) => {
        setChiTietKinhCoDinh(value);
    };

    const updateKinhCoDinh = async (values: any) => {
        setLoading(true);
        try {
            const response = await lichDongService.updateKinhCoDinh(chiTietKinhCoDinh === '<p><br></p>' ? '' : chiTietKinhCoDinh, values.id, values.key);
            if (response.status === 200) {
                message.success('Cập nhật thành công');
                refreshData();
            } else {
                message.error({ content: response.message, duration: 2 });
            }
            setLoading(false);
            setShowEditorKinhCoDinh(false);
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    const showTinCoDinh = async (id: any, key: any, title: string) => {
        setLoading(true);
        try {
            const response = await lichDongService.getKinhCoDinh(id);
            if (response.status === 200) {
                setChiTietKinhCoDinh(response.data.value);
                formKinhCoDinh.setFieldsValue({
                    id: id,
                    key: key,
                });
                setTitleKinhCoDinh(title);
                setShowEditorKinhCoDinh(true);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    // start Them moi Lich Dong
    const [showThemMoiLichDong, setShowThemMoiLichDong] = useState(false);
    const [formThemMoi] = Form.useForm();
    const [urlImgAnhDaiDienThemMoi, setUrlImgAnhDaiDienThemMoi] = useState(ANH_MAC_DINH);
    const handleImageChangeThemMoi = (value: string) => {
        setUrlImgAnhDaiDienThemMoi(value);
    };
    const onSubmitThemMoi = async (values: any) => {
        setLoading(true);
        try {
            const response = await lichDongService.createOrUpdate(null, values.ngay ? values.ngay.format("DD.MM.YYYY") : null,
                values.tieuDe, values.tieuDePhu, urlImgAnhDaiDienThemMoi ? urlImgAnhDaiDienThemMoi : ANH_MAC_DINH, values.mauAoLe, values.ghiChu);
            if (response.code === 0) {
                message.success({ content: 'Tạo mới Lịch dòng thành công', duration: 3 });
                formThemMoi.resetFields();
                setShowThemMoiLichDong(false);
                refreshData();
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };
    // End Them moi Lich Dong

    // Start chỉnh sửa lịch dòng
    const [formUpdateLichDong] = Form.useForm();
    const [urlImgAnhDaiDienUpdate, setUrlImgAnhDaiDienUpdate] = useState('');
    const handleImageChange = (value: string) => {
        setUrlImgAnhDaiDienUpdate(value);
    };
    const [showUpdateLichDong, setShowUpdateLichDong] = useState(false);

    const [objectDetailUpdate, setChiTietUpdate] = useState<any>(null);
    const handleShowUpdateLichDong = async (id: any) => {
        try {
            setLoading(true);
            const response = await lichDongService.getById(id); // Gọi API chỉ một lần
            if (response.status === 200) {
                setChiTietUpdate(response.data);
                formUpdateLichDong.setFieldsValue({
                    ngay: response.data.ngay ? dayjs(response.data.ngay) : null,
                    tieuDe: response.data.tieuDe,
                    tieuDePhu: response.data.tieuDePhu,
                    anhDaiDien: response.data.hinhDaiDien,
                    mauAoLe: response.data.mauAoLe,
                    ghiChu: response.data.ghiChu
                });
                setUrlImgAnhDaiDienUpdate(response.data.hinhAnhDaiDien);

                setShowUpdateLichDong(true);
            } else if (response.status === 404) {
                message.error('Id không tồn tại');
            } else {
                message.error(response.data.title);
            }
        } catch (error: any) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }

    }

    const onSubmitUpdateLichDong = async (values: any) => {
        setLoading(true);
        try {
            const response = await lichDongService.createOrUpdate(objectDetailUpdate.id, values.ngay ? values.ngay.format("DD.MM.YYYY") : null,
                values.tieuDe, values.tieuDePhu, values.anhDaiDien, values.mauAoLe, values.ghiChu === '<div><br></div>' ? null : values.ghiChu);
            if (response.code === 0) {
                message.success('Chỉnh sửa Lịch dòng thành công')
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoading(false);
            setShowUpdateLichDong(false);
            refreshData();
        }
    };

    // cho trình soạn thảo văn bản
    const [chiTietThongTinUpdate, setChiTietThongTinUpdate] = useState('');
    const handleEditorChangeUpdateLichDong = (value: string) => {
        setChiTietThongTinUpdate(value);
    };
    // End chính sửa lịch dong
    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                <Modal
                    title='Thêm mới lịch dòng'
                    open={showThemMoiLichDong}
                    maskClosable={false}
                    onCancel={() => setShowThemMoiLichDong(false)}
                    width={'98%'} 
                    footer={null}>
                    <Fragment>
                        <div className='card shadow mb-4' style={{ backgroundColor: 'rgb(255 201 201)' }}>
                            <div className="card-body" style={{ color: '#000' }}>
                                <Form form={formThemMoi} name="detail" onFinish={onSubmitThemMoi} className="c-formNew">
                                    <div className='row mb-2'>
                                        <div className='col-12 text-right'>
                                            <div className="form-group">
                                                <button type="button" className="btn btn-info" onClick={() => setShowThemMoiLichDong(false)}>Close</button>
                                                <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                                    {loading && (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    )}
                                                    Tạo mới
                                                </></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Ngày</label>
                                                <div className="col-sm-8">
                                                    <Form.Item name="ngay" rules={[{ required: true, message: 'Vui lòng chọn ngày!' }]}>
                                                        <DatePicker
                                                            size="large"
                                                            format="DD.MM.YYYY"
                                                            showTime={false}
                                                            inputReadOnly={true} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Tiêu đề</label>
                                                <div className="col-sm-8">
                                                    <Form.Item name="tieuDe">
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Tiêu đề phụ</label>
                                                <div className="col-sm-8">
                                                    <Form.Item name="tieuDePhu">
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                                <div className="col-sm-9">
                                                    <Form.Item name="anhDaiDien">
                                                        <InputImage onChange={handleImageChangeThemMoi} initialValue={ANH_MAC_DINH} />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Màu áo lễ</label>
                                                <div className="col-sm-9">
                                                    <Form.Item name="mauAoLe" rules={[{ required: true, message: 'Vui lòng chọn Màu áo lễ' }]}>
                                                        <Radio.Group value={'green'}>
                                                            <Radio value="green">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_green.png'} />
                                                            </Radio>
                                                            <Radio value="white">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_white.png'} />
                                                            </Radio>
                                                            <Radio value="red">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_red.png'} />
                                                            </Radio>
                                                            <Radio value="violet">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_violet.png'} />
                                                            </Radio>
                                                            <Radio value="pink">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_pink.png'} />
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <label className="col-sm-3 col-form-label">Ghi chú</label>
                                        <div className="col-sm-12">
                                            <Form.Item name="ghiChu">
                                                <EditorCustom onChange={handleEditorChange} initialValue={''} />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-12 text-right'>
                                            <div className="form-group">
                                                <button type="button" className="btn btn-info" onClick={() => setShowThemMoiLichDong(false)}>Close</button>
                                                <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                                    {loading && (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    )}
                                                    Tạo mới
                                                </></button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Fragment>
                </Modal>

                <Modal title='Chỉnh sửa lịch dòng'
                    open={showUpdateLichDong}
                    maskClosable={false}
                    onCancel={() => setShowUpdateLichDong(false)}
                    width={'98%'}
                    footer={null}>
                    <Fragment>
                        <div className='card shadow mb-4' style={{ backgroundColor: 'rgb(255 201 201)' }}>
                            <div className="card-body" style={{ color: '#000' }}>
                                <Form form={formUpdateLichDong} name="detail" onFinish={onSubmitUpdateLichDong} className="c-formNew">
                                    <div className='row mb-2'>
                                        <div className='col-12 text-right'>
                                            <div className="form-group">
                                                <button type="button" className="btn btn-info" onClick={() => setShowUpdateLichDong(false)}>Close</button>
                                                <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                                    {loading && (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    )}
                                                    Chỉnh sửa
                                                </></button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Ngày</label>
                                                <div className="col-sm-8">
                                                    <Form.Item name="ngay" rules={[{ required: true, message: 'Vui lòng chọn ngày!' }]}>
                                                        <DatePicker
                                                            size="large"
                                                            format="DD.MM.YYYY"
                                                            showTime={false}
                                                            inputReadOnly={true} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Tiêu đề</label>
                                                <div className="col-sm-8">
                                                    <Form.Item name="tieuDe">
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Tiêu đề phụ</label>
                                                <div className="col-sm-8">
                                                    <Form.Item name="tieuDePhu">
                                                        <Input className='form-control' autoComplete="off" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Hình ảnh đại diện</label>
                                                <div className="col-sm-9">
                                                    <Form.Item name="anhDaiDien">
                                                        {objectDetailUpdate ? (
                                                            <InputImage onChange={handleImageChange} initialValue={objectDetailUpdate.hinhDaiDien} />
                                                        ) : (
                                                            <p><span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...</p>
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">Màu áo lễ</label>
                                                <div className="col-sm-9">
                                                    <Form.Item name="mauAoLe" rules={[{ required: true, message: 'Vui lòng chọn Màu áo lễ' }]}>
                                                        <Radio.Group value={'green'}>
                                                            <Radio value="green">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_green.png'} />
                                                            </Radio>
                                                            <Radio value="white">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_white.png'} />
                                                            </Radio>
                                                            <Radio value="red">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_red.png'} />
                                                            </Radio>
                                                            <Radio value="violet">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_violet.png'} />
                                                            </Radio>
                                                            <Radio value="pink">
                                                                <img style={{ width: '26px', marginRight: '10px' }} src={'/images/aole_pink.png'} />
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <label className="col-sm-3 col-form-label">Ghi chú</label>
                                        <div className="col-sm-12">
                                            <Form.Item name="ghiChu">
                                                {objectDetailUpdate ? (
                                                    <EditorCustom onChange={handleEditorChangeUpdateLichDong} initialValue={objectDetailUpdate.ghiChu} />
                                                ) : (
                                                    <p><span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...</p>
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-12 text-right'>
                                            <div className="form-group">
                                                <button type="button" className="btn btn-info" onClick={() => setShowUpdateLichDong(false)}>Close</button>
                                                <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                                    {loading && (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    )}
                                                    Chỉnh sửa
                                                </></button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Fragment>
                </Modal>

                <Modal
                    title={titlePopup}
                    open={showEditor}
                    onCancel={handleCloseEditorModal}
                    maskClosable={false}
                    footer={[
                        <div className='row mt-2'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-info" onClick={handleCloseEditorModal}>Cancel</button>
                                    <button type="submit" className="btn btn-primary ml-2" onClick={() => updateKinh()}> <>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Chỉnh sửa
                                    </></button>
                                </div>
                            </div>
                        </div>
                    ]}
                    width={1200}>
                    {/* Hiển thị trình soạn thảo trong modal */}

                    <Form form={formKinh} name="kinh">
                        <div className='row mt-2'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-info" onClick={handleCloseEditorModal}>Cancel</button>
                                    <button type="submit" className="btn btn-primary ml-2" onClick={() => updateKinh()}> <>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Chỉnh sửa
                                    </></button>
                                </div>
                            </div>
                        </div>

                        <Form.Item name="chiTietKinh">
                            <EditorCustom onChange={handleEditorChange} initialValue={chiTietKinh} />
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title={titleKinhCoDinh}
                    open={showEditorKinhCoDinh}
                    onCancel={handleCloseEditorModal}
                    maskClosable={false}
                    footer={[
                        <div className='row mt-2'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-info" onClick={handleCloseEditorModal}>Cancel</button>
                                    <button type="submit" className="btn btn-primary ml-2" onClick={() => formKinhCoDinh.submit()}> <>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Chỉnh sửa
                                    </></button>
                                </div>
                            </div>
                        </div>
                    ]}
                    width={1200}>
                    {/* Hiển thị trình soạn thảo trong modal */}

                    <Form form={formKinhCoDinh} name="kinhCoDinh"
                        onFinish={updateKinhCoDinh}>

                        <Form.Item name="id" hidden={true} >
                            <Input />
                        </Form.Item>

                        <Form.Item name="key" hidden={true}>
                            <Input />
                        </Form.Item>

                        <div className='row mt-2'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-info" onClick={handleCloseEditorModal}>Cancel</button>
                                    <button type="submit" className="btn btn-primary ml-2"> <>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Chỉnh sửa
                                    </></button>
                                </div>
                            </div>
                        </div>

                        <Form.Item name="chiTietKinh">
                            <EditorCustom onChange={handleEditorChangeKinhCoDinh} initialValue={chiTietKinhCoDinh} />
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title={titlePopup}
                    open={showEvent}
                    onCancel={handleCloseEditorModal}
                    maskClosable={false}
                    width={1200}>
                    <div className='row'>
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead style={{ backgroundColor: '#fac2b5' }}>
                                        <tr>
                                            <th className='text-center'>Thời gian</th>
                                            <th className='text-center'>Phân loại</th>
                                            <th className='text-center'>Tên sự kiện</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            // Hiển thị bộ Loading khi loadingLoadList là true
                                            <tr className='text-center'>
                                                <td colSpan={3}>
                                                    <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                                </td>
                                            </tr>
                                        ) : (
                                            // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                            <Fragment>
                                                {lstDanhSachSuKien?.map((item, index) => (
                                                    <tr style={{ color: '#000', fontWeight: '400' }} key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                        <td style={{ color: 'rgb(232, 59, 70)', fontWeight: 'bold' }} >{item.tuGio} - {item.denGio}</td>
                                                        <td>{
                                                            item.loaiSuKien ? (
                                                                <span>
                                                                    {item.loaiSuKien === 'TOAN_TINH' ? "Toàn Tỉnh"
                                                                        : item.loaiSuKien === 'CONG_DOAN' ? item.congDoans[0]?.tenCongDoan
                                                                            : item.loaiSuKien === 'CA_NHAN' ? item.nhanSus[0]?.user?.firstName + ' ' + item.nhanSus[0]?.user?.lastName
                                                                                : ""}
                                                                </span>
                                                            ) : (<span>Chứa xác thực thông tin</span>)
                                                        }
                                                        </td>
                                                        <td>
                                                            {item.tenSuKien}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </Fragment>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title={titlePopup}
                    open={showThemMoiSuKien}
                    onCancel={handleCloseEditorModal}
                    maskClosable={false}
                    footer={[
                    ]}
                    width={1200}>

                    <Form form={formSuKien} name="suKienThemMoi"
                        onFinish={onSubmit}
                        className="c-formNew"
                        initialValues={{
                            trongNgay: dayjs().startOf('day'), // Lấy ngày hiện tại
                        }}
                    >
                        <div className='row mb-2'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" onClick={handleCloseEditorModal} className="btn btn-info" >Cancel</button>
                                    <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Tạo mới
                                    </></button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className='col-sm-6'>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Sự kiện trong ngày</label>
                                    <div className="col-sm-8">
                                        <Form.Item
                                            name="trongNgay" // Tên của trường trong form
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn ngày đăng',
                                                }
                                            ]}>
                                            <DatePicker
                                                size="large"
                                                format="DD.MM.YYYY"
                                                showTime={false}
                                                inputReadOnly={true}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Từ giờ</label>
                                    <div className="col-sm-8">
                                        <Form.Item
                                            name="tuGio"
                                            rules={[
                                                {
                                                    pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
                                                    message: 'Định dạng giờ không hợp lệ. Vui lòng nhập theo định dạng HH:MM!'
                                                }
                                            ]}>
                                            <Input className='form-control' placeholder='HH:MM' autoComplete="off" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Tiêu đề sự kiện</label>
                                    <div className="col-sm-8">
                                        <Form.Item
                                            name="tieuDeSuKien"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng nhập tiêu đề sự kiện!'
                                                }
                                            ]}>
                                            <Input className='form-control' placeholder='Tiêu đề sự kiện' autoComplete="off" />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-6'>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Đến ngày</label>
                                    <div className="col-sm-8">
                                        <Form.Item name="denNgay"
                                            rules={[
                                                {
                                                    validator: async (_, value) => {
                                                        const trongNgayValue = formSuKien.getFieldValue('trongNgay');
                                                        if (value && trongNgayValue) {
                                                            // Chuyển đổi giá trị ngày thành định dạng ngày tháng năm
                                                            const denNgayDate = dayjs(value.format('YYYY-MM-DD'));
                                                            const trongNgayDate = dayjs(trongNgayValue.format('YYYY-MM-DD'));
                                                            // So sánh ngày "denNgay" lớn hơn "trongNgay"
                                                            if (denNgayDate <= trongNgayDate) {
                                                                return Promise.reject('Ngày kết thúc phải lớn hơn ngày bắt đầu');
                                                            }
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                size="large"
                                                format="DD.MM.YYYY"
                                                showTime={false}
                                                inputReadOnly={true}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Đến giờ</label>
                                    <div className="col-sm-8">
                                        <Form.Item
                                            name="denGio"
                                            rules={[
                                                {
                                                    pattern: /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/,
                                                    message: 'Định dạng giờ không hợp lệ. Vui lòng nhập theo định dạng HH:MM!'
                                                }
                                            ]}
                                        >
                                            <Input className='form-control' placeholder='HH:MM' autoComplete="off" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Phân loại</label>
                                    <div className="col-sm-8">
                                        <Form.Item name="loaiSuKien"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn Loại sự kiên',
                                                }
                                            ]}
                                        >
                                            <Select placeholder="Chọn loại" size="large">
                                                <Select.Option value={'TOAN_TINH'}>Toàn Tỉnh</Select.Option>
                                                <Select.Option value={'CONG_DOAN'}>Cộng Đoàn</Select.Option>
                                                <Select.Option value={'CA_NHAN'}>Cá Nhân</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Chọn cộng đoàn</label>
                                    <div className="col-sm-8">
                                        {loading ? (
                                            // Hiển thị bộ Loading khi loadingLoadList là true
                                            <tr className='text-center'>
                                                <td colSpan={6}>
                                                    <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                                </td>
                                            </tr>
                                        ) : (
                                            // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                            <Form.Item name="idCongDoan"
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (getFieldValue('loaiSuKien') === 'CONG_DOAN' && !value) {
                                                                return Promise.reject('Vui lòng chọn Cộng Đoàn');
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Select placeholder="Chọn Cộng Đoàn" size="large"
                                                    showSearch
                                                    optionFilterProp="label">
                                                    <Select.Option value={''} label={''}>{''}</Select.Option>
                                                    {lstCongDoan.map((item: any, index) => (
                                                        <Select.Option key={index} value={item.id} label={item.tenCongDoan}>{item.tenCongDoan}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Chọn Nhân Sự</label>
                                    <div className="col-sm-8">
                                        {loading ? (
                                            // Hiển thị bộ Loading khi loadingLoadList là true
                                            <tr className='text-center'>
                                                <td colSpan={6}>
                                                    <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                                </td>
                                            </tr>
                                        ) : (
                                            // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                            <Form.Item name="idNhanSu"
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (getFieldValue('loaiSuKien') === 'CA_NHAN' && !value) {
                                                                return Promise.reject('Vui lòng chọn Nhân Sự');
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Select placeholder="Chọn Nhân Sự"
                                                    showSearch
                                                    optionFilterProp="label"
                                                    size="large">
                                                    <Select.Option value={''} label={''}>{''}</Select.Option>
                                                    {lstNhanSu.map((item: any, index) => (
                                                        <Select.Option key={index} value={item.id} label={item.hoTen}>{item.hoTen}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <label className="col-sm-3 col-form-label">Nội dung sự kiện</label>
                            <div className="col-sm-12">
                                <Form.Item name="chiTietThongTin">
                                    <EditorCustom onChange={handleEditorChange} initialValue={''} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" onClick={handleCloseEditorModal} className="btn btn-info" >Cancel</button>
                                    <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Tạo mới
                                    </></button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal>

                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>Lịch Dòng</h1>
                </div>
                {/* Content Row */}

                <Form form={formSearch} name="search">
                    <div className="row">
                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                <Form.Item name="keySearch">
                                    <Input className='form-control' placeholder="Tìm kiếm theo tiêu đề" autoComplete="off" />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                <Form.Item name="mmDDSelected">
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        size="large"
                                        format="MM.YYYY"
                                        picker="month"
                                        inputReadOnly={true} />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>

                <div className="row">
                    <div className="col-12">
                        <div className='row'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary mb-2" onClick={refreshData} disabled={loading}>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Lọc dữ liệu
                                    </button>
                                    <button type="button" className="btn btn-success ml-2 mb-2" onClick={taoNhanhLichTrongThang} disabled={loading}>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Tạo nhanh dữ liệu trong tháng
                                    </button>
                                    {/* <Link type="button" className="btn btn-info ml-2 mb-2" to="/lich-dong/them-moi" >Tạo mới</Link> */}
                                    <button type="button" onClick={() => setShowThemMoiLichDong(true)} className="btn btn-info ml-2 mb-2"  >Tạo mới</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th className='text-center'>
                                            <button type="button" className="btn btn-warning ml-2" onClick={() => showTinCoDinh(1, 'KEY_KINH_CO_DINH', 'Cập Nhật Kinh DCCT')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                DCCT
                                            </button>
                                        </th>
                                        <th className='text-center'>
                                            <button type="button" className="btn btn-primary ml-2" onClick={() => showTinCoDinh(2, 'TCN1', 'Cập Nhật Kinh TCN1')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                TCN 1
                                            </button>
                                        </th>
                                        <th className='text-center'>
                                            <button type="button" className="btn btn-primary ml-2" onClick={() => showTinCoDinh(3, 'TCN2', 'Cập Nhật Kinh TCN2')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                TCN 2
                                            </button>
                                        </th>
                                        <th className='text-center'>
                                            <button type="button" className="btn btn-primary ml-2" onClick={() => showTinCoDinh(4, 'TCN3', 'Cập Nhật Kinh TCN3')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                TCN 3
                                            </button>
                                        </th>
                                        <th className='text-center'>
                                            <button type="button" className="btn btn-primary ml-2" onClick={() => showTinCoDinh(5, 'TCN4', 'Cập Nhật Kinh TCN4')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                TCN 4
                                            </button>
                                        </th>
                                        <th className='text-center'>
                                            <button type="button" className="btn btn-primary ml-2" onClick={() => showTinCoDinh(6, 'TCN5', 'Cập Nhật Kinh TCN5')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                TCN 5
                                            </button>
                                        </th>
                                        <th className='text-center'>
                                            <button type="button" className="btn btn-primary ml-2" onClick={() => showTinCoDinh(7, 'TCN6', 'Cập Nhật Kinh TCN6')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                TCN 6
                                            </button>
                                        </th>
                                        <th className='text-center'>
                                            <button type="button" className="btn btn-primary ml-2" onClick={() => showTinCoDinh(8, 'TCN7', 'Cập Nhật Kinh TCN7')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                TCN 7
                                            </button>
                                        </th>
                                        <th className='text-center'>
                                            <button type="button" className="btn btn-primary ml-2" onClick={() => showTinCoDinh(9, 'TCN8', 'Cập Nhật Kinh TCN8')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                TCN 8
                                            </button>
                                        </th>

                                        <th className='text-center'>
                                            <button type="button" className="btn btn-primary ml-2" onClick={() => showTinCoDinh(10, 'TCN9', 'Cập Nhật Kinh TCN9')} disabled={loading}>
                                                {loading && (
                                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                                )}
                                                TCN 9
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead style={{ backgroundColor: '#fac2b5' }}>
                                    <tr>
                                        <th className='text-center'>Thứ, ngày</th>
                                        <th className='text-center'>Tiêu đề</th>
                                        <th className='text-center'>Tiêu đề phụ</th>
                                        <th className='text-center'>Các giờ kinh</th>
                                        <th className='text-center'>Sự kiện</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={6}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach?.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td>{item.ngayTrongTuan}, {item.strNgay}</td>
                                                    <td>
                                                        <img style={{ width: '23px', marginRight: '10px' }} src={'/images/aole_' + item.mauAoLe + '.png'} />
                                                        {item.tieuDe}
                                                    </td>
                                                    <td>{item.tieuDePhu}</td>
                                                    <td className='text-center'>
                                                        <img style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={`/images/web_kinhthanh${item.kinhThanh ? '' : '_p'}.png`}
                                                            onClick={() => handleShowPopup(item.id + '_1', item.kinhThanh, `Kinh Thánh - ${item.ngayTrongTuan}, ${item.strNgay}: ${item.tieuDe}`)} />

                                                        <img style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={`/images/web_kinhsach${item.kinhSach ? '' : '_p'}.png`}
                                                            onClick={() => handleShowPopup(item.id + '_2', item.kinhSach, `Kinh Sách - ${item.ngayTrongTuan}, ${item.strNgay}: ${item.tieuDe}`)} />

                                                        <img style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={`/images/web_kinhsang${item.kinhSang ? '' : '_p'}.png`}
                                                            onClick={() => handleShowPopup(item.id + '_3', item.kinhSang, `Kinh Sáng - ${item.ngayTrongTuan}, ${item.strNgay}: ${item.tieuDe}`)} />

                                                        <img style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={`/images/web_kinh3h${item.kinh3h ? '' : '_p'}.png`}
                                                            onClick={() => handleShowPopup(item.id + '_43h', item.kinh3h, `Kinh Trưa Giờ Ba - ${item.ngayTrongTuan}, ${item.strNgay}: ${item.tieuDe}`)} />

                                                        <img style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={`/images/web_kinhtrua${item.kinhTrua ? '' : '_p'}.png`}
                                                            onClick={() => handleShowPopup(item.id + '_4', item.kinhTrua, `Kinh Trưa - ${item.ngayTrongTuan}, ${item.strNgay}: ${item.tieuDe}`)} />

                                                        {/* <img style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={`/images/web_kinh6h${item.kinh6h ? '' : '_p'}.png`}
                                                            onClick={() => handleShowPopup(item.id + '_46h', item.kinh6h, `Kinh Trưa Giờ Sáu - ${item.ngayTrongTuan}, ${item.strNgay}: ${item.tieuDe}`)} /> */}

                                                        <img style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={`/images/web_kinh9h${item.kinh9h ? '' : '_p'}.png`}
                                                            onClick={() => handleShowPopup(item.id + '_49h', item.kinh9h, `Kinh Trưa Giờ Chín - ${item.ngayTrongTuan}, ${item.strNgay}: ${item.tieuDe}`)} />

                                                        <img style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={`/images/web_kinhchieu${item.kinhChieu ? '' : '_p'}.png`}
                                                            onClick={() => handleShowPopup(item.id + '_5', item.kinhChieu, `Kinh Chiều - ${item.ngayTrongTuan}, ${item.strNgay}: ${item.tieuDe}`)} />

                                                        <img style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={`/images/web_kinhtoi${item.kinhToi ? '' : '_p'}.png`}
                                                            onClick={() => handleShowPopup(item.id + '_6', item.kinhToi, `Kinh Tối - ${item.ngayTrongTuan}, ${item.strNgay}: ${item.tieuDe}`)} />
                                                    </td>

                                                    <td className='text-center' >
                                                        <img onClick={() => handleShowPopupSuKien(item.strNgay, "Danh sách sự kiện " + item.ngayTrongTuan + ', ' + item.strNgay)} style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src={'/images/event.png'} />

                                                        <img onClick={() => handleShowPopupThemMoiSuKien(item.strNgay, "Thêm mới sự kiện cho ngày " + item.ngayTrongTuan + ', ' + item.strNgay)} style={{ width: '30px', cursor: 'pointer', marginRight: '10px' }} src='/images/circle-icon-plus.png' />
                                                    </td>

                                                    <td style={{ display: 'flex' }}>
                                                        {/* <Link to={`/lich-dong/chinh-sua/${item.id}`} style={{ marginLeft: '10px' }}>
                                                            <img style={{ width: '22px' }} src='/images/web_new.png' alt={item.ma} />
                                                        </Link> */}

                                                        <img onClick={() => handleShowUpdateLichDong(item.id)} style={{ width: '22px' }} src='/images/web_new.png' alt={item.ma} />


                                                        <img onClick={() => handleDelete(item.id)} style={{ width: '23px', cursor: 'pointer', marginLeft: '10px' }} src='/images/web_delete.png' alt={item.ma} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='text-right'>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={MAX_ITEMS_PER_PAGE_32}
                                onChange={onPageChanged}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}
