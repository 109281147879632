export const API_URL: string = 'https://api.cssrvn.org/api';
// export const API_URL: string = 'http://localhost:8080/api';
export const MAX_ITEMS_PER_PAGE: number = 15;
export const STORARE_NAME_LIST_NHAN_SU: string = 'NhanSuListVersion3';
export const ANH_MAC_DINH: string = "https://api.cssrvn.org/api/images/imgae_df.png";
export const ANH_DAI_DIEN_MAC_DINH: string = "https://api.cssrvn.org/api/images/avatar_df.png";
export const ANH_MAC_DINH_TIN_TUC: string = "https://api.cssrvn.org/api/images/df-tin-tuc.png";
export const ANH_MAC_DINH_TAI_LIEU: string = "https://api.cssrvn.org/api/images/df-tai-lieu.png";
export const ANH_MAC_DINH_THU_CHA_GIAM_TINH: string = "https://api.cssrvn.org/api/images/df-thu-cha-giam-tinh.png";
export const MAX_ITEMS_PER_PAGE_32: number = 32;
