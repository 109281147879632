import {
    HomeActionTypes,
    LOAD_REQUEST,
    LOAD_SUCCESS,
    LOAD_FAILURE,
    HomeState
} from './types';

const initialState: HomeState = {
    loading: true,
    error: null,
    lstChuThanh: [],
    lstCongDoan: [],
    lstQuocGia: []
};

const homeReducer = (
    state: HomeState = initialState,
    action: HomeActionTypes
): HomeState => {
    switch (action.type) {
        case LOAD_REQUEST: {
            return { ...state, loading: true };
        }

        case LOAD_SUCCESS: {
            return {
                ...state,
                loading: false,
                lstChuThanh: action.payload.lstChuThanh,
                lstCongDoan: action.payload.lstCongDoan,
                lstQuocGia: action.payload.lstQuocGia
            };
        }

        case LOAD_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                lstChuThanh: [],
                lstCongDoan: [],
                lstQuocGia: []
            };
        }

        default:
            return state;
    }
};
export { homeReducer };