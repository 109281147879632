import React, { Fragment, useState } from 'react'
import { quocGiaService } from '../../services';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Select, message } from 'antd';

export const ThemMoiQuocGia = () => {
    const onClose = () => {
        navigate('/quoc-gia');
    };
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    // cho image
    const onSubmit = async (values: any) => {
        setLoading(true);
        try {
            const response = await quocGiaService.create(null, values.tenQuocGia, values.chauLuc);
            if (response.status === 201) {
                message.success('Tạo mới Quốc Gia thành công', onClose)
            } else {
                setLoading(false);
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            setLoading(false);
            message.error({ content: error.message as string, duration: 1 });
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>NHÂN SỰ / Quốc Gia / Tạo Mới</h1>
                </div>
                {/* Content Row */}
                <div className='card shadow mb-4'>
                    <div className="card-body" style={{ color: '#000' }}>
                        <Form form={form} name="detail" onFinish={onSubmit} className="c-formNew">
                            <div className='row mb-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/quoc-gia">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Tên Quốc Gia</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="tenQuocGia"
                                                rules={[{ required: true, message: 'Vui lòng nhập Tên Quốc Gia !' }]}>
                                                <Input className='form-control' autoComplete="off" />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Châu lục</label>
                                        <div className="col-sm-8">
                                            <Form.Item
                                                name="chauLuc"
                                                rules={[{ required: true, message: 'Vui lòng chọn Châu lục !' }]}>
                                                <Select placeholder="Chọn châu lục" size="large">
                                                    <Select.Option key={'CHAU_A'} value={'CHAU_A'}>{'Châu Á'}</Select.Option>
                                                    <Select.Option key={'CHAU_AU'} value={'CHAU_AU'}>{'Châu Âu'}</Select.Option>
                                                    <Select.Option key={'CHAU_DAI_DUONG'} value={'CHAU_DAI_DUONG'}>{'Châu Đại Dương'}</Select.Option>
                                                    <Select.Option key={'CHAU_MY'} value={'CHAU_MY'}>{'Châu Mỹ'}</Select.Option>
                                                    <Select.Option key={'CHAU_NAM_CUC'} value={'CHAU_NAM_CUC'}>{'Châu Nam Cực'}</Select.Option>
                                                    <Select.Option key={'CHAU_PHI'} value={'CHAU_PHI'}>{'Châu Phi'}</Select.Option>
                                                    <Select.Option key={'CHAU_UC'} value={'CHAU_UC'}>{'Châu Úc'}</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12 text-right'>
                                    <div className="form-group">
                                        <Link type="button" className="btn btn-info" to="/quoc-gia">Quay lại</Link>
                                        <button type="submit" className="btn btn-primary ml-2" disabled={loading}> <>
                                            {loading && (
                                                <span className='spinner-border spinner-border-sm mr-1'></span>
                                            )}
                                            Tạo mới
                                        </></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
