import React, { Fragment, useEffect, useState } from 'react'
import { congDoanService, homeService, tintucService } from '../../services';
import { Link } from 'react-router-dom';
import { Input, Pagination, Select, message } from 'antd';
import Swal from 'sweetalert2';
import MoveButtons from '../../components/MoveButtons';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { AuthenticatedUser } from '../../store/account/types';
import { Form } from 'antd';

export const ThongTin = () => {
    const MAX_ITEMS_PER_PAGE_THONG_TIN: number = 20;
    const accountAdminLogin = useSelector<AppState>((state) => state.account.user) as AuthenticatedUser;
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [loadingPage, setLoadingPage] = useState(true);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);
    const [formSearch] = Form.useForm();
    const [listIdCongDoan, setListIdCongDoan] = useState<any[]>([]);
    const [listIdLoaiTin, setListIdLoaiTin] = useState<any[]>([]);

    const fetchData = async (pageNumber: number) => {
        setLoadingPage(true);
        setCurrentPage(pageNumber);
        const values = await formSearch.validateFields();
        const response = await tintucService.filterList(pageNumber - 1, MAX_ITEMS_PER_PAGE_THONG_TIN, 'id', values.idLoaiTin, values.idCongDoan, values.tinhTrang, values.keySearch);
        if (response.status === 200) {
            setTotal(response.headers['x-total-count']);
            setLstDanhSach(response.data.data);
        } else {
            message.error({ content: response.message, duration: 2 });
        }
        setLoadingPage(false);
    };

    const onPageChanged = (pageNumber: number) => {
        if (pageNumber === currentPage) {
            return;
        }
        fetchData(pageNumber);
    };

    const onClickLocDuLieu = () => {
        fetchData(1);
    }

    const [lstCongDoan, setLstCongDoan] = useState<any[]>([]);
    const [lstLoaiTin, setLstLoaiTin] = useState<any[]>([]);
    useEffect(() => {
        const initData = async () => {
            try {
                const congDoanResponse = await congDoanService.getAll();
                if (congDoanResponse.code === 0) {
                    setLstCongDoan(congDoanResponse.data);
                }

                const loaiTinResponse = await tintucService.getAllDanhSachLoaiTin();
                if (loaiTinResponse.code === 0) {
                    setLstLoaiTin(loaiTinResponse.data);
                }

                const danhSachQuyen = await tintucService.getIdTongQuyen();
                if (danhSachQuyen.code === 0) {
                    setListIdCongDoan(danhSachQuyen.data[0]);
                    setListIdLoaiTin(danhSachQuyen.data[1])
                }
                fetchData(1);
                setLoading(false);
            } catch (error) {
                // Xử lý lỗi ở đây nếu cần thiết
                console.error("Error fetching data:", error);
            }
        };
        initData();
    }, []);

    const handleDeleteTinTuc = async (id: any) => {
        const result = await Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa tin tức này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const response = await tintucService.deleteThongTin(id)
                if (response.status === 204) {
                    fetchData(1);
                } else {
                    setLoading(false);
                    message.error({ content: response.message, duration: 2 });
                }
                setLoading(false)
            } catch (error: any) {
                setLoading(false);
                message.error({ content: error.message as string, duration: 1 });
            }
        }
    }

    const move = async (id: any, type: string, flagUpDown: boolean) => {
        setLoading(true);
        try {
            const response = await homeService.moveUpDown(id, null, type, flagUpDown);
            if (response.code === 0) {
                fetchData(currentPage);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>THÔNG TIN / Tin Tức</h1>
                </div>
                {/* Content Row */}

                <Form form={formSearch} name="search" onFinish={onClickLocDuLieu}>
                    <div className="row">
                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                {loading ? (
                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                ) : (
                                    <Form.Item name="idCongDoan">
                                        <Select placeholder="Chọn Cộng đoàn" size="large" onChange={() => formSearch.submit()}>
                                            <Select.Option key={0} value={''}>{'Tất cả'}</Select.Option>
                                            {lstCongDoan?.map((item: any, index) => (
                                                (accountAdminLogin.userLevel === 'Administrator' || listIdCongDoan.includes(item.id)) && (
                                                    <Select.Option key={index} value={item.id}>{item.tenCongDoan}</Select.Option>
                                                )
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </div>
                        </div>

                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                {loading ? (
                                    <span className='spinner-border spinner-border-sm mr-1'></span>
                                ) : (
                                    <Form.Item name="idLoaiTin">
                                        <Select placeholder="Chọn Phòng Ban (Loại tin)" size="large" onChange={() => formSearch.submit()}>
                                            <Select.Option key={0} value={''}>{'Tất cả'}</Select.Option>
                                            {lstLoaiTin.map((item: any, index) => (
                                                (accountAdminLogin.userLevel === 'Administrator' || listIdLoaiTin.includes(item.id as number)) && (
                                                    <Select.Option key={index} value={item.id}>{item.tenLoai}</Select.Option>
                                                )
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </div>
                        </div>

                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                <Form.Item name="tinhTrang">
                                    <Select placeholder="Tình Trạng" size="large" onChange={() => formSearch.submit()}>
                                        <Select.Option key={0} value={''}>{'Tất cả'}</Select.Option>
                                        <Select.Option key={1} value={'true'}>{'Hiển thị'}</Select.Option>
                                        <Select.Option key={2} value={'false'}>{'Không hiểu thị'}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>

                        <div className='col-xl-3 col-md-6'>
                            <div className="form-group">
                                <Form.Item name="keySearch">
                                    <Input className='form-control' placeholder="Tư khóa tìm kiếm" autoComplete="off" />
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12 text-right'>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                    )}
                                    Lọc dữ liệu
                                </button>
                                <Link type="button" className="btn btn-info ml-2" to="/tin-tuc/them-moi" >Tạo mới</Link>
                            </div>
                        </div>
                    </div>
                </Form>

                <div className="row">
                    <div className="col-12">
                        <div className='text-right mb-2'>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={MAX_ITEMS_PER_PAGE_THONG_TIN}
                                onChange={onPageChanged}
                                showSizeChanger={false}
                            />
                        </div>

                        <div className="table-responsive">
                            <table className="table table-bordered table-hover table-custom-with" id="dataTable" width="100%" cellSpacing={0}>
                                <thead style={{ backgroundColor: '#fac2b5' }}>
                                    <tr>
                                        <th>Ngày</th>
                                        <th>Tiêu đề</th>
                                        <th>Cộng đoàn</th>
                                        <th>Phòng ban</th>
                                        <th>Người đăng</th>
                                        <th>Thứ tự</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadingPage ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={6}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td style={{ width: '8%' }} className='text-center'>
                                                        {item.choPhepThongBao ? (
                                                            <span><img style={{ width: '23px', height: '23px' }} src='/images/icon_bell_ra_.png' alt={item.tieuDe} />{item.strNgayDang}</span>
                                                        ) : (
                                                            <span>{item.strNgayDang}</span>
                                                        )}
                                                    </td>
                                                    <td>{item.tieuDe}</td>
                                                    <td style={{ width: '15%' }}>{item.tenCongDoan}</td>
                                                    <td style={{ width: '15%' }}>{item.tenLoai}</td>
                                                    <td style={{ width: '15%' }}>{item.tenNhanSuDang}</td>
                                                    <td style={{ width: '10%' }}>
                                                        <MoveButtons onMoveUp={() => move(item.id, 'THONG_TIN', true)} onMoveDown={() => move(item.id, 'THONG_TIN', false)} />
                                                    </td>
                                                    <td style={{ width: '10%' }}>
                                                        <Link to={`/tin-tuc/chinh-sua/${item.id}`} style={{ marginRight: '10px' }}>
                                                            {/* Chèn nội dung bên trong thẻ Link */}
                                                            <img style={{ width: '22px' }} src='/images/web_new.png' alt={item.tieuDe} />
                                                        </Link>
                                                        <img onClick={() => handleDeleteTinTuc(item.id)} style={{ width: '23px', height: '23px', cursor: 'pointer' }} src='/images/web_delete.png' alt={item.tieuDe} />
                                                    </td>

                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='text-right'>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={MAX_ITEMS_PER_PAGE_THONG_TIN}
                                onChange={onPageChanged}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
