import React, { Fragment, useEffect, useState } from 'react';
import { homeService, vungService } from '../../services';
import { Link } from 'react-router-dom';
import { MAX_ITEMS_PER_PAGE } from '../../helpers/constants';
import { Pagination, message } from 'antd';
import Swal from 'sweetalert2';
import MoveButtons from '../../components/MoveButtons';

export const Vung = () => {
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lstDanhSach, setLstDanhSach] = useState<any[]>([]);

    const fetchData = (pageNumber: number) => {
        setLoading(true);
        vungService.filterList(pageNumber - 1, MAX_ITEMS_PER_PAGE, null)
            .then((response) => {
                if (response.status === 200) {
                    setTotal(response.headers['x-total-count']);
                    setLstDanhSach(response.data);
                } else {
                    message.error({ content: response.message, duration: 2 });
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData(currentPage);
        // Fetch other data (lstTenThanh, lstCongDoan, etc.)
    }, [currentPage]);

    const onPageChanged = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const refreshData = async () => {
        try {
            fetchData(1);
            setCurrentPage(1);
        } catch (error: any) {
            message.error({ content: "Chưa chọn năm", duration: 2 });
        }
    };

    const handleDelete = async (id: any) => {
        const result = await Swal.fire({
            title: 'Xác nhận xóa Vùng này?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const response = await vungService.deleteById(id);
                if (response.status === 204) {
                    message.success('Xóa Vùng thành công');
                    setCurrentPage(1);
                    fetchData(1);
                } else {
                    message.error({ content: "Đang có Cộng Đoàn thuộc Vùng này không thể xóa", duration: 2 });
                }
            } catch (error: any) {
                message.error({ content: error.message as string, duration: 1 });
            } finally {
                setLoading(false);
            }
        }
    };

    const move = async (id: any, type: string, flagUpDown: boolean) => {
        setLoading(true);
        try {
            const response = await homeService.moveUpDown(id, null, type, flagUpDown);
            if (response.code === 0) {
                setCurrentPage(currentPage);
                fetchData(currentPage);
            } else {
                message.error({ content: response.message, duration: 2 });
            }
        } catch (error: any) {
            message.error({ content: error.message as string, duration: 1 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ paddingTop: '10px' }}>
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0" style={{ color: '#951a1d' }}>Nhân Sự / Vùng</h1>
                </div>
                {/* Content Row */}

                <div className="row">
                    <div className="col-12">
                        <div className='row'>
                            <div className='col-12 text-right'>
                                <div className="form-group">
                                    <button type="button" className="btn btn-primary" onClick={refreshData} disabled={loading}>
                                        {loading && (
                                            <span className='spinner-border spinner-border-sm mr-1'></span>
                                        )}
                                        Lọc dữ liệu
                                    </button>
                                    <Link type="button" className="btn btn-info ml-2" to="/vung/them-moi" >Tạo mới</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing={0}>
                                <thead style={{ backgroundColor: '#fac2b5' }}>
                                    <tr>
                                        <th>Tên Vùng</th>
                                        <th>Thứ tự</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        // Hiển thị bộ Loading khi loadingLoadList là true
                                        <tr className='text-center'>
                                            <td colSpan={3}>
                                                <span className='spinner-border spinner-border-sm mr-1'></span> Đang tải...
                                            </td>
                                        </tr>
                                    ) : (
                                        // Hiển thị bảng dữ liệu khi loadingLoadList là false
                                        <Fragment>
                                            {lstDanhSach?.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'col-bg-0' : 'col-bg-1'}>
                                                    <td>{item.tenVung}</td>
                                                    <td style={{ width: '10%' }}>
                                                        <MoveButtons onMoveUp={() => move(item.id, 'VUNG', true)} onMoveDown={() => move(item.id, 'VUNG', false)} />
                                                    </td>
                                                    <td style={{ display: 'flex' }}>
                                                        <Link to={`/vung/chinh-sua/${item.id}`} style={{ marginLeft: '10px' }}>
                                                            {/* Chèn nội dung bên trong thẻ Link */}
                                                            <img style={{ width: '22px' }} src='/images/web_new.png' alt={item.ma} />
                                                        </Link>
                                                        <img onClick={() => handleDelete(item.id)} style={{ width: '23px', cursor: 'pointer', marginLeft: '10px' }} src='/images/web_delete.png' alt={item.ma} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='text-right'>
                            <Pagination
                                current={currentPage}
                                total={totalItems}
                                pageSize={MAX_ITEMS_PER_PAGE}
                                onChange={onPageChanged}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
