import React, { ChangeEvent, FormEvent, useEffect, useState, Fragment } from 'react';
import { login as loginAction, logout } from '../../store/account/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';

export const Login = () => {
    const loading = useSelector<AppState>((state) => state.account.loading);
    const error = useSelector<AppState>((state) => state.account.error);

    const [inputs, setInputs] = useState({
        email: '',
        password: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const { email, password } = inputs;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInputs((inputs) => ({ ...inputs, [name]: value }));
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitted(true);
        if (email && password) {
            const login = loginAction(email, password);
            login(dispatch);
        }
    };

    return (
        <Fragment>
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                <div className="sidebar-brand-text mx-3 text-center mb-2">
                    <img style={{ width: '100%', marginTop: '20px' }} src="/images/web_logo_w.png" alt="Logo" />
                </div>
            </ul>
            <div id="content-wrapper" className="container-fluid" style={{ padding: 'inherit' }}>
                <div id="content" className='text-right bg-custom'>
                    <div className="row split-screen">
                        <div className="col-md-12">
                            <img style={{ width: '21%', marginTop: '15px' }} src="/images/web_alfonso.png" alt="Logo" />
                        </div>
                    </div>
                    <div className='text-left pl-2 text-lg'>
                        <strong style={{ color: '#951a1d', fontSize: '22px' }}>TỈNH DÒNG CHÚA CỨU THẾ VIỆT NAM</strong>
                    </div>
                    <div className="divider" />
                    <div className="row split-screen bg-custom">
                        <div className="col-md-12">
                            {/* Content for bottom half */}
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="p-2">
                                        <form className='user' onSubmit={handleSubmit}>
                                            <>
                                                {error && (
                                                    <div className="alert alert-danger" role="alert">
                                                        {error.toString()}
                                                    </div>
                                                )}
                                            </>
                                            <div className="form-group">
                                                <input type="" className={'form-control form-control-user ' + (submitted && !email ? 'is-invalid' : '')}
                                                    onChange={handleChange}
                                                    name="email" aria-describedby="emailHelp" placeholder="Email đăng nhập..." />
                                                {submitted && !email && (
                                                    <div className='invalid-feedback'>
                                                        Email không được bỏ trống
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className={'form-control form-control-user ' + (submitted && !password ? 'is-invalid' : '')}
                                                    onChange={handleChange}
                                                    name="password" placeholder="Mật khẩu" />
                                                {submitted && !email && (
                                                    <div className='invalid-feedback'>
                                                        Mật khẩu không được trống
                                                    </div>
                                                )}
                                            </div>
                                            <button className="btn btn-primary btn-user btn-block">
                                                <>
                                                    {loading && (
                                                        <span className='spinner-border spinner-border-sm mr-1'></span>
                                                    )}
                                                    Đăng nhập
                                                </>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
